import React, { useEffect, useReducer } from 'react';
import $ from 'jquery';
import Topbar from './topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const ArtistOfTheWeek = () => {



  useEffect(() => {
    document.title = "Top 100 Afrobeat | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }


    console.log(usertoken)


    var r = []

    console.log(r)
    function myFunction2(x, myid = myid.reverse()) {
      //   var btn = $(".playbutton");
      // btn.toggleClass("paused");
      // return false;

      var settings = {
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/singles/" + myid[x.rowIndex - 1],
        "method": "GET",
        "timeout": 0,
      }

      $.ajax(settings).done(function (response) {
        console.log(response)
        document.getElementById("myAudio").src = response.song_link;

      }).then(
        function () {
          var userttype = document.getElementById("profiletype").innerHTML
          if (userttype === "Artist") {
            alert("You are logged in as an artist, You wont earn on this stream.")
          }
          else {
            var song = document.getElementById("myAudio")
            console.log(song)
            song.play();
          }

        }
      )

    }



    var usertoken = localStorage.getItem("access_token")
    console.log(usertoken)
    if (usertoken === null) {
      window.location.replace("/login");

    }

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      if (response.user_role == "Artist") {
        $(".forartistonly").css({ 'display': 'block' });
        $(".nmsbuttons").css({ 'display': 'none' });
        $(".artistnotify").css({ 'display': 'block' });
        $(".forlisteneronly").css({ 'display': 'none' });

      }
      if (response.picture == "") {
        document.getElementById("profilepicture").src = "/images/usericon.png"
      }
      else {
        document.getElementById("profilepicture").src = response.picture
      }

      if (response.user_role === "Listener") {
        document.getElementById("profilename").innerHTML = response.firstname + " " + response.lastname
        document.getElementById("profilemoney").innerHTML = "Earnings: &#x20A6;" + (response.streamedsongs.length - 1) * 15

      }

      else if (response.user_role === "Artist") {
        document.getElementById("profilename").innerHTML = response.stagename
        document.getElementById("profilemoney").innerHTML = "Balance: &#x20A6;" + response.balance.toLocaleString()

      }
      document.getElementById("profiletype").innerHTML = response.user_role

      document.getElementById("userid").value = response.id


      var settingstwo = {
        beforeSend: function () {
          $('.ajax-loader').css("visibility", "visible");
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs",
        "method": "GET",
        "timeout": 0,
        "headers": { "Authorization": "Bearer " + usertoken },
        complete: function () {
          $('.ajax-loader').css("visibility", "hidden");
        }
      }

      var objarr = []
      var songArtists = []
      var totalno = []


      $.ajax(settingstwo).done(function (responsetwo) {
        var homes = responsetwo
        const sortArray = homes

        sortArray.sort((a, b) => {
          if (a.noofstreams > b.noofstreams)
            return -1;
          if (a.noofstreams < b.noofstreams)
            return 1;
          return 0;
        });
        console.log(sortArray)
        var totalno = responsetwo.length

        $.each(sortArray, function (i, item) {

          console.log(sortArray[i])
          songArtists.push(sortArray[i].user_id)
          //    var filteredArr = songArtists.filter(function(item, index) {
          //   if (songArtists.indexOf(item) == index)
          //     return item;
          // });
          // console.log(filteredArr)

          //    var uniq = songArtists.reduce(function(a,b){
          //     if (a.indexOf(b) < 0 ) a.push(b);
          //     return a;
          //   },[]);
          // var uniq2 = uniq



          // CHECK IF USER HAS A BALANCE
          let checkthis

          $.each(songArtists, function (i, item) {
            // console.log(songArtists[i])
            // setTimeout(function(){
            checkthis = songArtists[i]
          })
          // console.log(checkthis)
          // console.log(songArtists[i])
          var settingsthree = {
            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + checkthis,
            "method": "GET",
            "timeout": 0,
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          }
          $.ajax(settingsthree).done(function (responsethree) {
            // console.log(responsethree)
            if (responsethree.balance !== 0) {
              if (sortArray[i].genre == "Afrobeat") {
                // console.log(responsetwo[i])



                var defaultDates = sortArray[i].createdAt
                var d = new Date(defaultDates).toString();
                var actualdate = d.split(' ').splice(0, 4).join(' ')
                r.push(sortArray[i]._id)

                if (sortArray[i].feature == "") {
                  var feature = "-"
                }
                else {
                  var feature = sortArray[i].feature
                }
                $('<tr onclick="myFunction2(this, r)">').html(

                  //  "<td>"+ "<i class='fas fa-play'></i>"+ "</td>"
                  // "<td>"+ response[i].id+ "</td>"

                  //  "<td class='centerthis'> <img width='20px' height='20px' src='" + response[i].song_art + "'>" + "</td>"
                  "<td class='centerthis'> " + "" + "</td>"
                  + "<td class='centerthis'> " + sortArray[i].noofstreams + "</td>"

                  //  "<td class='centerthis'><button class='playbutton'></button>" + "</td>"

                  + "<td class='centerthis firstnametd'>" + sortArray[i].name + "</td>"

                  + "<td class='centerthis firstnametd'>" + sortArray[i].artist_name + "</td>"
                  + "<td class='centerthis firstnametd'>" + 5 + "</td>"
                  // + "<td>"+ '<p class="centerthis firstnametd">' +  '<a target="_blank" style="color:#fff" href="'+ responsetwo[i].song_link +'"><i class="fas fa-download"></i></a>'  + "</p></td>"

                ).appendTo('#records_table');
                $('.firstnametd').click(false);

              }

            }
          })

          // }, 3000);





          // CHECK IF USER HAS A BALANCE

          // var myDiv = response[i].song_art
          // myDiv.innerHTML = response[i].song_art
          // document.getElementById("newmusicbox").append(myDiv);
          // document.getElementById("songtitle").append(myDiv);

          // var totalno = response[i].id.length;
          // console.log(totalno)

          // $("body").html("<div>This is a new div.</div>");

          objarr.push(responsetwo[i])
          // console.log(objarr.length)



          // let imalges = ["url1","url2","url3","url4","url5"];
          // console.log(imalges)
          // let div='';
          // let j = 3;
          // for(var i = 0; i < imalges.length; i++ ){

          //   if(i % 3 === 0 || i == 0 ) div +=  "<div class='row'>";
          //     div +=  "<div class='col-md-4'>" + imalges[i] + "</div>";

          //   if( j == i || i === 2 || (imalges.length-1) === i )  {
          //     j += 3
          //     div +=  "</div>";

          //   }
          // }


          // document.getElementById('newmusicbox').innerHTML = div
          // console.log(div)







          // $(function () {
          //   var musicbox2 = document.getElementById('newmusicbox2').innerHTML
          //   let row = $('<div>').addClass('row').prependTo(newmusicbox2);
          //     for (let i = 0; i < 4; i++) {
          //         let col = $('<div>').addClass('col-md-3').appendTo(row);
          //         createCardsFor(col);
          //     }
          // })
          // function createCardsFor($container) {
          //     for (let i = 0; i < 1; i++) {
          //         let card = $('<div>').addClass('card').css({
          //             'margin': '10px',
          //             'box-shadow': '0 0 6px rgba(0,0,0, .2)'
          //         }).appendTo($container);
          //         let cardHeader = $('<div>').addClass('card-header').appendTo(card);
          //         let cardImage = $('<img>').attr({
          //             alt: "alt",
          //             src: "https://via.placeholder.com/50"
          //         }).appendTo(cardHeader);
          //         let deleteButton = $('<button>').addClass('btn btn-sm btn-outline-danger').text('Delete').on('click', deleteButtonClick).appendTo(cardHeader);
          //         let cardBody = $('<div>').addClass('card-body').appendTo(card);
          //         let bodyTitle = $('<h5>').text('Title').appendTo(cardBody);
          //         let bodyText = $('<p>').text('Lorem Ipsum Dolor...').appendTo(cardBody);
          //     }
          // }

          // function deleteButtonClick(e) {
          //     e.preventDefault();
          // }
          // $("<div class='row' id=''>").html(
          //       "<div style='margin-top:30px;' class='col-md-4'>"+
          //       "<img class='albumimage' src='" +response[i].song_art+ "'"
          //           //  +  "<p style=''>" + "daddyyo"+ "</p>"
          //           //  +  "<button>" + "View"+ "</button>"

          //      +  "<p style='text-align:center' class='centerthis'>" +response[i].name+ "</p>"
          //       // +"<p class='centerthis'>" + response[i].feature + "</p>"
          //       // +" <a target='_blank' href='/newmusicsingle?songid="+ response[i].id +"'>" +"<button class='btn btn-danger btn-sm'  id='appbtns' class='btn btn-sm downloadreceiptbutton'> VIEW SONG</button>"  +"</a>"

          //       + "</div>"

          //             ).appendTo('#newmusicbox');









        })
      })





      // if (document.getElementsByClassName("profilename").innerHTML !== ""){
      //   document.getElementsByClassName("profilename").innerHTML == "Edit Your Profile"
      // }
      // else{
      //   document.getElementsByClassName("profilename").innerHTML = response.firstname + " " + response.lastname

      // }

    })




























  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading3">Artist of the week.</h4>

            <div class=" newmusicsinglebox">

              {/* <!-- <h1 class="mt-4 menuheading">New Music </h1> --> */}
              <div class="row">
                <input type="hidden" id="userid" value="" />

                <div class="col-md-2">
                  {/* <!-- <div class="card hottrackmoodcard-block hottrack-1"></div> --> */}
                  <p class="bannerp"><img className="top100banner" src="/images/webapp/top100/afrobeat.jpeg" /></p>
                </div>


                <div class="col-md-9">
                  <div class="">
                    <h4 class="nmsartistalbum">HunkPapii</h4>
                    <h4 class="nmsartistname">The New Trybe</h4>
                    <p class="nmsartistyear"><span className="editorsnotetop100">Introducing the artist of the week.</span></p>
                    <p style={{ color: "#7BFFB3" }}>205,000 | 3hr 48 min</p>
                    <p>

                      {/* <!-- <p class="nmsbuttons">
        <a href="/dailytop100"><button type="button" class="btn btn-lg"style="color:white; background-color:#FA2D48; font-size:13px" >PLAY</button></a>
        <a href="/dailytop100"><button type="button" class="btn btn-lg"style="color:white; background-color:#FA2D48; font-size:13px" >SHUFFLE</button></a>
</p> --> */}
                      <p style={{ color: "#fff" }} class="artistnotify">Create/Login to a listeners account to stream</p>

                    </p>

                    <hr />




                    <div>

                    </div>
                  </div>
                </div>
              </div>

              <Row>
                <div class="col-md-12">
                  <div class="">
                    <div class="row dailybox">
                      <table class="table  css-serial aotwtable">
                        <thead class="thead">
                          <tr class="ippisschedulehead">
                            {/* <!-- <th class="ippiscol0" scope="col">Song ID</th> --> */}
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">S/N</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">No Of Plays</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Song</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Artist</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Ratings</th>
                            {/* <!-- <th style="text-align:center" class="ippiscol0" scope="col">Ratings</th> --> */}
                            {/* <!-- <th class="ippiscol0" scope="col">Featuring</th>
                    <th class="ippiscol0" scope="col">Producer</th>
                    <th class="ippiscol0" scope="col">Streams</th> --> */}

                          </tr>
                        </thead>

                        <tbody id="records_table">
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </Row>
            </div>

          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { ArtistOfTheWeek as default }