import React, { useEffect, useReducer, useState } from 'react';
import { Container, Row, Form, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import "../stylesheets/website.css"
// import {useHistory} from 'react-router-dom';
import $ from 'jquery';

import Header from './Header';

import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
const ResetPasswordFinalStep = () => {
  const [newpassword, setPassword] = useState("")
  const [confirmpassword, setConfirmPassword] = useState("")
  const [notify, setNotify] = useState("")
  useEffect(() => {
    document.title = "Reset Password | The New Trybe"
  }, [])


  const resetPassword = (e) => {
    e.preventDefault()
    console.log(newpassword)
    console.log(confirmpassword)
    if (newpassword === "") {
      setNotify('Password cannot be empty.')
    }

    else if (confirmpassword === "") {
      setNotify('Confirm your password.')
    }


    else if (newpassword !== confirmpassword) {
      setNotify('Passwords do not match.')
    }

    else {
      setNotify('Processing...')
      var pathname = window.location.href;
      let params = (new URL(pathname)).searchParams;
      var tokens = params.get('token')
      // var password = document.getElementById('password').value

      var settings = {


        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/resetfinal/" + tokens,
        "method": "POST",
        "timeout": 0,
        error: function (xhr, status, error) {
          console.log(xhr)

          var data = xhr.responseText
          console.log(data)
          if (data === "Password reset token is invalid or has expired.") {
            setNotify('Password reset token is invalid or has expired.')
          }
          // else{
          //     window.location.replace("/resetpasswordfinalstep?token="+ tokens);
          // }



          // var jsonResponse = JSON.parse(data);
          // console.log(jsonResponse.error);
          // document.getElementById("errorsignin").innerHTML = jsonResponse.error;
        },
        "data": { "password": newpassword },

      }

      $.ajax(settings).done(function (response) {
        setNotify('Updated Successfully, Redirecting...')
        window.location.replace("/login");

      })

    }


    // dispatch.push('/')

  }

  return (

    <div id="resetbody">
      <Container className='page'>
        {/* <Header/> */}
        <Row className="">
          <div className="col-md-12">
            <h1 className='logintitle'>Reset Password</h1>

          </div>


        </Row>
        <Row className="">
          <div className="col-md-3">
          </div>
          <div className="col-md-6">
            <p className='loginnotify'>Reset your password.</p>


          </div>
          <div className="col-md-3">
          </div>


          <div className="col-md-4">
          </div>
          <div className="col-md-4">
            <Form >
              <Form.Group className="mb-3" controlId="formBasicEmail" >

                <Form.Control type="password" id="email" value={newpassword} onChange={(e) => setPassword(e.target.value)} placeholder="Input your new password" size="lg" />

              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail" >

                <Form.Control type="password" id="email" value={confirmpassword} onChange={(e) => setConfirmPassword(e.target.value)} placeholder="Confirm your new password" size="lg" />

              </Form.Group>
              <div className="d-grid gap-2">
                <p className='loginerrornotify'>{notify}</p>
                <Button onClick={resetPassword} className="loginbutton" type="submit">
                  Continue
                </Button>

              </div>



            </Form>


          </div>
          <div className="col-md-4">
          </div>






        </Row>
        {/* <Footer/> */}
      </Container>
    </div>
  )
}


export { ResetPasswordFinalStep as default }