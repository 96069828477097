import React, { useEffect, useReducer } from 'react';
import { BrowserRouter, Routes, Route, Switch, useLocation } from 'react-router-dom'

import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
// import {motion} from 'framer-motion/dist/es/index'
// import { AnimatedSwitch } from 'react-router-transition';

import Header from '../components/Header';
import HomePage from '../components/HomePage';
import AboutPage from '../components/AboutPage';
import FaqPage from '../components/FaqPage';
import LoginPage from '../components/LoginPage';
import RegisterPage from '../components/RegisterPage';
import ConfirmationPage from '../components/ConfirmationPage';
import ArtistPage from '../components/ArtistPage';
import ListenerPage from '../components/ListenerPage';
import VerifyPage from '../webappcomponents/verify';
import DeletePage from '../webappcomponents/DeletePage';
import EditPage from '../webappcomponents/EditPage';
import LearnMorePage from '../components/LearnMore';
import Learnmorelisteners from '../components/Learnmorelisteners';

import newMusicPage from '../webappcomponents/newMusicPage';
import Footer from '../components/Footer';
import EmailVerificationPage from '../components/EmailVerificationPage';
import resetPasswordPage from '../components/resetPasswordPage';
import resetPasswordFinalStep from '../components/resetPasswordFinalStep';



import explorePage from '../webappcomponents/explorePage'
import donatePage from '../webappcomponents/donate'
import AccountPage from '../webappcomponents/AccountPage';
import libraryPage from '../webappcomponents/libraryPage';
import top100Page from '../webappcomponents/top100Page';
import searchPage from '../webappcomponents/searchPage';
import dashboardPage from '../webappcomponents/dashboardPage';
import personalInformation from '../webappcomponents/personalInformation';
import bankPage from '../webappcomponents/bankPage';
import passwordPage from '../webappcomponents/passwordPage';
import afrobeattop100 from '../webappcomponents/afrobeatdaily'
import afropoptop100 from '../webappcomponents/afropopdaily'
import bluestop100 from '../webappcomponents/bluesdaily'
import raptop100 from '../webappcomponents/rapdaily'
import altetop100 from '../webappcomponents/altedaily'
import gospeltop100 from '../webappcomponents/gospeldaily'
import highlifetop100 from '../webappcomponents/highlifedaily'
import soultop100 from '../webappcomponents/souldaily'
import donatetoanartist from '../webappcomponents/donatetoanartist';



import artistoftheweek from '../webappcomponents/artistoftheweek';
import fundAccountPage from '../webappcomponents/fundAccountPage';
import uploadSong from '../webappcomponents/uploadSong';
import uploadVideo from '../webappcomponents/uploadVideo';
import viewStreams from '../webappcomponents/viewStreams';
import songStat from '../webappcomponents/songStat';
import earningsPage from '../webappcomponents/earningsPage';
import stream from '../webappcomponents/stream';
import payoutsPage from '../webappcomponents/payoutsPage';
import AllStreamsPage from '../webappcomponents/AllStreamsPage';





import $ from 'jquery'
import PostsContext from '../context/posts-context';
import postsReducer from '../reducers/posts'

const AppRouter = () => {

  useEffect(() => {
    var usertoken = localStorage.getItem("access_token")
    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      // console.log(response)
      const name = response.firstname + " " + response.lastname
      localStorage.setItem("userfullname", name);
      localStorage.setItem("userfirstname", response.firstname);
      localStorage.setItem("userlastname", response.lastname);
      localStorage.setItem("userinstagram", response.instagram);
      localStorage.setItem("usertwitter", response.twitter);
      localStorage.setItem("bank", response.bankname);
      localStorage.setItem("earnings", response.earnings);
      localStorage.setItem("accountnumber", response.accountnumber);
      localStorage.setItem("picture", response.picture);
      localStorage.setItem("loggedinuserphone", response.phone);
      localStorage.setItem("useremail", response.email);
      localStorage.setItem("loggedinusertype", response.user_role);
      localStorage.setItem("userid", response._id);
      localStorage.setItem("stagename", response.stagename);
      localStorage.setItem("about", response.about);
      localStorage.setItem("picture", response.picture);
      localStorage.setItem("instagramid", response.instagramid);
      localStorage.setItem("nin", response.nin);

      if (response.user_role === "Artist") {
        $(".forartistonly").css({ 'display': 'block' });
        $(".forlisteneronly").css({ 'display': 'none' });

      }
    })



  })



  const initialState = {
    user: [
      {
        name: localStorage.getItem("userfullname"),
        phone: localStorage.getItem("loggedinuserphone"),
        email: localStorage.getItem("useremail"),
        usertype: localStorage.getItem("loggedinusertype"),


      }
    ]
  };
  const [state, dispatch] = useReducer(postsReducer, initialState)



  return (





    <BrowserRouter>
      <div>
        {/* <Header /> */}

        <PostsContext.Provider value={{ users: state.user, dispatch }}>

          <Route render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={450}
                classNames="fade"
              >
                <Switch location={location}>


                  <Route path="/" component={HomePage} exact={true} />
                  <Route path="/about" component={AboutPage} exact={true} />
                  <Route path="/faq" component={FaqPage} exact={true} />
                  <Route path="/login" component={LoginPage} />
                  <Route path="/signin" component={LoginPage} />
                  <Route path="/signup" component={RegisterPage} />
                  <Route path="/artiste" component={ArtistPage} />
                  <Route path="/listener" component={ListenerPage} />
                  <Route path="/emailverification" component={EmailVerificationPage} />
                  <Route path="/resetpassword" component={resetPasswordPage} />
                  <Route path="/resetpasswordfinalstep" component={resetPasswordFinalStep} />
                  <Route path="/confirmation" component={ConfirmationPage} />
                  <Route path="/dashboard" component={dashboardPage} />
                  <Route path="/verify" component={VerifyPage} />
                  <Route path="/learnmore" component={LearnMorePage} />
                  <Route path="/learnmorelisteners" component={Learnmorelisteners} />



                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )} />

          <Route path="/explore" component={explorePage} />
          <Route path="/account" component={AccountPage} />
          <Route path="/library" component={libraryPage} />
          <Route path="/top100" component={top100Page} />
          <Route path="/donatetoanartist" component={donatetoanartist} />
          <Route path="/newmusic" component={newMusicPage} />
          <Route path="/search" component={searchPage} />
          <Route path="/personalinfo" component={personalInformation} />
          <Route path="/bank" component={bankPage} />
          <Route path="/password" component={passwordPage} />
          <Route path="/afrobeatdaily" component={afrobeattop100} />
          <Route path="/afropopdaily" component={afropoptop100} />
          <Route path="/bluesdaily" component={bluestop100} />
          <Route path="/rapdaily" component={raptop100} />
          <Route path="/altedaily" component={altetop100} />
          <Route path="/gospeldaily" component={gospeltop100} />
          <Route path="/highlifedaily" component={highlifetop100} />
          <Route path="/souldaily" component={soultop100} />
          <Route path="/donate" component={donatePage} />
          <Route path="/artistoftheweek" component={artistoftheweek} />
          <Route path="/fundaccount" component={fundAccountPage} />
          <Route path="/uploadsong" component={uploadSong} />
          <Route path="/uploadvideo" component={uploadVideo} />
          <Route path="/viewstreams" component={viewStreams} />
          <Route path="/songstat" component={songStat} />
          <Route path="/earnings" component={earningsPage} />
          <Route path="/stream" component={stream} />
          <Route path="/payouts" component={payoutsPage} />
          <Route path="/allstreams" component={AllStreamsPage} />
          <Route path="/hideordelete" component={DeletePage} />
          <Route path="/edit" component={EditPage} />
        </PostsContext.Provider>
        {/* <Footer /> */}
      </div>
    </BrowserRouter>




    // <TransitionGroup>
    //   <CSSTransition
    //     key={location.key}
    //     timeout={450}
    //     classNames="fade"
    //   >
    //     <Switch location={location}>
    //       <Route exact path="/" component={HomePage}  exact={true} />
    //       <Route path="/about" component={AboutPage} />
    //       <Route path="/faq" component={FaqPage  } />
    //     </Switch>
    //   </CSSTransition>
    // </TransitionGroup>









  )

};

export default AppRouter;
