import React, { useEffect, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const DashboardPage = () => {



  useEffect(() => {
    document.title = "Dashboard | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)


    var settings = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }
    $.ajax(settings).done(function (response) {
      // setShow(true)
      console.log(response)
      document.getElementById("dfigure").innerHTML = response.balance.toLocaleString()
      document.getElementById("dstream").innerHTML = response.totalnoofstreams.toLocaleString()

    })




  }, [])

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Dashboard</h4>

            <Container>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />

              <Row>

                <div className='col-md-5 col-xs-9 dashboardbalance'>
                  <p className="dashboardpagetext">Balance</p>
                  <h1 className="dashboardpagefigures">&#8358;<span id="dfigure">0</span></h1>
                  <a href="/uploadsong"> <button type="button" class="btn btn-lg" className='requestpayoutbutton' >Upload a song</button></a>

                </div>
                <div className='col-md-1'>
                </div>
                <div className='col-md-5 col-xs-9 dashboardstreams'>
                  <p className="dashboardpagetext">Streams</p>
                  <h1 className="dashboardpagefigures"><span id="dstream">0</span></h1>
                </div>

                <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />
              </Row>
              {/* <p className='dashboardhelp'><i class="fas fa-question-circle"></i> Help</p> */}
            </Container>

            <Container >
              <Row className="settingssecondcontainer">
                <div className='col-md-12'>
                  <Nav.Link as={Link} to='/fundaccount'>
                    <p>
                      <img src="/images/webapp/fundaccounticon.png" />
                      <span className="settingspersonalinfo" >Fund Account</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </Nav.Link>

                  <Nav.Link as={Link} to='/uploadsong' >
                    <p className="settingsacc ">
                      <img src="/images/webapp/uploadsongicon.png" />
                      <span className="settingspersonalinfo" >Upload a Song</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </Nav.Link>

                  {/* <Nav.Link as={Link}   to='/uploadvideo' >
       <p className="settingsacc ">
           <img src="/images/webapp/uploadsongicon.png"/>
       <span className="settingspersonalinfo" >Upload a Video</span>
           <span  style={{float: "right", marginTop:"15px"}}>  <img src="/images/rightarrow.png"/></span>
       </p>
       </Nav.Link> */}

                  <Nav.Link as={Link} to='/viewstreams'>
                    <p className="settingsacc">
                      <img src="/images/webapp/streamsicon.png" />
                      <span className="settingspersonalinfo" >Manage Songs/Streams</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </Nav.Link>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>

              </Row>
            </Container>

          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { DashboardPage as default }