import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import Email from '@sendgrid/mail';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import "../stylesheets/webapp.css"

import "../js/main.js"



const UploadSongPage = () => {

  const [image, setImage] = useState("/images/musicart.png");
  const [url, setUrl] = useState("");
  // const [ ImageSelector, setImageSelector ] = useState("/images/musicart.png");


  useEffect(() => {
    $('.loading').css("visibility", "visible");
    const data = new FormData()
    data.append("file", image)
    data.append("upload_preset", "e3e7uyua")
    data.append("cloud_name", "dlrsrdekh")
    fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload", {
      // beforeSend: function(){
      //   $('.loading').css("visibility", "visible");
      //   },
      method: "post",
      body: data
    })
      .then(resp => resp.json())
      .then(data => {
        setUrl(data.url)
        console.log(data)
        if (data.error) {
          document.getElementById("imageselectorid").src = "/images/musicart.png"
          $('.loading').css("visibility", "hidden");
        }
        else {
          document.getElementById("imageselectorid").src = data.url
          localStorage.setItem("songart", data.secure_url);
          $('.loading').css("visibility", "hidden");
        }


      })
      .catch(err => console.log(err))



  }, [image])

  const [song, setSong] = useState("");
  const [urlsong, setUrlSong] = useState("");
  const [notify, setNotify] = useState("")
  const [show, setShow] = useState(false);

  const submitSong = () => {

    console.log(image)


    // var thesongart =   localStorage.getItem("songart");
    // var thesong =   localStorage.getItem("songmp3");
    //  console.log(thesongart)
    var artistname = document.getElementById("stagename").value
    var songname = document.getElementById("songname").value
    var feature = document.getElementById("feature").value
    var producer = document.getElementById("producer").value
    var genre = document.getElementById("genre").value
    console.log(genre)
    var description = document.getElementById("description").value

    // var belongsto = localStorage.getItem("userid")
    // var usertoken = localStorage.getItem("access_token")

    // var song_art = localStorage.getItem("songart");
    // var song_link =localStorage.getItem("songmp3");

    if (songname === "") {
      setNotify('Song Title is Missing')
    }
    else if (genre === "") {
      setNotify('Genre is Missing')
    }

    else if (description === "") {
      setNotify('Input a description')
    }

    else if (image === "/images/musicart.png") {
      setNotify('Please upload a song art')
    }


    else if (song === "") {
      setNotify('Please select a song')
    }
    else {
      setNotify('Uploading')
      const data = new FormData()
      data.append("file", song)
      data.append("upload_preset", "e3e7uyua")
      data.append("cloud_name", "dlrsrdekh")
      fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload", {
        method: "post",
        body: data
      })
        .then(resp => resp.json())
        .then(data => {
          setUrlSong(data.url)
          console.log(data)
          if (data.error) {
            setNotify('Something went wrong, Please try again')
            // document.getElementById("imageselectorid").src = "/images/musicart.png"
          }
          else {
            setNotify('Uploading, Do not refresh the page.')
            // document.getElementById("imageselectorid").src = data.url
            // localStorage.setItem("songmp3", data.secure_url);
            var song_link = data.secure_url
            console.log(song_link)
            var settings = {
              beforeSend: function () {
                $('.loading').css("visibility", "visible");
              },

              "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single",
              "method": "POST",
              "timeout": 0,
              "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
              "data": {
                "name": songname,
                "feature": feature,
                "artist_name": artistname,
                "producer": producer,
                "genre": genre,
                "songdescription": description,
                "user_id": localStorage.getItem("userid"),
                "songart": localStorage.getItem("songart"),
                "songlink": song_link,

              },

              complete: function () {
                $('.loading').css("visibility", "hidden");
              }
            }

            $.ajax(settings).done(function (response) {


              console.log(response)
              setNotify('Updated Successfully')
              setShow(true)
            })

            var settingsemail = {
              beforeSend: function () {
                $('.loading').css("visibility", "visible");
              },

              "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/songuploadmail",
              "method": "GET",
              "timeout": 0,
              "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },

              complete: function () {
                $('.loading').css("visibility", "hidden");
              }
            }

            $.ajax(settingsemail).done(function (response) {
              console.log(response)
            })

          }
        })
        .catch(err => console.log(err))
    }






    // else{
    //   setNotify('Next Step')
    // }

  }



  useEffect(() => {
    document.title = "Upload Song | Artist | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)



    var settings = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }
    $.ajax(settings).done(function (response) {
      // setShow(true)
      // $("#myModal").modal('show');
      console.log(response)
      document.getElementById("dfigure").innerHTML = response.balance.toLocaleString()
      document.getElementById("dstream").innerHTML = response.totalnoofstreams.toLocaleString()
      document.getElementById("stagename").value = response.stagename
    })
  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Dashboard - Upload a song</h4>

            <Container>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />

              <Row>

                <div className='col-md-5 dashboardbalance'>
                  <p className="dashboardpagetext">Balance</p>
                  <h1 className="dashboardpagefigures">&#8358;<span id="dfigure">0</span></h1>

                </div>
                <div className='col-md-1'>
                </div>
                <div className='col-md-5 dashboardstreams'>
                  <p className="dashboardpagetext">Streams</p>
                  <h1 className="dashboardpagefigures"><span id="dstream">0</span></h1>

                </div>

                {/* <hr className="desktopdisplay" style={{borderTop: "1px solid #FFF", marginTop: "30px"}}/> */}
                {/* <hr className="mobiledisplay" style={{borderTop: "1px solid #FFF", marginTop: "30px"}}/> */}

              </Row>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} /></Container>

            <Container >
              <Row className="settingssecondcontainer">

                {/* <div className='col-md-4'>

    </div>

    <div className='col-md-4'>


     
    </div>

    <div className='col-md-4'>
    </div> */}


                <div className='col-md-6'>
                  <p className="uploadsonghead">Please use a song art designed by a graphics designer else your song will not be approved.</p>

                  <div class="image-upload">
                    <label for="file">
                      <img class="img-fluid" style={{ marginBottom: "40px", cursor: "pointer " }} width="489" height="371" id="imageselectorid" src={image} />
                    </label>

                    {/* <input id="file" type="file" /> */}
                    <input type="file" id="file" onChange={(e) => setImage(e.target.files[0])}></input>
                  </div>

                </div>

                <div className='col-md-6'>
                  <p className="uploadsonghead">Input details about song here</p>

                  {/* <div class="image-upload">
    <label for="file-input">
    <img src="https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/128/Downloads-icon.png"/>
  </label>
    <input type="file" onChange= {(e)=> setImage(e.target.files[0])}></input>
    </div> */}


                  {/* <img src="/images/musicart.png"/> */}

                  {/* <input id="file" type="file" onChange= {(e)=> setImage(e.target.files[0])} />
<button onClick={uploadImage}>Upload</button> */}


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="" placeholder="Input Artist Name" id="stagename" size="lg" disabled />
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="" placeholder="Input Song Title" id="songname" size="lg" />
                  </Form.Group>

                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="feature" placeholder="Featuring (Leave empty if theres no feature)" size="lg" />
                  </Form.Group>

                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="producer" placeholder="Producer (s)" size="lg" />
                  </Form.Group>

                  <Form.Group className="mb-3 loginemailinput" >
                    <Form.Select size="lg" size="lg" id="genre" className="form-control signupusertype usertypeformcontrol">
                      <option value="">- Select Genre -</option>
                      {/* <option value="Afrojuju">Afrojuju</option> */}
                      <option value="Afropop">Afropop</option>
                      <option value="Afrobeat">Afrobeat</option>
                      <option value="Alte">Alte</option>
                      <option value="Blues">Blues</option>
                      <option value="Gospel">Gospel</option>
                      <option value="Highlife">Highlife</option>
                      <option value="Hiphop">Hiphop</option>
                      <option value="Trap">Trap</option>
                      <option value="Drill">Drill</option>
                      <option value="Rap">Rap</option>
                      <option value="Soul">Soul</option>
                      <option value="Soul">Reggae</option>
                    </Form.Select>
                  </Form.Group>



                  <p className="uploadsonghead">Write something about your song</p>
                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control as="textarea" style={{ height: '200px' }} id="description" placeholder="Describe your song" size="lg" />
                  </Form.Group>


                  <p className="uploadsonghead">Upload Song (Max size: 10MB)</p>


                  {/* <div class="image-upload"> */}
                  {/* <label for="file">
    <img style={{marginBottom:"40px", cursor:"pointer "}} width="489" height="371" id="imageselectorid" src={ image} />
  </label> */}

                  {/* <input id="file" type="file" /> */}
                  <input type="file" id="file2" onChange={(e) => setSong(e.target.files[0])}></input>
                  {/* </div> */}

                  <p style={{ marginTop: "20px " }} className='loginerrornotify'>{notify}</p>

                  <p style={{ marginTop: "0px ", textAlign: "center" }} className=""><button onClick={submitSong} className='fundaccountbutton'>Continue</button></p>


                </div>

                {/* <div className='col-md-3'>
    </div> */}



              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>
      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Uploaded <span id="artist2"></span> Successfully</h5>
          <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} loop autoplay></lottie-player>
          {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}
          <p style={{ textAlign: "center", color: "#fff" }}>Your song has been submitted for review, you will get an e-mail once it is approved.</p>

          <p style={{ textAlign: "center" }}>
            <a href="/fundaccount"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Fund Account</button></a>
            <a href="/dashboard"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Go to Dashboard</button></a>
          </p>

          {/* <p style={{textAlign:"center"}}>  </p> */}


        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>




      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { UploadSongPage as default }