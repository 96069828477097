import React, { useEffect, useReducer,useState} from 'react';
import {Container, Row,Button, Form, Nav, Modal} from 'react-bootstrap'
import $ from 'jquery';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import "../stylesheets/website.css"
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';

const Learnmorelisteners = () => {
    const [Amount, setAmount] = useState(500)    
    const [result, setResult] = useState("")    
    const [show, setShow] = useState(false);


    useEffect(() =>{
        document.title = "Learn More | Listeners | The New Trybe"
        // setShow(true)
    },[])

    useEffect(() =>{
      

        const audience = (Amount/20).toLocaleString()
        setResult(audience)

     
      },[Amount]) 

      const closeModal = () =>{
        setShow(false)
      }

    return (
      
      <div>


          <Header/>

<Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

    <h5 style={{textAlign:"center", color:"#fff"}}>Welcome to The New Trybe </h5>


<div className=""  >
          {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
      


         
  
              
<p className="welcometext">
We are offering the first 100 artists a cash Bonus after you upload at least 1 song.

Sign up here to get your Bonus and start your journey to Fame
  </p>


<br/>
<div class="row">

  <div class="container twitterverifybox">
  <div class="row">

  {/* <div class="col-md-4">
    <img width="300px" src="" />
      <p><img width="100" height="100" style={{borderRadius:"8px"}} src={instagramimg}/></p>
  </div> */}

  

</div>

</div>
{/* <p style={{textAlign:"center",color:"#FFF",paddingTop:"20px"}}>{notify}</p> */}

{/* style={{textAlign:"center"}} */}

<div class="row">
  <div class="col-md-2">

  </div>

  <div class="col-md-9">
  <p className="footermenup3">
  <button   type="button" onClick={closeModal} class="btn btn-lg" className='onboardedbutton' >Close</button>
 <a href="/signup"><button   type="button"  class="btn btn-lg" className='onboardedbutton' >Sign Up</button></a>
  </p>
    </div>

    <div class="col-md-1">
    
    </div>
</div>


</div>



{/* <div class="col-md-12">
<p className="ifthis">If this is your account, Continue Below</p>
  </div> */}
  {/* <p style={{textAlign:"center"}}><button   type="button" onClick={saveUser} class="btn btn-lg" className='onboardedbutton' >Continue</button></p> */}



{/* <p style="color:#fff; text-align:center; padding-top:40px">

  Don`t forget to follow us.
</p> */}
            {/* <p style="text-align:center; padding-top:20px;">

              <a target="_blank" href="https://instagram.com/thenewtrybe"> <i style="font-size:28px; color:#fff" class="fab fa-instagram"></i></a>
              <a target="_blank" href="https://facebook.com/thenewtrybe"><i style="font-size:28px;margin-left:10px; color:#fff"class="fab fa-facebook"></i></a>
              <a target="_blank" href="https://twitter.com/thenewtrybe"><i style="font-size:28px; margin-left:10px; color:#fff" class="fab fa-twitter"></i></a>
      
            </p>
   */}
  
            {/* <p > <button onclick="submitFunction()" type="button" class="btn btn-lg"
                >CONTINUE</button></p> */}

    

          {/* <p style={{textAlign:"center"}}> <button onclick="submitFunction()" type="button" class="btn btn-lg"
              style="color:white; background-color:#FA2D48; font-size:10px">SUBMIT</button></p> */}



          </div>

            </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>


<div className="homepagefirstbox">
<Container className='page'>
    <Row >
        <div className="col-md-6">
        <h2 className='homepagetitle'>Where<span className="homepagefavourite"> Music</span> rewards  <span className="homepagefavourite">Everyone</span>.</h2>
            <p className='homepagedescription'>
            You discover new music and new artistes everyday already, why not get paid to do it? So we decided to pay you up to N50,000 every month to enjoy good music and discover new artistes.
            <br/>
It’s very simple to start.               
                   </p>   

                   {/* <p className='homepagedescription'>
                   Built completely for New Artists, your music can now get the recognition it deserves. You have the Music, Go take the Fame!                
               
                   </p>    */}


            <p className="footermenup centermobile">
          <a href="#artists"><Button  className="homepagectabutton1">Read More</Button></a>
          <a href="/signup"> <Button  className="homepagectabutton1">Sign Up</Button></a>

            </p>
     
            </div>

            <div className="col-md-6">
            <img className="img-fluid afbimg fordesktoponly desktopdisplay" src="/images/aboutfirstboximg.png"/>
            {/* <div className="homepageyt"> */}
                
            {/* <iframe className="hpyt" src="https://www.youtube.com/embed/om1L-jfifJ4?showinfo=0&controls=0" title="YouTube video player" rel="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            {/* <img className="hpi1 img-fluid" src="/images/homepageimage.png"/> */}
            {/* </div> */}
            </div>
    </Row>
    </Container>
    </div>



<div id="artists" className="homepagesecondboxlearnmore">
<Container className='page'>
    <Row >
        <div className="col-md-6 iframecontainer">
        {/* <img className="img-fluid  learnmoreimageunlimited" src="/images/reward.jpg"/> */}
        <iframe class="responsive-iframe" width="512" height="732" src="https://www.youtube.com/embed/4nMViml9dKc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
 
     
            </div>

            <div className="col-md-6">

                   <h2 style={{paddingTop:"100px"}} className='homepagetitle5 unlimitedmobile'>How to<span className="homepagefavourite"> Earn</span> </h2>


                   <p className='homepagedescription'>Step 1. Create an account <span className="homepagefavourite">  <a target="_blank"  href="/signup">HERE</a></span> </p>
                   <p className='homepagedescription'>Step 2. Stream a song from start to finish and rate the song; For every song you stream you make &#x20A6;15.  </p>
                   <p className='homepagedescription'>Step 3. Follow the artist on Instagram </p>
                   <p className='homepagedescription'>Note: You can request for your earnings after accumulating up to &#x20A6;1,000.</p>
                   <p className='homepagedescription'>Note: If you unfollow the artiste, your payout will not be processed. </p>
                   <p style={{textAlign:"center"}}>
                   <a style={{textAlign:"center",marginTop:"30px"}} href="/signup"> <Button  className="homepagectabutton1">Create an account now</Button></a>

                   </p>

            
            </div>



            {/* <h2 className='homepagetitle5 calculatormaintitle'><span className="homepagefavourite">Reward  </span> Calculator </h2>

<div class="row">
    <div class="col-md-6">

 <p className='calculatorhead' style={{paddingBottom:"0px"}} >For Every</p>
 <input className="form-control loginemailinput calculatorinput" value={Amount} type="number"id="amount" onChange={(e) => setAmount(e.target.value)} width="100px" placeholder="Input an amount" size="lg" />
 <p style={{paddingTop:"10px"}} className='calculatorhead'>You Fund</p>

        </div>

    <div class="col-md-6">
  
    <p className='calculatorhead'>You get:</p>
    <p className='calculatorbody'>1. Streams from <span className="homepagefavourite">{result} New listeners</span>  on the platform.</p>
    <p className='calculatorbody'>2. <span className="homepagefavourite">{result} Real followers</span>  on Instagram.</p>
    <p className='calculatorbody'>3. <span className="homepagefavourite">{result} Ratings</span>  on your song.</p>
    <p className='calculatorbody'>4. For every &#x20A6;30,000 you fund, We distribute 1 of your songs to <span className="homepagefavourite">Apple Music, Spotify for FREE.</span></p>


        </div>
    </div> */}

    </Row>
    </Container>
    </div>



    <div className="homepagefirstboxlearnmore">
<Container className='page'>


<div class="row" style={{marginTop:"0px"}}>
    <div class="col-md-6">
    <h2  className=' deducted'>Our Whatsapp Community</h2>
    <h2 className='homepagetitle6'><span className="homepagefavourite"> Support, Freebies, Giveaway and lots more</span> </h2>

    <p className='homepagedescription'>
    You can be sure not to miss out on any opportunity to earn as
we will be updating you regularly on our WhatsApp community.
                               </p>   
                               <p className="centermobile">
                   <a style={{textAlign:"center",marginTop:"30px"}} href="https://chat.whatsapp.com/JB2RDN2ZE25GhwfvTMP5ky" target="_blank"> <Button  className="homepagectabutton1">Join Here</Button></a>

                   </p>
                               {/* <h2 className='homepagetitle6'><span className="homepagefavourite"> A large social media following of real fans</span> </h2>

                               <p className='homepagedescription'>
                   Every New fan follows you on Social Media to earn from that stream, this means you get to grow your own fan base on Social Media. A real community of music lovers. 
               
                   </p>    */}

        </div>

        <div class="col-md-6">
        <img className="img-fluid learnmorestreamimage desktopdisplay" src="/images/reward.jpg"/>
        {/* <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_W6nHGZ/Whats app.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}

     
        </div>
    </div>

    <div class="row " >
    {/* <div class="col-md-6">
  
    <img className="img-fluid  learnmoreimage" src="/images/feedback.png"/>

  

        </div> */}

        {/* <div class="col-md-6 musicratingbox">
        <h2 className='homepagetitle7'><span className="homepagefavourite"> Organic Music Ratings</span> </h2>

        <p className='homepagedescription'>
                  Don’t you want to know what fans think about your music? With our ratings system, you get a chance to unlock exclusive rewards after holding a Top 10 spot for 12 consecutive weeks.. Now your fans get to reward you too. Are you up for a challenge? Go grab that Top 10 spot.
               
                   </p> 

                   <h2 className='homepagetitle6'><span className="homepagefavourite"> A Transparent dashboard </span> </h2>

        <p className='homepagedescription'>
                  We built a completely transparent dashboard for you so that you can watch your music’s performance in real time, get to see how each one of your songs are performing and view all your fan engagements.
               
                   </p> 
        </div> */}
{/* 
        <p className='launchdate' >
    We are offering the <span className="homepagefavourite"> first 100 artists a cash Bonus</span> after you upload at least 1 song. 
               
                   </p>  */}
                   
                   {/* <p className='homepagedescription' style={{textAlign:"center"}}>
                   Sign up  here  to get your Bonus and start your journey to Fame.               
                   </p>  */}


                   {/* <p className='homepagedescription' style={{textAlign:"center", paddingTop:"20px"}}>
                       For futher inquiries, call/whatsapp 08078854295 or join our telegram  channel <a target="_blank" className="homepagefavourite" href="https://t.me/thenewtrybeartiste">HERE</a>
                   </p>  */}
    </div>



 
    </Container>

  
    </div>



    <Footer/>
    </div>


    )
    
    
}


export {Learnmorelisteners as default}