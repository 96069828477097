import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import "ajax"
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import "../stylesheets/webapp.css"

import "../js/main.js"



const UploadSongPage = () => {

  const [image, setImage] = useState("/images/musicart.png");
  const [url, setUrl] = useState("");
  // const [ ImageSelector, setImageSelector ] = useState("/images/musicart.png");


  // useEffect(() =>{
  //   $('.loading').css("visibility", "visible");
  //   const data = new FormData()
  //   data.append("file", image)
  //   data.append("upload_preset", "e3e7uyua")
  //   data.append("cloud_name","dlrsrdekh")
  //   fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload",{
  //     // beforeSend: function(){
  //     //   $('.loading').css("visibility", "visible");
  //     //   },
  //   method:"post",
  //   body: data
  //   })
  //   .then(resp => resp.json())
  //   .then(data => {
  //   setUrl(data.url)
  //   console.log(data)
  //   if(data.error){
  //     document.getElementById("imageselectorid").src = "/images/musicart.png"
  //     $('.loading').css("visibility", "hidden");
  //   }
  //   else{
  //     document.getElementById("imageselectorid").src = data.url
  //     localStorage.setItem("songart", data.secure_url);
  //     $('.loading').css("visibility", "hidden");
  //   }


  //   })
  //   .catch(err => console.log(err))



  // },[image])

  const [song, setSong] = useState("");
  const [urlsong, setUrlSong] = useState("");
  const [notify, setNotify] = useState("")
  const [show, setShow] = useState(false);

  const submitSong = () => {
    var videourl = document.getElementById("videourl").value
    var stagename = document.getElementById("stagename").value
    var songtitle = document.getElementById("songtitle").value



    if (videourl === "") {
      setNotify('Video url is missing')
    }

    else if (stagename === "") {
      setNotify('Stagename is missing')
    }

    else if (songtitle === "") {
      setNotify('Songtitle is missing')
    }


    else if (song === "") {
      setNotify('Please select a song')
    }
    else {
      setNotify('Uploading')
      const data = new FormData()
      data.append("file", song)
      data.append("upload_preset", "e3e7uyua")
      data.append("cloud_name", "dlrsrdekh")
      fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload", {
        method: "post",
        body: data
      })
        .then(resp => resp.json())
        .then(data => {
          setUrlSong(data.url)
          console.log(data)
          if (data.error) {
            setNotify('Something went wrong, Please try again')
            // document.getElementById("imageselectorid").src = "/images/musicart.png"
          }
          else {
            setNotify('Uploading, Do not refresh the page.')
            // document.getElementById("imageselectorid").src = data.url
            // localStorage.setItem("songmp3", data.secure_url);
            var song_link = data.secure_url
            console.log(song_link)
            var settings = {
              beforeSend: function () {
                $('.loading').css("visibility", "visible");
              },

              "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single",
              "method": "POST",
              "timeout": 0,
              "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
              "data": {
                //  "name": songname, 
                // "feature": feature,
                // "artist_name": artistname,
                // "producer": producer,
                // "genre": genre,
                // "songdescription":description,
                // "user_id":  localStorage.getItem("userid"),
                // "songart":localStorage.getItem("songart"),
                // "songlink":song_link,

              },

              complete: function () {
                $('.loading').css("visibility", "hidden");
              }
            }

            $.ajax(settings).done(function (response) {
              console.log(response)
              setNotify('Updated Successfully')
              setShow(true)
              //           Email.send({
              //  Host : "smtp.sendgrid.net",
              //  Username : "apikey",
              //  Password : "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",
              //  Port: "25",
              //  To: document.getElementById("email").innerHTML,
              //  From : "aayo@codethatsprints.com",
              //  Subject : "Song Uploaded Successfully",
              //  Body : " Your song has been uploaded successfully."
              //  })
              // window.location.replace("/artistdashboard");

            })

          }
        })
        .catch(err => console.log(err))
    }






    // else{
    //   setNotify('Next Step')
    // }

  }



  useEffect(() => {
    document.title = "Upload Song | Artist | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)



    var settings = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }
    $.ajax(settings).done(function (response) {
      // setShow(true)
      // $("#myModal").modal('show');
      console.log(response)
      document.getElementById("dfigure").innerHTML = response.balance.toLocaleString()
      document.getElementById("dstream").innerHTML = response.totalnoofstreams.toLocaleString()
      document.getElementById("stagename").value = response.stagename
    })
  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Dashboard - Upload a video</h4>

            <Container>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />

              <Row>

                <div className='col-md-5 dashboardbalance'>
                  <p className="dashboardpagetext">Balance</p>
                  <h1 className="dashboardpagefigures">&#8358;<span id="dfigure">0</span></h1>

                </div>
                <div className='col-md-1'>
                </div>
                <div className='col-md-5 dashboardstreams'>
                  <p className="dashboardpagetext">Streams</p>
                  <h1 className="dashboardpagefigures"><span id="dstream">0</span></h1>

                </div>

                {/* <hr className="desktopdisplay" style={{borderTop: "1px solid #FFF", marginTop: "30px"}}/> */}
                {/* <hr className="mobiledisplay" style={{borderTop: "1px solid #FFF", marginTop: "30px"}}/> */}

              </Row>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} /></Container>

            <Container >
              <Row className="settingssecondcontainer">

                {/* <div className='col-md-4'>

    </div>

    <div className='col-md-4'>


     
    </div>

    <div className='col-md-4'>
    </div> */}


                <div className='col-md-3'>
                </div>

                <div className='col-md-6'>

                  {/* <p style={{textAlign:"center"}} className="uploadsonghead">Stagename</p> */}

                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" placeholder="Input Artist Name" id="stagename" size="lg" disabled />
                  </Form.Group>

                  {/* <p style={{textAlign:"center"}} className="uploadsonghead">Song Title</p> */}

                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" placeholder="Input Song Title" id="songtitle" size="lg" />
                  </Form.Group>


                  {/* <p style={{textAlign:"center"}} className="uploadsonghead">Input video link from youtube</p> */}

                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" placeholder="Input Video Url" id="videourl" size="lg" />
                  </Form.Group>


                  <p style={{ marginTop: "0px " }} className='loginerrornotify'>{notify}</p>

                  <p style={{ marginTop: "0px ", textAlign: "center" }} className=""><button onClick={submitSong} className='fundaccountbutton'>Continue</button></p>


                </div>

                <div className='col-md-3'>
                </div>


                {/* <div className='col-md-3'>
    </div> */}



              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>
      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Uploaded <span id="artist2"></span> Successfully</h5>
          <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} loop autoplay></lottie-player>
          {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}

          <p style={{ textAlign: "center" }}>
            <a href="/fundaccount"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Fund Account</button></a>
            <a href="/dashboard"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Go Home</button></a>
          </p>

          {/* <p style={{textAlign:"center"}}>  </p> */}


        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>




      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { UploadSongPage as default }