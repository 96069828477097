import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './routers/AppRouter';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import HttpsRedirect from 'react-https-redirect';


const jsx = (
    // <HttpsRedirect>
    <AppRouter />
    //  </HttpsRedirect>
)


ReactDOM.render( jsx, document.getElementById('root'));

serviceWorkerRegistration.register();