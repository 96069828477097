import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import { NavLink } from 'react-router-dom';

const HomePageHeader = () =>(

    <div>   
    <Container>
       <Row>

  <Col sm>
      <input type="text"className="form-control" placeholder="Seach Posts"/>
  </Col>

  <Col sm>
    <select className="form-control">
        <option>Select</option>
        <option>By Title</option>
    </select>
  </Col>

  <Col sm>
  
        <NavLink to="/create" activeClassName="is-active">  <button LinkclassName="btn-sm">Add Post</button></NavLink>
  </Col>
</Row>
    </Container>
        </div>

)



export {HomePageHeader as default}