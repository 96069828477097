import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Modal, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"








const VerifyPage = () => {

  const [name] = useState(localStorage.getItem("userfullname"));
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();
  const [url, setUrl] = useState("");
  const [notify, setNotify] = useState("")
  const [notify2, setNotify2] = useState("")
  const [notify3, setNotify3] = useState("")
  const [phone, setPhone] = useState("")

  const [showNIN, setNINShow] = useState(false);
  const [showPH, setPHShow] = useState(false);
  // const changeAvatar = () =>{
  const showninmodal = () => {
    setNINShow(true)
  }

  const showphmodal = () => {
    setPHShow(true)
  }

  const handleClose = () => {
    setPHShow(false)
    setNINShow(false)
  }




  const verifyNIN = () => {

    var nin = document.getElementById("nin").value
    var firstname = localStorage.getItem("userfirstname").toLowerCase();
    var lastname = localStorage.getItem("userlastname").toLowerCase();
    console.log(firstname)
    console.log(lastname)

    console.log(nin)
    if (nin === "") {
      setNotify("Input your NIN")
    }
    else if (nin.length < 11) {
      setNotify("Incomplete NIN")
    }
    else if (nin.length > 11) {
      setNotify("Invalid NIN")
    }
    else {
      setNotify("Processing, Please wait...")
      var settings = {
        //     beforeSend: function(){
        // $('.loading').css("visibility", "visible");
        // },

        "url": "https://api.myidentitypay.com/api/v1/biometrics/merchant/data/verification/nin_wo_face",
        "method": "POST",
        "headers": { "x-api-key": "3sMF5ROO.vLiSaKj3HkTGGM8yzxnxfCn5tqk3IDbt" },
        "timeout": 0,
        "data": {
          "number": document.getElementById("nin").value,

        },
        //             complete: function(){
        // $('.loading').css("visibility", "hidden");
        // }
      }

      $.ajax(settings).done(function (response) {
        console.log(response)
        // var ninfname = (response.nin_data.firstname).toLowerCase()
        // var ninlname = (response.nin_data.lastname).toLowerCase()

        if (response.status === false) {
          setNotify("NIN not found, Please try verifying your account with your phone number, If it doesnt work, Please contact support in the livechat below")
        }
        else if ((response.nin_data.firstname).toLowerCase() !== firstname) {
          setNotify("Your firstname/lastname does not match your NIN, Please contact support in the livechat below")

        }
        else {
          setNotify("Processing, Please wait...")
          var settingsupdate = {
            //         beforeSend: function(){
            //     $('.loading').css("visibility", "visible");
            //   },

            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
            "method": "PATCH",
            "timeout": 0,
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "data": {


              "nin": document.getElementById("nin").value,
              "onboarded": true,

              "picture": localStorage.getItem("picture"),
              "bankname": localStorage.getItem("bank"),
              "accountnumber": localStorage.getItem("accountnumber"),
              "phone": localStorage.getItem("loggedinuserphone"),
              "earnings": localStorage.getItem("earnings"),
              "instagram": localStorage.getItem("userinstagram"),
              "twitter": localStorage.getItem("usertwitter"),

              "instagramid": localStorage.getItem("instagramid"),
            },

            //         complete: function(){
            //     $('.loading').css("visibility", "hidden");
            //   }
          }
          $.ajax(settingsupdate).done(function (response) {
            $('.onboardedbutton').css("display", "none");

            console.log(response)
            setNotify("Account Verified Successfully, Redirecting...")
            window.location.replace("/verify");
          })
        }
        // setNotify("User not found")
      })
    }


  }


  const verifyPhone = () => {
    var ph = document.getElementById("phonenumber2").value

    var firstname = localStorage.getItem("userfirstname").toLowerCase();
    var lastname = localStorage.getItem("userlastname").toLowerCase();
    console.log(firstname)
    console.log(lastname)

    if (ph === "") {
      setNotify2("Input your Phone Number")
    }
    else if (ph.length < 11) {
      setNotify2("Incomplete Phone Number")
    }
    else if (ph.length > 11) {
      setNotify2("Invalid Phone Number")
    }
    else {
      setNotify2("Processing, Please wait...")
      var settings = {
        //     beforeSend: function(){
        // $('.loading').css("visibility", "visible");
        // },

        "url": "https://api.myidentitypay.com/api/v1/biometrics/merchant/data/verification/phone_number/advance",
        "method": "POST",
        "headers": { "x-api-key": "3sMF5ROO.vLiSaKj3HkTGGM8yzxnxfCn5tqk3IDbt" },
        "timeout": 0,
        "data": {
          "number": document.getElementById("phonenumber2").value,

        },
        //             complete: function(){
        // $('.loading').css("visibility", "hidden");
        // }
      }

      $.ajax(settings).done(function (response) {
        if (response.status === false) {
          setNotify2("Record not found, Please try verifying with your NIN, If it doesnt work, Please contact support in the livechat below")
        }

        else if ((response.data.firstname).toLowerCase() !== firstname) {
          setNotify2("Your firstname/lastname does not match, Please contact support in the livechat below")

        }
        else {
          setNotify2("Processing, Please wait...")
          var settingsupdate = {
            //         beforeSend: function(){
            //     $('.loading').css("visibility", "visible");
            //   },

            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
            "method": "PATCH",
            "timeout": 0,
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "data": {


              "nin": response.data.nin,
              "onboarded": true,

              "picture": localStorage.getItem("picture"),
              "bankname": localStorage.getItem("bank"),
              "accountnumber": localStorage.getItem("accountnumber"),
              "phone": document.getElementById("phonenumber2").value,
              "earnings": localStorage.getItem("earnings"),
              "instagram": localStorage.getItem("userinstagram"),
              "twitter": localStorage.getItem("usertwitter"),

              "instagramid": localStorage.getItem("instagramid"),
            },

            //         complete: function(){
            //     $('.loading').css("visibility", "hidden");
            //   }
          }
          $.ajax(settingsupdate).done(function (response) {
            $('.onboardedbutton').css("display", "none");

            console.log(response)
            setNotify2("Account Verified Successfully, Redirecting...")
            window.location.replace("/verify");
          })
        }
        // setNotify("User not found")
      })
    }

  }



  useEffect(() => {
    document.title = "Verify | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response.phone)
      setPhone(response.phone)
      console.log(response.user_role)
      if (response.user_role === "Artist") {
        $(".forlisteneronly").css({ 'display': 'none' });
      }
      else {
        $(".forlisteneronly").css({ 'display': 'block' });

      }

      if (response.picture === "") {
        setImage("/images/usericon2.png")
      }
      else {
        setImage(response.picture)

      }


      if (response.nin != 0) {
        console.log("verified")
        setNotify3("Your account is already verified")
        $('.cbutton1').css("display", "none");
        $('.cbutton2').css("display", "none");
        $('.loginemailinput').css("visibility", "hidden");
        $('.verifyhide').css("display", "none");

      }


    })
  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <Modal id="myModal" class="modal fade modal-content"
            show={showNIN} onHide={handleClose}


          >

            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>


              <div class="row">

                <div class="col-md-2 verifyhide"></div>

                <div class="col-md-8 verifyhide">
                  <br />


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" placeholder="Input your NIN" id="nin" size="lg" />
                  </Form.Group>
                </div>

                <div class="col-md-2 verifyhide"></div>


                <div class="container twitterverifybox verifyhide">
                  <div class="row verifyhide">



                  </div>

                </div>


                <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify}</p>
                <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify3}</p>
                <p class="verifyhide" style={{ textAlign: "center", marginTop: "10px" }}><button type="button" onClick={verifyNIN} class="btn btn-lg onboardedbutton cbutton1 verifyhide" className='' >Continue</button></p>
                <br />
                <br />
              </div>


            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
      
        </Modal.Footer> */}
          </Modal>

          <Modal id="myModal" class="modal fade modal-content"
            show={showPH} onHide={handleClose}
          >

            <Modal.Header closeButton>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>


              {/* <p style={{textAlign:"center",color:"#FFF",paddingTop:"0px"}}>{notify2}</p> */}

              <div class="row">
                <div class="col-md-2 verifyhide"></div>

                <div class="col-md-8 verifyhide">
                  <br />


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" placeholder="Input your Phone Number" id="phonenumber2" size="lg" />
                  </Form.Group>
                </div>

                <div class="col-md-2 verifyhide"></div>


                <div class="row">
                  <div class="col-md-2">

                  </div>

                  <div class="col-md-9">
                    <p className="footermenup3">
                      <br />

                      <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify2}</p>
                      <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify3}</p>
                      <p class="verifyhide" ><button type="button" onClick={verifyPhone} class="btn btn-lg onboardedbutton cbutton2 verifyhide" className='' >Continue</button></p>
                    </p>
                  </div>

                  <div class="col-md-1">

                  </div>
                </div>


              </div>


            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
       
        </Modal.Footer> */}
          </Modal>






          <div className="webappinnercontent">


            <div style={{ marginTop: "30px" }} class="formobileonly">

            </div>
            <Container>
              <Row>
                <div className='col-md-12'>
                  <h4 className="webappinnerheading">Verification</h4>
                  <p id="profilenamesettings2">
                    In order to continue streaming songs on this platform, Kindly verify your account below with your NIN or phone number.
                    This is to enable us confirm your identity & prevent duplicate accounts.</p>


                </div>

                <hr className="accountpagehr" />
              </Row>
            </Container>

            <Container >
              <Row className="settingssecondcontainer">
                <div className='col-md-12'>
                  <Nav.Link onClick={showninmodal}>
                    <p>
                      <img src="/images/webapp/susericon.png" />
                      <span className="settingspersonalinfo" >Verify with NIN</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </Nav.Link>

                  <Nav.Link onClick={showphmodal} to='/bank' className="forlisteneronly" >
                    <p className="settingsacc ">
                      <img src="/images/webapp/sbankicon.png" />
                      <span className="settingspersonalinfo" >Verify with phone number</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </Nav.Link>

                  {/* <Nav.Link as={Link}   to='/password'>
       <p className="settingsacc">
           <img src="/images/webapp/spasswordicon.png"/>
       <span className="settingspersonalinfo" >Change Password</span>
           <span style={{float: "right", marginTop:"15px"}}>  <img src="/images/rightarrow.png"/></span>
       </p>
</Nav.Link> */}
                  {/* <Nav.Link as={Link}   to='/login'>
       <p className="settingsacc">
           <img src="/images/webapp/slogouticon.png"/>
       <span className="settingspersonalinfo" >Logout</span>
           <span style={{float: "right", marginTop:"15px"}}>  <img src="/images/rightarrow.png"/></span>
       </p>
       </Nav.Link> */}

                </div>

              </Row>
            </Container>
          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { VerifyPage as default }