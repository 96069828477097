import React, { useEffect, useReducer, useState } from 'react';
import { Container, Row, Form, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import "../stylesheets/website.css"
// import {useHistory} from 'react-router-dom';
import $ from 'jquery';

import Header from './Header';

import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
const ResetPasswordPage = () => {
  const [email, setEmail] = useState("")
  const [notify, setNotify] = useState("")
  useEffect(() => {
    document.title = "Reset Password | The New Trybe"
  }, [])


  const resetPassword = (e) => {
    e.preventDefault()
    setEmail('')
    if (email === "") {
      setNotify('E-mail cannot be empty.')
    }

    else {
      var settings = {
        beforeSend: function () {
          setNotify('Loading...')
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/resetpassword/" + email,
        "method": "POST",
        "timeout": 0,
        error: function (xhr, status, error) {
          if (xhr.status === 404) {
            setNotify("Error")
          }

          else if (xhr.status === 0) {
            setNotify("Error")
          }

          else {
            setNotify(xhr.responseJSON.message)

          }

          console.log(xhr)
          // document.getElementById("errorsignin").innerHTML = data

        },
        "data": {
          "email": email,
          // "phone":"0"
        },

        complete: function () {
          //   setNotify('Loading...')
        }
      }

      $.ajax(settings).done(function (response) {
        setNotify(response.message)

        // window.location.href = 'https://app.thenewtrybe.com/home'; 
      })
    }


    // dispatch.push('/')

  }

  return (

    <div id="resetbody">
      <Container className='page'>
        {/* <Header/> */}
        <Row className="">
          <div className="col-md-12">
            <h1 className='logintitle'>Reset Password</h1>

          </div>


        </Row>
        <Row className="">
          <div className="col-md-3">
          </div>
          <div className="col-md-6">
            <p className='loginnotify'>Reset your password.</p>


          </div>
          <div className="col-md-3">
          </div>


          <div className="col-md-4">
          </div>
          <div className="col-md-4">
            <Form >
              <Form.Group className="mb-3" controlId="formBasicEmail" >

                <Form.Control type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" size="lg" />

              </Form.Group>

              <div className="d-grid gap-2">
                <p className='loginerrornotify'>{notify}</p>
                <Button onClick={resetPassword} className="loginbutton" type="submit">
                  Continue
                </Button>

              </div>



            </Form>


          </div>
          <div className="col-md-4">
          </div>






        </Row>
        {/* <Footer/> */}
      </Container>
    </div>
  )
}


export { ResetPasswordPage as default }