import { v4 as uuidv4 } from 'uuid';




const postsReducer = (state, action) =>{
    switch (action.type){
        case 'POPULATE_POSTS':
            return action.posts
       
            case 'ADD_POST':
              let arr= {title:action.title, body:action.body,id: uuidv4()}
              return {
                ...state,
                posts: [...state.posts, arr]
              };



              case 'EDIT_POST':
                const updatedPost = action.payload;
                const updatedPosts = state.posts.map((post) => {             
                  if (post.id === updatedPost.id) {
                    return updatedPost;
                  }
                  return post;
                });
          console.log(updatedPosts)
                return {
                  ...state,
                  posts: updatedPosts,
                };
      
                

            case 'REMOVE_POST':
          console.log(action.payload)
          const filteredTodos = state.posts.filter(post => post.id !== action.payload.id)
          console.log(filteredTodos)
          return { 
            ...state, 
            posts: filteredTodos
           }

              // return state.posts.filter(
              //   // ({ id }) => id !== action.id
                
              //   );
              // return state.posts.filter(( post ) => post.id !== action.payload);
      
          default:
            return state;
    }
}

export {postsReducer as default}