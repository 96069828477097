import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
import Topbar from './topBar'
import NewNavbar from './newNavbar';

import { Container, Row, Button, Nav, Modal, Form } from 'react-bootstrap'

// import "../stylesheets/style.css"
import "../stylesheets/registration.css"
import "../stylesheets/nav.css"
import "../stylesheets/main.css"
import "../stylesheets/home.css"
import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const NewMusicPage = () => {
  const [thestreamedsongs, setthestreamedsongs] = useState("");
  const [show, setShow] = useState(false);



  var usertoken = localStorage.getItem("access_token")

  // SCROLL DOWN TO LOAD MOE SONGS
  //     useEffect(() =>{
  //     $(window).scroll(function() {
  //       if($(window).scrollTop() == $(document).height() - $(window).height()) {
  //         console.log("jj")
  //              var objarr = []
  //              var songArtists = []   
  //              var allSongsArray = []   
  //              let streamedSongsArray; 
  //              var freshSongs = [];

  //              var settings = {
  //                beforeSend: function(){
  //                  $('.loading').css("visibility", "visible");
  //                          },
  //                        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
  //                        "method": "GET",
  //                        "headers": { "Authorization": "Bearer " + usertoken },
  //                        "timeout": 0,
  //                        complete: function(){
  //                        $('.loading').css("visibility", "hidden");
  //                      }
  //                    }

  //                    $.ajax(settings).done(function (response) {
  //                      console.log(response.streamedsongs)
  //                      streamedSongsArray = response.streamedsongs



  //                    }).then( 
  //                      function() {
  //                      var settingstwo = {
  //                        beforeSend: function(){
  //                          $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(0);
  //                        $('.loading').css("visibility", "visible");
  //                        },
  //                        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs?limit=15&skip=15",
  //                            "method": "GET",
  //                            "timeout": 0,
  //                            "headers": { "Authorization": "Bearer " +  localStorage.getItem("access_token")},
  //                            complete: function(){
  //                              $('.loading').css("visibility", "hidden");
  //                              $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
  //                          }
  //                        }

  //                        $.ajax(settingstwo).done(function (responsetwo) {    
  //                          console.log(settingstwo)

  //                          $.each(responsetwo, function (i, item) {



  //                          allSongsArray.push(responsetwo[i]._id)


  //                          var index;
  //                     for (var i=0; i < streamedSongsArray.length; i++) {
  //                         index = allSongsArray.indexOf(streamedSongsArray[i]);
  //                         if (index > -1) {
  //                             allSongsArray.splice(index, 1);
  //                         }
  //                     }






  //                     }) 




  //                       $.each(allSongsArray, function (i, item) {
  //                         console.log(allSongsArray[i])
  //                                           console.log(allSongsArray[i].length)
  //                                               var settingsfive = {
  //                                                beforeSend: function(){
  //                                                  $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(0);
  //                                                  $('.loading').css("visibility", "visible");
  //                                                  },
  //                                                 "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" +  allSongsArray[i],
  //                                                "method": "GET",
  //                                                "timeout": 0,
  //                                                "headers": { "Authorization": "Bearer " +  localStorage.getItem("access_token")},
  //                                                complete: function(){
  //                                                  $('.loading').css("visibility", "hidden");
  //                                                  $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
  //                                              }
  //                                            }

  //                                            $.ajax(settingsfive).done(function (responsefive) {
  //                                                 console.log(responsefive)


  //                                                 var settingslast = {
  //                                                  beforeSend: function(){
  //                                                    $('.loading').css("visibility", "visible");
  //                                                    },
  //                                                   "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + responsefive.user_id,
  //                                                  "method": "GET",
  //                                                  "timeout": 0,
  //                                                  "headers": { "Authorization": "Bearer " +  localStorage.getItem("access_token")},
  //                                                  complete: function(){
  //                                                    $('.loading').css("visibility", "hidden");

  //                                                }
  //                                              }
  //                                              $.ajax(settingslast).done(function (responselast) {


  //                       if(responsefive.feature == ""){var feature = ""}
  //                       else{var feature = "Ft " + responsefive.feature}

  //                                                    if(responselast.balance !== 0){
  //                                                      $('#musicbox3').append($(
  //                                                      "<a target='_blank' href='/stream?songid="+ responsefive._id +"'>"
  //                                                   +   '<div class="musicitemmain">' 
  //                                                        + "<img class='musicimagemain' src='" +responsefive.songart + "'"

  //                                                        + '<br><p class="textfont moodtitle" style="margin-top:10px;">' +'</p>' 
  //                                                        + '<p class="textfontnewmusic" style="margin-bottom:40px;">' + responsefive.artist_name + " " +  feature  + ": " + responsefive.name   + '</p>' 
  //                                                        +" <a target='_blank' href='/stream?songid="+ responsefive._id +"'>"  +"</a>"

  //                                                        + '</div>'
  //                                                      + '</a>'
  //                                                      ));



  //                                                    }




  //                                                 })


  //                                            })
  //                                             })






  //                    })

  //                  }


  //                    )


  //       }
  //   });
  // },[])
  // SCROLL DOWN TO LOAD MOE SONGS



  useEffect(() => {
    var usertoken = localStorage.getItem("access_token")
    document.title = "New Music | The New Trybe"
    if (usertoken === null) {
      window.location.replace("/login");
    }

    var objarr = []
    var songArtists = []
    var allSongsArray = []
    let streamedSongsArray;
    var freshSongs = [];

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response.streamedsongs)
      streamedSongsArray = response.streamedsongs
      // console.log(response.streamedsongs)
      // setthestreamedsongs(response.streamedsongs)
      // console.log(thestreamedsongs)


    }).then(
      function () {
        var settingstwo = {
          beforeSend: function () {
            $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(0);
            $('.loading').css("visibility", "visible");
          },
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs?limit=50",
          "method": "GET",
          "timeout": 0,
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          complete: function () {
            $('.loading').css("visibility", "hidden");
            $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
          }
        }

        $.ajax(settingstwo).done(function (responsetwo) {

          $.each(responsetwo, function (i, item) {
            if (responsetwo[i].admin_approved === true) {
              allSongsArray.push(responsetwo[i]._id)

            }
            // All Songs Array     
            // allSongsArray.push(responsetwo[i]._id)


            var index;
            for (var i = 0; i < streamedSongsArray.length; i++) {
              index = allSongsArray.indexOf(streamedSongsArray[i]);
              if (index > -1) {
                allSongsArray.splice(index, 1);
              }
            }






          })




          $.each(allSongsArray, function (i, item) {
            console.log(allSongsArray[i])
            console.log(allSongsArray[i].length)
            var settingsfive = {
              beforeSend: function () {
                $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(0);
                $('.loading').css("visibility", "visible");
              },
              "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + allSongsArray[i],
              "method": "GET",
              "timeout": 0,
              "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
              complete: function () {
                $('.loading').css("visibility", "hidden");
                $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
              }
            }

            $.ajax(settingsfive).done(function (responsefive) {
              console.log(responsefive)
              // checkthis = responsefive.user_id
              // $.each(songArtists, function (i, item) {
              //   // console.log(songArtists[i])
              //                       // setTimeout(function(){
              //                         checkthis = songArtists[i]
              //                       })


              var settingslast = {
                beforeSend: function () {
                  // $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);
                },
                "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + responsefive.user_id,
                "method": "GET",
                "timeout": 0,
                "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
                complete: function () {
                  // $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);

                }
              }
              $.ajax(settingslast).done(function (responselast) {
                // console.log(responselast)


                // console.log(responsethree)
                // console.log(responsetwo[i]._id)
                // console.log(streamedsongs[i])

                if (responsefive.feature == "") { var feature = "" }
                else { var feature = "Ft " + responsefive.feature }
                console.log(responsefive._id)
                if (responselast.balance > 0) {
                  $('#musicbox3').append($(
                    "<a target='_blank' href='/stream?songid=" + responsefive._id + "'>"
                    + '<div class="musicitemmain">'
                    // + "<img class='musicimagemain' src='" +responsefive.songart + "'"
                    + "<img class='musicimage'  src='/images/3.jpeg'/> "
                    // + response[i].feature
                    + '<br><p class="textfont moodtitle" style="margin-top:10px;">' + '</p>'
                    + '<p class="textfontnewmusic streamartist" style="margin-bottom:40px;">' + responsefive.artist_name + " " + feature + ": " + responsefive.name + '</p>'
                    + " <a target='_blank' href='/stream?songid=" + responsefive._id + "'>" + "</a>"

                    + '</div>'
                    + '</a>'
                  ));



                }




              })


            })
          })


          // objarr.push(responsetwo[i])



        })

      }


    )


    var checkpayout = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": `https://tnt-backend-7b6098ccaafc.herokuapp.com/api/payout/user/${localStorage.getItem("userid")}`,
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }
    $.ajax(checkpayout).done(function (response) {

      $.each(response, function (i, item) {
        console.log(response[i])
        if (response[i].status === "Pending") {
          $('.requestpayoutbutton5').css("visibility", "hidden");
          setShow(true)
        }
      })

    })























  })



  return (
    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />

        <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
          keyboard={false}>

          <Modal.Header >
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <h5 style={{ textAlign: "center", color: "#fff" }}>Pending Payout</h5>




            <div className="hidediv">


            </div>
            <div className=""  >




              <p style={{ textAlign: "center", color: "#FFFFFF", paddingTop: "40px" }}>You have a pending payout. You will be able to stream after your payout has been processed.</p>
              <br />






            </div>

          </Modal.Body>
          <Modal.Footer>

          </Modal.Footer>
        </Modal>


        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h5 className="webappinnerheading3">New Music Daily</h5>




            <div class='newmusicmain' id='musicbox3'>

            </div>

          </div>
        </div>


      </div>

      <div class="AnyElementYouWantToShowOnAjaxSend">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>

  )
}

export { NewMusicPage as default }