import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Modal } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import "../stylesheets/webapp.css"

import "../js/main.js"




const StreamPage = () => {
  const [Notify, setNotify] = useState("Step 1/2: Click the instagram icon below to follow the artist and earn from this stream. Your payout request will only be processed if you are following the artiste.")
  const [show, setShow] = useState(false);
  const [payoutshow, setPayoutShow] = useState(false);
  const [Songs, AllSongs] = useState("");
  const [thesongid, setthesongid] = useState("");
  const [songtitle, setsongtitle] = useState("Title");
  const [song, setsong] = useState("song");
  const [artist, setartist] = useState("Artist");
  const [genre, setgenre] = useState("Genre");
  const [year, setyear] = useState("Date");
  const [art, setart] = useState("/images/3.jpg");
  const [role, setrole] = useState("");
  const [streamedsongs, setstreamedsongs] = useState("");
  const [artistid, setartistid] = useState("");
  const [artistinstagram, setartistinstagram] = useState("");
  const [artistinstagramid, setartistinstagramid] = useState("");
  const [artistfacebook, setartistfacebook] = useState("");
  const [artisttwitter, setartisttwitter] = useState("");
  const [artisttwitterwithouturl, setartisttwitterwithouturl] = useState("");
  const [artistinstagramwithouturl, setartistinstagramwithouturl] = useState("");
  const [artistimage, setartistimage] = useState("/images/musicplaceholder.jpg");
  const [hasstreamed, sethasstreamed] = useState("false");
  const [instagramcheck, setinstagramcheck] = useState("false");
  const [twittercheck, settwittercheck] = useState("false");
  const [ratingscheck, setratingscheck] = useState("false");
  const [ratingsfigure, setratingsfigure] = useState("");


  if (role === "Artist") {
    $(".forartistonly").css({ 'display': 'block' });
    $(".nmsbuttons").css({ 'display': 'block' });
    $(".artistnotify").css({ 'display': 'block' });
    $(".forlisteneronly").css({ 'display': 'none' });
    $(".nmsbuttons2").css({ 'display': 'none' });
  }
  else {
    $(".forartistonly").css({ 'display': 'none' });
    $(".forlisteneronly").css({ 'display': 'block' });
  }

  var checkpayout = {
    beforeSend: function () {
      $('.loading').css("visibility", "visible");
    },
    "url": `https://tnt-backend-7b6098ccaafc.herokuapp.com/api/payout/user/${localStorage.getItem("userid")}`,
    "method": "GET",
    "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
    "timeout": 0,
    complete: function () {
      $('.loading').css("visibility", "hidden");
    }
  }
  $.ajax(checkpayout).done(function (response) {

    $.each(response, function (i, item) {
      console.log(response[i])
      if (response[i].status === "Pending") {
        $('.requestpayoutbutton5').css("visibility", "hidden");
        setPayoutShow(true)
      }
    })

  })


  // console.log(streamedsongs)
  // console.log(thesongid)
  useEffect(() => {
    document.title = "Stream | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    console.log(usertoken)
    if (usertoken === null) { window.location.replace("/login"); }

    // GET LOGGED IN USER ROLE AND STREAMED SONGS

    var settings = {
      beforeSend: function () {
        $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);

      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
      complete: function () {
        $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
      }
    }
    $.ajax(settings).done(function (response) {
      var allstreamedsongs = response.allstreamedsongs
      setrole(response.user_role)
      setstreamedsongs(response.allstreamedsongs)

    })
    // GET LOGGED IN USER ROLE AND STREAMED SONGS

    // GET ALL SONGS TO FILTER THROUGH 
    let artistuserid
    var pathname = window.location.href;
    console.log(pathname)
    let params = (new URL(pathname)).searchParams;
    var songid = params.get('songid') // "n1"
    var settingstwo = {
      beforeSend: function () {
        $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);

      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + songid,
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      //         complete: function(){
      //           $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
      // }
    }

    $.ajax(settingstwo).done(function (responsetwo) {
      console.log(responsetwo)

      setthesongid(responsetwo._id)
      setsongtitle(responsetwo.name)
      setartist(responsetwo.artist_name)
      setgenre(responsetwo.genre)
      setyear(responsetwo.createdAt)
      setart(responsetwo.songart)
      setartistid(responsetwo.user_id)
      setsong(responsetwo.songlink)

      artistuserid = responsetwo.user_id
      $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);

      var settingsthree = {
        beforeSend: function () {
          $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/user/" + responsetwo.user_id,
        "method": "GET",
        "timeout": 0,
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        complete: function () {
          $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
        }
      }
      $.ajax(settingsthree).done(function (response) {
        console.log(response)
        var totalno = response.length

        $.each(response, function (i, item) {
          if (response[i].feature == "") {
            var feature = ""
          }
          else {
            var feature = "Ft " + response[i].feature
          }
          $('#musicbox3').append($(
            "<a target='_blank' href='/newmusicsingle?songid=" + response[i]._id + "'>"

            + '<div class="musicitemmain">'
            + "<img class='musicimagemain' src='" + response[i].songart + "'"
            // + response[i].feature
            + '<br><p class="textfont2" style="color:#fff; margin-top:10px;">' + response[i].artist_name + " " + feature + "  : " + response[i].name + '</p>'
            + " <a target='_blank' href='/stream?songid=" + response[i]._id + "'>" + "</a>"

            + '</div>'
            + '</a>'
          ));

        })
      })

      var settingsuser = {
        beforeSend: function () {
          $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);
          $('.loading').css("visibility", "visible");
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + artistuserid,
        "method": "GET",
        "timeout": 0,
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        complete: function () {
          $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
        }
      }

      $.ajax(settingsuser).done(function (responseuser) {
        console.log(responseuser)
        setartistinstagramwithouturl(responseuser.instagram)
        setartistfacebook(responseuser.facebook)
        // setartisttwitter(`https://twitter.com/${responseuser.twitter}`)
        setartistinstagram(`https://instagram.com/${responseuser.instagram}`)
        setartisttwitterwithouturl(responseuser.twitter)
        setartistinstagramid(responseuser.instagramid)


      })

      // AllSongs(responsetwo)
    })
    // GET ALL SONGS TO FILTER THROUGH 



  }, [])

  useEffect(() => {
    for (let i = 0; i < streamedsongs.length; i++) {
      console.log(streamedsongs[i])
      if (thesongid === streamedsongs[i]) {
        console.log("its a match")
        // document.getElementById('streamedstatus').value = true
        // console.log(document.getElementById('streamedstatus').value )
        $(".alreadyearned").css({ 'display': 'block' });
        $(".qdet1").css({ 'display': 'none' });
        sethasstreamed("true")
        break;

      }
      else {
        $(".alreadyearned").css({ 'display': 'none' });
        $(".qdet1").css({ 'display': 'block' });
      }
    }
  },)

  // GET SELECTED ARTIST SOCIAL MEDIA HANDLE SO LISTENER CAN FOLLOW AFTER STREAM
  //    useEffect(() =>{
  //     console.log(artistid)
  //    var settingsuser = {
  //     beforeSend: function(){
  //       $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);
  // $('.loading').css("visibility", "visible");
  // },
  // "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + artistid,
  //         "method": "GET",
  //         "timeout": 0,
  //         "headers": { "Authorization": "Bearer " +  localStorage.getItem("access_token")},
  //         complete: function(){
  //           $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
  // }
  //     }

  //     $.ajax(settingsuser).done(function (responseuser) {
  // console.log(responseuser)
  // setartistinstagramwithouturl(responseuser.instagram)
  // setartistfacebook(responseuser.facebook)
  // // setartisttwitter(`https://twitter.com/${responseuser.twitter}`)
  // setartistinstagram(`https://instagram.com/${responseuser.instagram}`)
  // setartisttwitterwithouturl(responseuser.twitter)
  // setartistinstagramid(responseuser.instagramid)


  //     })
  //   },[artistid])
  // GET SELECTED ARTIST SOCIAL MEDIA HANDLE SO LISTENER CAN FOLLOW AFTER STREAM


  // PLAY, PAUSE SONG AND DISPLAY FOLLOW MODAL
  var x = document.getElementById("myAudio")


  const playAudio = () => {
    console.log(hasstreamed)
    x.play();
    var intervalId = window.setInterval(function () {
      var duration = document.getElementById("myAudio").duration;
      var currenttime = x.currentTime
      var totalduration = duration / 60
      var curtime = currenttime / 60
      document.getElementById("totalduration").innerHTML = totalduration.toFixed(2)
      document.getElementById("currentduration").innerHTML = curtime.toFixed(2)

      // HANDLE SONG ENDED
      var songEnded = document.getElementById("myAudio").ended
      var usertype = document.getElementById("defaultprofiletype").value
      // var sstatus = document.getElementById('streamedstatus').value 
      console.log(songEnded)
      // console.log(hasstreamed)
      if (songEnded === true && hasstreamed === "false" && role === "Listener") {
        clearInterval(intervalId)

        // $(".nmsbuttons").css({ 'display': 'none' });
        $(".alreadyearned").css({ 'display': 'block' });
        setShow(true)
        // $("#myModal").modal('show');  
      }
      else {
        // setShow(true)
      }
    })
  }

  const pauseAudio = () => {
    x.pause();
  }
  // PLAY, PAUSE SONG AND DISPLAY FOLLOW MODAL

  // TRACK SOCIAL MEDIA CLICKS
  //     const  igclick = () => {

  //       setinstagramcheck("true")
  //       $(".fa-instagram").css({ 'color': '#7BFFB3' });
  //  }



  const igclick = () => {
    console.log(artistinstagramwithouturl)
    console.log(localStorage.getItem("userinstagram"))
    // setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to follow ${artistinstagramwithouturl}, Do not refresh the page after following, This page will be updated in a few seconds`)
    setNotify(`Follow ${artistinstagramwithouturl} on Instragram, Your payout request will only be processed if you are following the artiste with this account ${localStorage.getItem("userinstagram")}. Rate the song below to continue`)
    $('.followtwitter').css("display", "none");
    setinstagramcheck("true")


    //   const checkInterval = setInterval(function(){ 
    //   const settings = {
    // 	"async": true,
    // 	"crossDomain": true,
    // 	"url": `https://instagram28.p.rapidapi.com/search_followers?user_id=${artistinstagramid}&query=${localStorage.getItem("userinstagram")}`,
    // 	"method": "GET",
    // 	"headers": {
    // 		"x-rapidapi-host": "instagram28.p.rapidapi.com",
    // 		"x-rapidapi-key": "e159be1a99msh1d7ba1d55b1aba3p1a984fjsn9dab505cd247"
    // 	},
    //   error: function(xhr, status, error) {
    //     console.log(xhr)    
    // },};

    // $.ajax(settings).done(function (response) {
    //   console.log(response);
    //   console.log(response.users.length);
    //   if(response.users.length === 0){
    //     setNotify(` ${localStorage.getItem("userinstagram")} has not followed ${artistinstagramwithouturl}, Do not refresh the page after following, This page will be updated in a few seconds`)
    //     console.log("User has not followed")
    //   }
    //   else{
    //     setNotify(` ${localStorage.getItem("userinstagram")} has followed ${artistinstagramwithouturl}. Rate the song below to continue.`)
    //     $('.followtwitter').css("display", "none");
    //     setinstagramcheck("true")
    //     clearInterval(checkInterval);

    //   }
    // });
    // }, 10000);
  }


  const ratings5click = () => {
    console.log("5 rating")
    console.log(document.getElementById("star5").value)
    setratingscheck("true")
    setratingsfigure(5)
  }

  const ratings4click = () => {
    console.log("4 rating")
    console.log(document.getElementById("star4").value)
    setratingscheck("true")
    setratingsfigure(4)
  }

  const ratings3click = () => {
    console.log("3 rating")
    console.log(document.getElementById("star3").value)
    setratingscheck("true")
    setratingsfigure(3)
  }

  const ratings2click = () => {
    console.log("2 rating")
    console.log(document.getElementById("star2").value)
    setratingscheck("true")
    setratingsfigure(2)
  }

  const ratings1click = () => {
    console.log("1 rating")
    console.log(document.getElementById("star1").value)
    setratingscheck("true")
    setratingsfigure(1)
  }

  //  const  ratingsclick = () => {
  //  console.log("hello")
  //  setratingscheck("true")
  //  console.log(document.getElementById("ratingfigure").value)
  //  console.log(document.getElementById("ratingfigure").value)
  //  console.log(document.getElementById("ratingfigure").value)
  //  console.log(document.getElementById("ratingfigure").value)
  //  console.log(document.getElementById("ratingfigure").value)
  // setinstagramcheck("true")
  // $(".fa-instagram").css({ 'color': '#7BFFB3' });
  // }


  // TRACK SOCIAL MEDIA CLICKS


  // SUBMIT 
  const submitFunction = () => {
    var ig = instagramcheck
    var ratings = ratingscheck


    console.log(ratings)



    if (ig !== "true") {
      setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to follow ${artistinstagramwithouturl}, Do not refresh the page`)

    }

    else if (ratings !== "true") {
      setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to rate the song`)

    }

    else {
      $('.fundaccountbutton2').css("display", "none");
      console.log("hi")
      setNotify("Processing...")





      // ADD N8 TO LISTENER USER ID, ADD STREAMED SONG ID TO USER ID TO INDICATE STREAMED SONGS

      var settings = {
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
        "method": "GET",
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        "timeout": 0,
      }

      $.ajax(settings).done(function (response) {
        var earning = 40
        var earningint = earning + response.earnings
        var settingsearnings = {
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
          "method": "PATCH",
          "timeout": 0,
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          "data": {
            "earnings": earningint,
            "streamedsongs": thesongid,
            "allstreamedsongs": thesongid,
            "picture": localStorage.getItem("picture"),
            "bankname": localStorage.getItem("bank"),
            "phone": localStorage.getItem("loggedinuserphone"),
            "onboarded": true,




            "instagram": localStorage.getItem("userinstagram"),
            "twitter": localStorage.getItem("usertwitter"),
            "about": localStorage.getItem("about"),
            "instagramid": localStorage.getItem("instagramid"),
            "about": localStorage.getItem("about"),
            "stagename": localStorage.getItem("stagename"),


          }
        }
        $.ajax(settingsearnings).done(function (response) {
          console.log(response)
        })
        // ADD N8 TO USER ID, ADD STREAMED SONG ID TO USER ID TO INDICATE STREAMED SONGS


        // ADD 1 EXTRA STREAM TO NO OF STREAMS ON SONG ID
        var updatenoofstreams = {
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + thesongid,
          "method": "GET",
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          "timeout": 0,
        }
        $.ajax(updatenoofstreams).done(function (response) {
          console.log(response)
          var noofstreams = 1
          var noofstreamsint = noofstreams + response.noofstreams
          console.log(noofstreamsint)

          console.log(ratingsfigure)
          if (ratingsfigure === 5) {
            var ratings5 = 5 + response.fivestar
          }
          else {
            var ratings5 = response.fivestar
          }

          if (ratingsfigure === 4) {
            var ratings4 = 4 + response.fourstar
          }
          else {
            var ratings4 = response.fourstar
          }

          if (ratingsfigure === 3) {
            var ratings3 = 3 + response.threestar
          }
          else {
            var ratings3 = response.threestar
          }

          if (ratingsfigure === 2) {
            var ratings2 = 2 + response.twostar
          }
          else {
            var ratings2 = response.twostar
          }

          if (ratingsfigure === 1) {
            var ratings1 = 1 + response.onestar
          }
          else {
            var ratings1 = response.onestar
          }


          var audience = localStorage.getItem("userid")
          var updatenoofstreams2 = {
            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + thesongid,
            "method": "PATCH",
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "timeout": 0,
            "data": {
              "noofstreams": noofstreamsint,
              "audience": audience,
              "onestar": ratings1,
              "twostar": ratings2,
              "threestar": ratings3,
              "fourstar": ratings4,
              "fivestar": ratings5,

            }
          }

          $.ajax(updatenoofstreams2).done(function (response) {
            console.log(response)
          })

          // ADD 1 EXTRA STREAM TO NO OF STREAMS ON SONG ID

          // DEDUCT N8 FROM STREAMED ARTISTS BALANCE
          var settingsdeduct = {
            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + response.user_id,
            "method": "GET",
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "timeout": 0,
          }
          $.ajax(settingsdeduct).done(function (response) {
            var deduct = 50
            var balance = response.balance
            var newbalance = balance - deduct

            var add = 1
            var tnos = response.totalnoofstreams
            var tnos2 = tnos + add
            var settingsdeduct2 = {
              "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user/" + response._id,
              "method": "PATCH",
              "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
              "timeout": 0,
              "data":
              {
                "balance": newbalance,
                "totalnoofstreams": tnos2
              }
            }
            $.ajax(settingsdeduct2).done(function (response) {
              console.log(response)
              document.getElementById("follownotify").innerHTML = "Success!!"
              window.location.replace("/newmusic");
            })
          })

        })
        // DEDUCT N8 FROM STREAMED ARTISTS BALANCE



      })
    }




  }




  // SUBMIT 

  // SEARCH SUGGESTIONS
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    $('.loading').css("visibility", "visible");
    $('#searchresult').css("display", "block");
    // $('#searchresult').fadeIn();
    console.log(item)
    // setthesongid(item._id)
    // setsongtitle(item.name)
    // setartist(item.artist_name)
    // setgenre(item.genre)
    // setyear(item.createdAt)
    //  setart(item.songart)
    //  setartistid(item.user_id)
    //  setsong(item.songlink)



    //  function pageloader() {
    //   $('.loading').css("visibility", "hidden");
    //  }
    //  setTimeout(pageloader, 3000); 


    // $('#searchresult').css("display", "block");
    // $('#searchresult').fadeIn(2000);


  }



  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }
  // SEARCH SUGGESTIONS



  // },[thesongid, songtitle, artist, genre, year, streamedsongs])


  return (

    <div className='d-flex'>

      <Modal id="myModal" class="modal fade modal-content" show={payoutshow} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Pending Payout</h5>




          <div className="hidediv">


          </div>
          <div className=""  >




            <p style={{ textAlign: "center", color: "#FFFFFF", paddingTop: "40px" }}>You have a pending payout. You will be able to stream after your payout has been processed.</p>
            <br />






          </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>

      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">


            <hr className="searchhr" />


            {/* <div class="row" > */}
            <Container id="">
              <Row >
                <div class="col-md-3">
                  <p style={{ textAlign: "center" }} class=""><img className="songart1366r" style={{ borderRadius: "24px " }} id="songart"
                    src={art} /></p>
                </div>

                <input type="hidden" id="loggedinuserid" value="" />
                <input type="hidden" id="streamedstatus" value="" />
                <div class="col-md-9">
                  <div class="">
                    <h6 class="nmsartistalbum streamsongtitle streamartist" id="songtitle">{songtitle}</h6>
                    <h4 class="nmsartistname streamartist" id="artist">{artist}</h4>
                    <p class="nmsartistyear nmsartistyear1024">
                      <span id="genre">{genre}</span> - <span id="year">{year}</span>
                    </p>
                    <p class="qdet1 streamqdet1366">Note: Earnings are recorded at the end of the song.</p>
                    <p class="alreadyearned alreadyearned1024">Note: You have already streamed this song, You will not earn from this stream </p>
                    <p style={{ color: "#FFF" }} class="forartistonly">Note: You are logged in as an artist, You will not earn on this stream.</p>



                    <input type="hidden" value="" id="userid" />
                    {/* <hr/> */}

                    <div>
                      <audio style={{ width: "500px", borderRadius: "0px!important" }} src={song} id="myAudio"></audio>
                      <p class="playbutton">

                        <button className="controlbuttons1" onClick={playAudio} type="button"><i
                          class="fas fa-play "></i>
                        </button>

                        <button className="controlbuttons1" onClick={pauseAudio} type="button"><i
                          class="fas fa-pause "></i></button>
                      </p>
                      {/* <button className="controlbuttons" onclick="" type="button"><a id="myAudioLink" href=""
        target="_blank"><i class="fas fa-download "></i></a></button> */}

                      {/* <button className="controlbuttons2" 

          
          onclick="copyFunction()" type="button">Copy Song Link</button> */}
                      <p style={{ marginTop: "20px" }} class="nmsbuttons mobilecentercount nmsbuttons1024"><span id="currentduration">0:00</span> : <span id="totalduration">0:00</span></p>





                    </div>

                    <div>
                      <div class="">
                        {/* <p class="qdet1">1 Song, 3 Minutes</p>
        <p class="qdet1" id="qdet2">Release Date</p> */}

                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <div class="newmusicsingleboxtwo">
                <div class="containerf">
                  <div class="containerf nmcont">
                    {/* <h1 class="mt-4 menuheading moreby">More by Artist </h1> */}




                  </div>
                </div>


              </div>
            </Container>
            {/* </div> */}
            {/* <h1 class="mt-4 menuheading moreby containers">More from Artist </h1>
<div class='containers newmusicmain' id='musicbox3'>

</div> */}

          </div>
        </div>

      </div>

      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {/* <h5 style={{textAlign:"center", color:"#fff"}}>Almost There</h5> */}




          <div className="hidediv">

            {/* <p className="requestnotify2">{Notify}</p> */}
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
            {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}

            {/* <p style={{textAlign:"center"}}> <button  onClick={requestPayout2 } type="button" class="btn btn-lg" className='fundaccountbutton2' >Request Payout</button></p> */}
          </div>
          <div className=""  >
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
            <h5 style={{ textAlign: "center", color: "#FFF" }}> Follow {artist} on Instagram </h5>
            {/* <p style={{textAlign:"center", marginTop:"30px"}}><img class="clientimg" style={{borderRadius:"50%"}} width="150" height="150" id="followimg"
              src={artistimage}/></p> */}


            <p style={{ textAlign: "center", marginTop: "30px" }} id="follownotify">{Notify}</p>

            <p style={{ textAlign: "center", paddingTop: "20px" }}>
              {/* 
            <a onClick={igclick} id="followig" target="_blank" href={artistinstagram}> <i style={{fontSize:"28px", color:"#fff "}} 
                class="fab fa-instagram"></i></a> */}



              <a onClick={igclick} id="followtwitter" className="followtwitter" target="_blank" href={artistinstagram}>
                <i style={{ fontSize: "28px", marginLeft: "0px", color: "#fff " }} class="fab fa-instagram"></i></a>

              <input type="hidden" id="instagramcheck" value={instagramcheck} />
              {/* <input type="hidden" id="twittercheck" value={twittercheck} /> */}
              <input type="hidden" id="ratingscheck" value={ratingscheck} />

            </p>
            <div class="row">
              <div class="col-md-3">

              </div>

              <div class="col-md-5 col-12">
                <fieldset class="rating" >
                  <input type="radio" id="star5" name="rating" value="5" onClick={ratings5click} /><label id="ratingfigure" class="full" for="star5" title=" 5 stars"></label>
                  <input type="radio" id="star4" name="rating" value="4" /><label id="ratingfigure" onClick={ratings4click} class="full" for="star4" title=" 4 stars"></label>
                  <input type="radio" id="star3" name="rating" value="3" /><label id="ratingfigure" onClick={ratings3click} class="full" for="star3" title="3 stars"></label>
                  <input type="radio" id="star2" name="rating" value="2" /><label id="ratingfigure" onClick={ratings2click} class="full" for="star2" title=" 2 stars"></label>
                  <input type="radio" id="star1" name="rating" value="1" /><label id="ratingfigure" onClick={ratings1click} class="full" for="star1" title=" 1 star"></label>


                </fieldset>
              </div>

              <div class="col-md-3">

              </div>
            </div>


            {/* <p style={{textAlign:"center"}}> <button onclick="submitFunction()" type="button" class="btn btn-lg"
              style="color:white; background-color:#FA2D48; font-size:10px">SUBMIT</button></p> */}


            <p style={{ textAlign: "center" }}><button onClick={submitFunction} type="button" class="btn btn-lg" className='fundaccountbutton2' >Continue</button></p>

          </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>



      {/* 
    <div class="loading">
      <div class="loading-text">
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">H</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">N</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words">W</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">R</span>
        <span class="loading-text-words">Y</span>
        <span class="loading-text-words">B</span>
        <span class="loading-text-words">E</span>
    
      </div>
    </div> */}

      <div class="AnyElementYouWantToShowOnAjaxSend">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { StreamPage as default }