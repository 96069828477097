import React, { useEffect, useReducer } from 'react';
import $ from 'jquery';
import Topbar from './topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const RapDaily = () => {



  useEffect(() => {
    document.title = "Top 100 Rap | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }


    console.log(usertoken)


    var r = []

    console.log(r)
    function myFunction2(x, myid = myid.reverse()) {
      //   var btn = $(".playbutton");
      // btn.toggleClass("paused");
      // return false;

      var settings = {
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/singles/" + myid[x.rowIndex - 1],
        "method": "GET",
        "timeout": 0,
      }

      $.ajax(settings).done(function (response) {
        console.log(response)
        document.getElementById("myAudio").src = response.song_link;

      }).then(
        function () {
          var userttype = document.getElementById("profiletype").innerHTML
          if (userttype === "Artist") {
            alert("You are logged in as an artist, You wont earn on this stream.")
          }
          else {
            var song = document.getElementById("myAudio")
            console.log(song)
            song.play();
          }

        }
      )

    }



    var usertoken = localStorage.getItem("access_token")
    console.log(usertoken)
    if (usertoken === null) {
      window.location.replace("/login");

    }

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      if (response.user_role == "Artist") {
        $(".forartistonly").css({ 'display': 'block' });
        $(".nmsbuttons").css({ 'display': 'none' });
        $(".artistnotify").css({ 'display': 'block' });
        $(".forlisteneronly").css({ 'display': 'none' });

      }
      if (response.picture == "") {
        document.getElementById("profilepicture").src = "/images/usericon.png"
      }
      else {
        document.getElementById("profilepicture").src = response.picture
      }

      if (response.user_role === "Listener") {
        document.getElementById("profilename").innerHTML = response.firstname + " " + response.lastname
        document.getElementById("profilemoney").innerHTML = "Earnings: &#x20A6;" + (response.streamedsongs.length - 1) * 15

      }

      else if (response.user_role === "Artist") {
        document.getElementById("profilename").innerHTML = response.stagename
        document.getElementById("profilemoney").innerHTML = "Balance: &#x20A6;" + response.balance.toLocaleString()

      }
      document.getElementById("profiletype").innerHTML = response.user_role

      document.getElementById("userid").value = response.id


      var settingstwo = {
        beforeSend: function () {
          $('.ajax-loader').css("visibility", "visible");
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs",
        "method": "GET",
        "timeout": 0,
        "headers": { "Authorization": "Bearer " + usertoken },
        complete: function () {
          $('.ajax-loader').css("visibility", "hidden");
        }
      }

      var objarr = []
      var songArtists = []
      var totalno = []


      $.ajax(settingstwo).done(function (responsetwo) {
        var homes = responsetwo
        const sortArray = homes

        sortArray.sort((a, b) => {
          if (a.noofstreams > b.noofstreams)
            return -1;
          if (a.noofstreams < b.noofstreams)
            return 1;
          return 0;
        });

        var totalno = responsetwo.length

        $.each(sortArray, function (i, item) {


          songArtists.push(sortArray[i].user_id)

          let checkthis


          $.each(songArtists, function (i, item) {
            // console.log(songArtists[i])
            // setTimeout(function(){
            checkthis = songArtists[i]
          })
          console.log(sortArray[i].noofstreams)

          //  CUT THIS
          if (sortArray[i].genre == "Rap") {


            var onestar = sortArray[i].onestar
            var twostar = sortArray[i].twostar
            var threestar = sortArray[i].threestar
            var fourstar = sortArray[i].fourstar
            var fivestar = sortArray[i].fivestar
            var sum = onestar + twostar + threestar + fourstar + fivestar


            var average1 = (1 * sortArray[i].onestar) + (2 * sortArray[i].twostar) + (3 * sortArray[i].threestar) + (4 * sortArray[i].fourstar) + (5 * sortArray[i].fivestar)

            var average2 = average1 / sum

            let ratings

            if (isNaN(average2)) {
              ratings = 0
            }
            else {
              ratings = average2.toFixed(1)
            }

            //    if(average2=="NaN") {
            //     return 0.00;
            // } else {
            //     return average2;
            // }

            //  console.log(typeof(average1))
            //  console.log(average2)

            //  if(average2  === "NaN"){
            //    var average = 0
            //    console.log(average)
            //  }
            //  else{
            //   var average = average2
            //  }



            var defaultDates = sortArray[i].createdAt
            var d = new Date(defaultDates).toString();
            var actualdate = d.split(' ').splice(0, 4).join(' ')
            r.push(sortArray[i]._id)

            if (sortArray[i].feature == "") {
              var feature = "-"
            }
            else {
              var feature = sortArray[i].feature
            }
            $('<tr onclick="myFunction2(this, r)">').html(

              //  "<td>"+ "<i class='fas fa-play'></i>"+ "</td>"
              // "<td>"+ response[i].id+ "</td>"

              //  "<td class='centerthis'> <img width='20px' height='20px' src='" + response[i].song_art + "'>" + "</td>"
              "<td class='centerthis'> " + "" + "</td>"
              + "<td class='centerthis'> " + sortArray[i].noofstreams + "</td>"

              //  "<td class='centerthis'><button class='playbutton'></button>" + "</td>"

              + "<td class='centerthis firstnametd'>" + sortArray[i].name + "</td>"

              + "<td class='centerthis firstnametd'>" + sortArray[i].artist_name + "</td>"
              + "<td class='centerthis firstnametd'>" + ratings + "</td>"
              // + "<td>"+ '<p class="centerthis firstnametd">' +  '<a target="_blank" style="color:#fff" href="'+ responsetwo[i].song_link +'"><i class="fas fa-download"></i></a>'  + "</p></td>"

            ).appendTo('#records_table');
            $('.firstnametd').click(false);

          }
          //  CUT THIS


          // console.log(checkthis)
          // console.log(songArtists[i])
          var settingsthree = {
            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + checkthis,
            "method": "GET",
            "timeout": 0,
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          }
          $.ajax(settingsthree).done(function (responsethree) {
            // console.log(responsethree)

            if (responsethree.balance !== 0) {

              // IPASTE HERE
              // IPASTE HERE
            }
          })


          objarr.push(responsetwo[i])






        })
      })



    })




























  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading3 ">Top 100 Rap</h4>
            <div class="container newmusicsinglebox">

              {/* <!-- <h1 class="mt-4 menuheading">New Music </h1> --> */}
              <div class="row">
                <input type="hidden" id="userid" value="" />

                <div class="col-md-3">
                  {/* <!-- <div class="card hottrackmoodcard-block hottrack-1"></div> --> */}
                  <p class="bannerp mobilebannerp"><img className="top100banner" src="/images/webapp/top100/rap.png" /></p>
                </div>


                <div class="col-md-9">
                  <div class="">
                    <h4 class="nmsartistalbum top100singlehead">Top 100: Rap</h4>
                    <h4 class="nmsartistname">The New Trybe</h4>
                    <p class="nmsartistyear"><span className="editorsnotetop100">The most-played Rap songs on The New Trybe, updated every day.</span></p>
                    <p>

                      {/* <!-- <p class="nmsbuttons">
        <a href="/dailytop100"><button type="button" class="btn btn-lg"style="color:white; background-color:#FA2D48; font-size:13px" >PLAY</button></a>
        <a href="/dailytop100"><button type="button" class="btn btn-lg"style="color:white; background-color:#FA2D48; font-size:13px" >SHUFFLE</button></a>
</p> --> */}
                      <p style={{ color: "#fff" }} class="artistnotify mobilebannerp">Create/Login to a listeners account to stream</p>

                    </p>

                    <hr />




                    <div>

                    </div>
                  </div>
                </div>
              </div>

              <Row>
                <div class="col-md-12">
                  <div class="">
                    <div class="row dailybox">
                      <table class="table  css-serial toptable">
                        <thead class="thead">
                          <tr class="ippisschedulehead">
                            {/* <!-- <th class="ippiscol0" scope="col">Song ID</th> --> */}
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">S/N</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">No Of Plays</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Song</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Artist</th>
                            <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Ratings</th>
                            {/* <!-- <th style="text-align:center" class="ippiscol0" scope="col">Ratings</th> --> */}
                            {/* <!-- <th class="ippiscol0" scope="col">Featuring</th>
                    <th class="ippiscol0" scope="col">Producer</th>
                    <th class="ippiscol0" scope="col">Streams</th> --> */}

                          </tr>
                        </thead>

                        <tbody id="records_table">
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </Row>
            </div>

          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { RapDaily as default }