import React, { useEffect, useState, useContext, FloatingLabel, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Form } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const PasswordPage = () => {
  const { users, dispatch } = useContext(PostsContext)
  const [useremail] = useState(localStorage.getItem("useremail"));


  console.log(users[0])

  useEffect(() => {
    document.title = "Personal Information | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      if (response.user_role == "Listener") {
        $(".forartistonly").css({ 'display': 'none' });
      }
      else {
        $(".forartistonly").css({ 'display': 'block' });

      }



    })

  })

  const updateUserInfo = () => {

    var bank = document.getElementById("bank").value
    var accountnumber = document.getElementById("accountnumber").value
    var accountname = document.getElementById("accountname").value



    var settingsupdate = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "PATCH",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "data": {
        "bank": bank,
        "accountnumber": accountnumber,
        "accountname": accountname,

        //   "picture": picture,
        //   "bankname": bankname,
        //   "accountnumber": accountnumber ,
        //   "phone":phone,
        //   "earnings":earnings,
        //   "facebook": facebook, 
        //   "instagram": instagram,
        //   "twitter": twitter,
        // "youtube": youtube ,
        //   "about": about,
        //   "stagename": stagename,
        //   "onboarded": true,
      },

      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingsupdate).done(function (response) {
      console.log(response)
      window.location.replace("/personalinfo");

    })
  }



  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Personal Information</h4>
            <Container>

              <Row>
                <h5 className='pismh'>Change Password</h5>

                <div className='col-md-6'>



                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" id="accountnumber" placeholder="Old Password" size="lg" />
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput">
                    <Form.Control type="email" id="accountname" placeholder="New Password" size="lg" />
                  </Form.Group>





                  <button onClick={updateUserInfo} className='btn-block pibutton'>Save</button>



                  {/* <FloatingLabel controlId="floatingTextarea" label="Comments" className="mb-3">
    <Form.Control as="textarea" placeholder="Leave a comment here" />
  </FloatingLabel>
  <FloatingLabel controlId="floatingTextarea2" label="Comments">
    <Form.Control
      as="textarea"
      placeholder="Leave a comment here"
      style={{ height: '100px' }}
    />
  </FloatingLabel> */}

                </div>



              </Row>




              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Container>

          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { PasswordPage as default }