import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import Email from '@sendgrid/mail';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const PayoutPage = () => {


  // TABS
  // useEffect(() =>{
  const [Notify, setNotify] = useState("")
  const [RequestedAmount, setRequestedAmount] = useState("")
  const [CurrentAmount, setCurrentAmount] = useState("")
  const [show, setShow] = useState(false);


  // },[Notify])


  useEffect(() => {
    document.title = "Payouts | Listener | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var checkpayout = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": `https://tnt-backend-7b6098ccaafc.herokuapp.com/api/payout/user/${localStorage.getItem("userid")}`,
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }
    $.ajax(checkpayout).done(function (response) {
      console.log(response)
      $.each(response, function (i, item) {
        console.log(response[i])
        // if(response[i].status ==="Pending"){
        //   $('.requestpayoutbutton').css("visibility", "hidden");
        //   setNotify("You have a pending payout request.")
        // }
        var defaultDates = response[i].createdAt
        var d = new Date(defaultDates).toString();
        var actualdate = d.split(' ').splice(0, 4).join(' ')

        $('<tr onclick="myFunction2(this, r)">').html(
          "<td class='centerthis firstnametd'>" + "" + "</td>"

          + "<td class='centerthis firstnametd'>" + actualdate + "</td>"
          + "<td class='centerthis firstnametd'>" + "&#8358; " + response[i].amountrequested.toLocaleString() + "</td>"
          + "<td class='centerthis firstnametd'>" + response[i].status + "</td>"


        ).appendTo('#records_table');
      })


    })




    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)

      // document.getElementById("tnos").innerHTML= response.earnings.toLocaleString()
      var ca = response.earnings
      setCurrentAmount(ca)



    })

  }, [])






  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Dashboard - Payouts</h4>


            <Container >
              <Row className="settingssecondcontainer">

                <div className=''>
                  <div className=''>
                    <div class="row">




                      <div class="col-md-12">
                        <div class="row mobilerow">
                          <div className="">

                            <table class="table  css-serial">
                              <thead class="thead">
                                <tr class="ippisschedulehead">
                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">S/N</th>

                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Date</th>

                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Amount</th>
                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Status</th>




                                </tr>
                              </thead>

                              <tbody id="records_table">
                              </tbody>
                            </table>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>





              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>



      {/* <div class="loading">
      <div class="loading-text">
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">H</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">N</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words">W</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">R</span>
        <span class="loading-text-words">Y</span>
        <span class="loading-text-words">B</span>
        <span class="loading-text-words">E</span>
    
      </div>
    </div> */}
    </div>


  )


}


export { PayoutPage as default }