import React, { useEffect, useReducer} from 'react';
import {Container, Row,Button,Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Header from './Header';
import "../stylesheets/website.css"
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
const AboutPage = () => {
    
    useEffect(() =>{
        document.title = "About | The New Trybe"
    },[])


{/* // Load data once */}
// useEffect(() =>{
//     const posts = JSON.parse(localStorage.getItem('posts'))
//     if(posts){
//         dispatch({type:'POPULATE_POSTS', posts})
//     }
// },[])
{/* // Load data once */}



{/* // Load data on condition */}
// useEffect(() =>{
//     localStorage.setItem('posts', JSON.stringify(posts))
// },[posts])
{/* // Load data on condition */}


    return (
        <div>
<Header/>
<div id="aboutpage">
<Container className='page'>

    <Row className="aboutpagefirstbox">
        <div className="col-md-5">
            <h2 className='abouttitle'>Who we are.</h2>
            <p className='homepagedescription'>The New Trybe was created for Emerging artistes to get their music to their audience faster. 
            You don’t need to wait to be discovered, you can now upload a song and 
            reach thousands of people in minutes even if you just started your career today.  </p>   
            <p className='homepagedescription'>Our reward system
             created the listeners platform so that listeners discovering new artistes can enjoy
              cash rewards for every new song they stream.  </p>   
              {/* <Nav.Link  as={Link} to="/signup" >  <Button  className="aboutctabutton" >Create an account</Button></Nav.Link> */}
            </div>

            <div className="col-md-1">
                </div>

            <div className="col-md-6 aboutfirstboximg">
            <img className="img-fluid afbimg" src="/images/aboutfirstboximg.png"/>
            </div>
    </Row>

<br/>
<br/>
<br/>
<br/>


{/* 
    <Row className="aboutpagesecondbox">
        <div className="col-md-12">

        <h2 className='abouttitle2'>For artists.</h2>
            <p className='aboutdescription'>
            We celebrate new artists and so we created this platform so that you can upload a song and watch it go viral even if you just started your career today.
Do you have good songs you want to get out to the world, but are struggling to get discovered by big labels and make it in the music industry? Well, we’ve got you. On the new trybe, the game is fair and every new music you upload drops on thousands of listeners’ feed. </p>
<p className='aboutdescription'>With thousands listening to your song and increasing number of downloads, you are on your way to becoming the next big artist. </p>
<p className='aboutdescription'>Artists on The New Trybe are segmented into stages based on their activities on the platform. An artist that has acquired 10,000 streams is classified as a starter artist, 50,000 streams a starter artist</p>

     
      <h4 className='aboutwhatweoffer'>What we offer</h4>
      <p className='aboutdescription'>
          a. Wider audience: Through our "stream to earn" method. Artist pay users N8 per stream on our platform and each listener is required to stream the song completely, follow the artist on Instagram and also rate the song else the listener doesn’t earn on the stream.
<br/>N.B This is only available to an active artist.
      </p>

      <p className='aboutdescription'>
          b. Events Booking: Through our "stream to earn" method. Artist pay users N8 per stream on our platform and each listener is required to stream the song completely, follow the artist on Instagram and also rate the song else the listener doesn’t earn on the stream.
          <br/>N.B This is only available to an active artist.
      </p>

      <p className='aboutdescription'>
          c. Collaborations:  Through our "stream to earn" method. Artist pay users N8 per stream on our platform and each listener is required to stream the song completely, follow the artist on Instagram and also rate the song else the listener doesn’t earn on the stream.
          <br/>N.B This is only available to an active artist.
      </p>

      <p className='aboutdescription'>
          d. Music distribution at a discounted rate:  Through our "stream to earn" method. Artist pay users N8 per stream on our platform and each listener is required to stream the song completely, follow the artist on Instagram and also rate the song else the listener doesn’t earn on the stream.
          <br/>N.B This is only available to an active artist.
      </p>


             
            </div>

       

            <div className="col-md-12 ">
            <h2 className='abouttitle3'>For listeners.</h2>
            <p className='aboutdescription'>
            So you already love music, why not make some money listening to new songs?  There are new artists out there with the right music, waiting for you to listen to them. The new trybe connects you to these artists and you get rewarded for every new song you stream.  Come join an amazing community of music lovers like you. Let’s go to The New Trybe.
            
              </p>   
            <p className='aboutdescription'>Our reward system
             created the listeners platform so that listeners discovering new artists can enjoy
              cash rewards for every new song they stream.  </p>   
              <h4 className='aboutwhatweoffer'>What we offer</h4>
              <p className='aboutdescription'>
          a. Earn up to N50,000 per month from listening to music 
      </p>
      <p className='aboutdescription'>
          b. We are a Trybe, meet music lovers like you and discover the next big artist 
      </p>

      <p className='aboutdescription'>
          c. Enjoy bi – annual giveaways and promos – stand a chance to win mega prizes yearly
      </p>

      <p className='aboutdescription'>
          d. Enjoy music, but also, earn money.
      </p>
     
            </div>
    </Row>
 */}


    


    </Container>
    <Footer/>
   </div>
   </div>
    )
}


export {AboutPage as default}