import React, { useEffect, useState, useContext, FloatingLabel, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Form } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"


// $('#webappsidebarCollapse').on('click', function () {
//   console.log("kk")
//     $('#sidebar').toggleClass('active');
// });

const BankPage = () => {
  const { users, dispatch } = useContext(PostsContext)
  const [useremail] = useState(localStorage.getItem("useremail"));


  console.log(users[0])

  useEffect(() => {
    document.title = "Bank Settings | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      if (response.user_role == "Listener") {
        $(".forartistonly").css({ 'display': 'none' });
      }
      else {
        $(".forartistonly").css({ 'display': 'block' });

      }



      document.getElementById('bank').value = response.bankname
      document.getElementById('accountnumber').value = response.accountnumber
      document.getElementById('accountname').value = response.firstname + " " + response.lastname



      if (document.getElementById("accountnumber").value !== "") {
        $("#accountnumber").prop("disabled", true);
      }

      if (document.getElementById("bank").value !== "") {
        $("#bank").prop("disabled", true);
      }

      // if (document.getElementById("facebook").value !== ""){
      //     $("#facebook").prop("disabled", true);
      //     }

      //     if (document.getElementById("instagram").value !== ""){
      //         $("#instagram").prop("disabled", true);
      //         }

      //         if (document.getElementById("twitter").value !== ""){
      //             $("#twitter").prop("disabled", true);
      //             }

      //             if (document.getElementById("stagename").value !== ""){
      //                 $("#stagename").prop("disabled", true);
      //                 }


    })

  })

  const updateUserInfo = () => {

    var bank = document.getElementById("bank").value
    var accountnumber = document.getElementById("accountnumber").value
    var accountname = document.getElementById("accountname").value



    var settingsupdate = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "PATCH",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "data": {
        "bankname": bank,
        "accountnumber": accountnumber,
        // "accountname":accountname,

        "picture": localStorage.getItem("picture"),
        "phone": localStorage.getItem("loggedinuserphone"),
        "earnings": localStorage.getItem("earnings"),
        "instagram": localStorage.getItem("userinstagram"),
        "twitter": localStorage.getItem("usertwitter"),
        "about": localStorage.getItem("about"),
        "phone": localStorage.getItem("loggedinuserphone"),
        "instagramid": localStorage.getItem("instagramid"),
        // "youtube": youtube ,
        "about": localStorage.getItem("about"),
        "stagename": localStorage.getItem("stagename"),
        "nin": localStorage.getItem("nin"),
        "onboarded": true,

        //   "picture": picture,
        //   "bankname": bankname,
        //   "accountnumber": accountnumber ,
        //   "phone":phone,
        //   "earnings":earnings,
        //   "facebook": facebook, 
        //   "instagram": instagram,
        //   "twitter": twitter,
        // "youtube": youtube ,
        //   "about": about,
        //   "stagename": stagename,
        //   "onboarded": true,
      },
      error: function (xhr, status, error) {
        console.log(xhr)


      },

      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingsupdate).done(function (response) {
      console.log(response)
      window.location.replace("/bank");

    })
  }



  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Personal Information</h4>
            <Container>

              <Row>
                <h5 className='pismh'>Account Details</h5>

                <div className='col-md-6'>
                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Select size="lg" id="bank" className="form-control signupusertype usertypeformcontrol">
                      <option value="access">- Select -</option>
                      <option value="access">Access Bank</option>
                      <option value="citibank">Citibank</option>
                      <option value="diamond">Diamond Bank</option>
                      <option value="ecobank">Ecobank</option>
                      <option value="fidelity">Fidelity Bank</option>
                      <option value="firstbank">First Bank</option>
                      <option value="fcmb">First City Monument Bank (FCMB)</option>
                      <option value="gtb">Guaranty Trust Bank (GTB)</option>
                      <option value="heritage">Heritage Bank</option>
                      <option value="keystone">Keystone Bank</option>
                      <option value="polaris">Polaris Bank</option>
                      <option value="providus">Providus Bank</option>
                      <option value="stanbic">Stanbic IBTC Bank</option>
                      <option value="standard">Standard Chartered Bank</option>
                      <option value="sterling">Sterling Bank</option>
                      <option value="suntrust">Suntrust Bank</option>
                      <option value="union">Union Bank</option>
                      <option value="uba">United Bank for Africa (UBA)</option>
                      <option value="unity">Unity Bank</option>
                      <option value="wema">Wema Bank</option>
                      <option value="zenith">Zenith Bank</option>
                    </Form.Select>
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" id="accountnumber" placeholder="Account Number" size="lg" />
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="accountname" size="lg" disabled />
                  </Form.Group>





                  <button onClick={updateUserInfo} className='btn-block pibutton'>Save</button>



                  {/* <FloatingLabel controlId="floatingTextarea" label="Comments" className="mb-3">
    <Form.Control as="textarea" placeholder="Leave a comment here" />
  </FloatingLabel>
  <FloatingLabel controlId="floatingTextarea2" label="Comments">
    <Form.Control
      as="textarea"
      placeholder="Leave a comment here"
      style={{ height: '100px' }}
    />
  </FloatingLabel> */}

                </div>



              </Row>




              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </Container>

          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { BankPage as default }