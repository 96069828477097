import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import Email from '@sendgrid/mail';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const AllStreamsPage = () => {
  // TABS
  class Tabs extends React.Component {
    state = {
      activeTab: this.props.children[0].props.label
    }
    changeTab = (tab) => {

      this.setState({ activeTab: tab });
    };
    render() {

      let content;
      let buttons = [];
      return (
        <div>
          {React.Children.map(this.props.children, child => {
            buttons.push(child.props.label)
            if (child.props.label === this.state.activeTab) content = child.props.children
          })}

          <TabButtons activeTab={this.state.activeTab} buttons={buttons} changeTab={this.changeTab} />
          <div className="tab-content">{content}</div>

        </div>
      );
    }
  }

  const TabButtons = ({ buttons, changeTab, activeTab }) => {

    return (
      <div className="tab-buttons">
        {buttons.map(button => {
          return <button className={button === activeTab ? 'tabactive' : ''} onClick={() => changeTab(button)}>{button}</button>
        })}
      </div>

    )
  }

  const Tab = props => {
    return (
      <React.Fragment>
        {props.children}
      </React.Fragment>
    )
  }

  // TABS
  // useEffect(() =>{
  const [Notify, setNotify] = useState("")
  const [RequestedAmount, setRequestedAmount] = useState("")
  const [CurrentAmount, setCurrentAmount] = useState("")
  const [show, setShow] = useState(false);
  console.log(CurrentAmount)
  const requestPayout = () => {

    if (CurrentAmount < 10000) {
      setNotify("Minimum withdrawable amount is 10,000")
    }
    else {
      setShow(true)
    }
  }
  // },[Notify])

  const requestPayout2 = () => {

    if (RequestedAmount < 10000) {
      setNotify("Minimum withdrawable amount is 10,000")
    }
    else if (RequestedAmount > CurrentAmount) {
      setNotify("You do not have up to the requested amount")
    }
    else {
      // setShow(true)

      Email.send({
        Host: "smtp.sendgrid.net",
        Username: "apikey",
        Password: "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",
        Port: "25",
        To: 'thenewtrybe@gmail.com',
        From: "aayo@codethatsprints.com",
        Subject: "New Payout Request",
        Body: " A new payout request."
      })

      var settings = {
        beforeSend: function () {
          $('.loading').css("visibility", "visible");
        },

        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/payout",
        "method": "POST",
        "timeout": 0,
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        "data": {

          "user_id": localStorage.getItem("userid"),
          "earnings": localStorage.getItem("earnings"),
          "email": localStorage.getItem("useremail"),
          "phone": localStorage.getItem("loggedinuserphone"),

          "firstname": localStorage.getItem("userfirstname"),
          "lastname": localStorage.getItem("userlastname"),

          "bankname": localStorage.getItem("bank"),
          "accountnumber": localStorage.getItem("accountnumber"),

          "amountrequested": RequestedAmount
        },

        complete: function () {
          $('.loading').css("visibility", "hidden");

        }
      }



      $.ajax(settings).done(function (response) {
        console.log(response)


        //  document.getElementById('withdrawnote').innerHTML = "Awaiting Confirmation"
        //  setTimeout(function(){ 
        //     window.location.replace("/home");
        //   }, 2000);
        //  window.location.replace("/s");

      })
      $('.hidediv').css("visibility", "hidden");
      $('.popupdiv').css("display", "block");

    }
  }

  useEffect(() => {
    document.title = "All Streams | Listener | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var checkpayout = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": `https://tnt-backend-7b6098ccaafc.herokuapp.com/api/payout/user/${localStorage.getItem("userid")}`,
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }
    $.ajax(checkpayout).done(function (response) {

      $.each(response, function (i, item) {
        console.log(response[i])
        if (response[i].status === "Pending") {
          $('.requestpayoutbutton').css("visibility", "hidden");
          setNotify("You have a pending payout request.")
        }


        $('<tr onclick="myFunction2(this, r)">').html(
          "<td class='centerthis firstnametd'>" + + "</td><td class='centerthis firstnametd'>"

          + "<td class='centerthis firstnametd'>" + response[i].createdAt + "</td>"
          + "<td class='centerthis firstnametd'>" + response[i].amountrequested + "</td>"
          + "<td class='centerthis firstnametd'>" + response[i].status + "</td>"


        ).appendTo('#records_tablepayout');
      })


    })




    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)

      // document.getElementById("tnos").innerHTML= response.earnings.toLocaleString()
      var ca = response.earnings
      setCurrentAmount(ca)
      let checkthis
      var totalno = []
      $.each(response.streamedsongs, function (i, item) {

        if (response.streamedsongs[i] !== null) {
          console.log(response.streamedsongs[i])

          totalno.push(response.streamedsongs[i])
          console.log(totalno)
          //    document.getElementById("tnost").innerHTML= totalno.length 

        }
      })
      // console.log(response.streamedsongs)
      var ssongs = response.streamedsongs
      // console.log(ssongs)
      // console.log(ssongs)

      $.each(ssongs, function (i, item) {

        checkthis = ssongs[i]
        console.log(checkthis)
        var settingstwo = {
          beforeSend: function () {
            $('.ajax-loader').css("visibility", "visible");
          },
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + checkthis,
          "method": "GET",
          "timeout": 0,
          "headers": { "Authorization": "Bearer " + usertoken },
          complete: function () {
            $('.ajax-loader').css("visibility", "hidden");
          }
        }

        $.ajax(settingstwo).done(function (response) {
          console.log(response)
          // $.each(response, function (i, item) {

          if (response.feature == "") {
            var feature = "-"
          }
          else {
            var feature = response.feature
          }
          $('<tr onclick="myFunction2(this, r)">').html(

            //  "<td>"+ "<i class='fas fa-play'></i>"+ "</td>"
            // "<td>"+ response[i].id+ "</td>"

            //  "<td class='centerthis'> <img width='20px' height='20px' src='" + response[i].song_art + "'>" + "</td>"
            "<td class='centerthis firstnametd'>" + "" + "</td>"
            + "<td class='centerthis firstnametd'>" + response.name + "</td>"

            //  "<td class='centerthis'><button class='playbutton'></button>" + "</td>"

            + "<td class='centerthis firstnametd'>" + response.artist_name + "</td>"
            // + "<td>"+ '<p class="centerthis firstnametd">' +  '<a target="_blank" style="color:#fff" href="'+ response.songlink +'"><i class="fas fa-download"></i></a>'  + "</p></td>"

          ).appendTo('#records_table');
          // $('.firstnametd').click(false);


          // });

        }

        )
      })



    })

  }, [])






  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Dashboard - Streamed Song</h4>



            <Container >
              <Row className="settingssecondcontainer2">

                <div className=''>
                  <div className=''>
                    <div class="row">




                      <div class="col-md-12">
                        <div class="row mobilerow">
                          <div className="">

                            <table class="table  css-serial">
                              <thead class="thead">
                                <tr class="ippisschedulehead">
                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">S/N</th>

                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Song</th>

                                  <th style={{ textAlign: "center" }} class="ippiscol0" scope="col">Artist</th>
                                  {/* <th style={{textAlign:"center" }} class="ippiscol0" scope="col">Download</th> */}




                                </tr>
                              </thead>

                              <tbody id="records_table">
                              </tbody>
                            </table>
                          </div>


                        </div>

                      </div>
                    </div>
                  </div>
                </div>





              </Row>
            </Container>


          </div>
        </div>

      </div>

      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Request<span id="artist2"></span> Payout</h5>




          <div className="hidediv">
            <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" style={{ marginTop: "40px" }}>
              <Form.Control type="email" id="amount" onChange={(e) => setRequestedAmount(e.target.value)} placeholder="Input an amount" size="lg" />
            </Form.Group>
            <p className="requestnotify2">{Notify}</p>
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
            {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}

            <p style={{ textAlign: "center" }}> <button onClick={requestPayout2} type="button" class="btn btn-lg" className='fundaccountbutton2' >Request Payout</button></p>
          </div>
          <div className="popupdiv"  >
            <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} loop autoplay></lottie-player>
            <h5 style={{ textAlign: "center", color: "#fff" }}>Payout request successful </h5>
            <h5 style={{ textAlign: "center", color: "#fff" }}>  Your account will be funded shortly and your balance updated.</h5>

            <p style={{ textAlign: "center" }}><a href="/earnings"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Continue</button></a></p>

          </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>



      {/* <div class="loading">
      <div class="loading-text">
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">H</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">N</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words">W</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">R</span>
        <span class="loading-text-words">Y</span>
        <span class="loading-text-words">B</span>
        <span class="loading-text-words">E</span>
    
      </div>
    </div> */}
    </div>


  )


}


export { AllStreamsPage as default }