import React, { useEffect } from 'react';
import $ from 'jquery';
import { Container, NavDropdown, Nav, NavLink, Navbar, Form, FormControl, Button, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import "../stylesheets/website.css"
import "../js/main.js"

const NewNavbar = () => {
  useEffect(() => {
    var usertoken = localStorage.getItem("access_token")
    var settings = {
      //       beforeSend: function(){
      // $('.loading').css("visibility", "visible");
      // },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      //               complete: function(){
      // $('.loading').css("visibility", "hidden");
      // }
    }

    $.ajax(settings).done(function (response) {
      // console.log(response)

      document.getElementById("defaultprofiletype").value = response.user_role
      // console.log(document.getElementById("defaultprofiletype").value)

      if (response.picture === "") {
        document.getElementById("profilepicture").src = "/images/usericon.png"
      }
      else {
        document.getElementById("profilepicture").src = response.picture
      }

      if (response.user_role === "Artist") {
        $(".forartistonly").css({ 'display': 'block' });
        $(".forlisteneronly").css({ 'display': 'none' });

      }




      if (response.user_role === "Listener") {
        var totalno = []
        var totalno2 = []
        document.getElementById("profilename").innerHTML = response.firstname + " " + response.lastname
        document.getElementById("profilemoney").innerHTML = "Earnings: &#x20A6;" + (response.streamedsongs.length - 1) * 15
        $.each(response.streamedsongs, function (i, item) {

          if (response.streamedsongs[i] !== null) {

            totalno.push(response.streamedsongs[i])
            var totalnumber = (totalno).length
            if (totalnumber <= 500) {
              document.getElementById("profiletype").innerHTML = "Beginner " + response.user_role
            }
            else if (totalnumber > 500 && totalnumber <= 2500) {
              document.getElementById("profiletype").innerHTML = "Starter " + response.user_role
            }

            else if (totalnumber > 2500 && totalnumber <= 5000) {
              document.getElementById("profiletype").innerHTML = "Bronze " + response.user_role
            }

            else if (totalnumber > 5000 && totalnumber <= 10000) {
              document.getElementById("profiletype").innerHTML = "Intermediate " + response.user_role
            }

            else if (totalnumber > 10000 && totalnumber <= 20000) {
              document.getElementById("profiletype").innerHTML = "Gold " + response.user_role
            }

            else if (totalnumber > 20000 && totalnumber <= 50000) {
              document.getElementById("profiletype").innerHTML = "Advannced " + response.user_role
            }

            else if (totalnumber > 50000) {
              document.getElementById("profiletype").innerHTML = "Platinum " + response.user_role
            }

          }
        })
      }

      else if (response.user_role === "Artist") {
        document.getElementById("profilename").innerHTML = response.stagename
        document.getElementById("profilemoney").innerHTML = "Balance: &#x20A6;" + response.balance.toLocaleString()





        var totalnumber = response.totalnoofstreams
        if (totalnumber <= 500) {
          document.getElementById("profiletype").innerHTML = "Beginner " + response.user_role
        }
        else if (totalnumber > 500 && totalnumber <= 2500) {
          document.getElementById("profiletype").innerHTML = "Starter " + response.user_role
        }

        else if (totalnumber > 2500 && totalnumber <= 5000) {
          document.getElementById("profiletype").innerHTML = "Bronze " + response.user_role
        }

        else if (totalnumber > 5000 && totalnumber <= 10000) {
          document.getElementById("profiletype").innerHTML = "Intermediate " + response.user_role
        }

        else if (totalnumber > 10000 && totalnumber <= 20000) {
          document.getElementById("profiletype").innerHTML = "Gold " + response.user_role
        }

        else if (totalnumber > 20000 && totalnumber <= 50000) {
          document.getElementById("profiletype").innerHTML = "Advannced " + response.user_role
        }

        else if (totalnumber > 50000) {
          document.getElementById("profiletype").innerHTML = "Platinum " + response.user_role
        }




        // document.getElementById("profiletype").innerHTML =  response.user_role
      }


      if (response.user_role === "Listener" && response.onboarded === "") {
        // $("#myModal").modal('show');
        //   document.getElementById("usernotify1").innerHTML = response.firstname + " !"
        // document.getElementById("usernotify2").innerHTML = response.firstname
      }

      else if (response.user_role === "Artist" && response.onboarded === "") {
        // $("#myArtistModal").modal('show');
        // document.getElementById("usernotify3").innerHTML = response.firstname + " !"
      }



      // if (document.getElementsByClassName("profilename").innerHTML !== ""){
      //   document.getElementsByClassName("profilename").innerHTML == "Edit Your Profile"
      // }
      // else{
      //   document.getElementsByClassName("profilename").innerHTML = response.firstname + " " + response.lastname

      // }

    })



  })

  return (
    <div id="webappsidebar">
      <div class="sidebar-header">
        <Nav.Link as={Link} to="/explore" eventKey="5" activeClassName="is-active" className="" >  <img className='headerlogo' src="/images/tntlogowhite.png" /></Nav.Link>
      </div>

      <ul class="list-unstyled components">
        {/* <p>Basic Listener</p>
       <p>Balance: N0</p> */}
        <li className="webappgradelink">
          <p id="profiletype">Level:  Basic Listener</p>
          <input type="hidden" id="defaultprofiletype" />
        </li>

        <li className="webappearningslink">
          <p id="profilemoney">Balance: N0</p>
        </li>

        {/* webappactivelink */}
        <li className="webappnavlink ">
          <a href="/explore"><i class="fas fa-headphones webapplinkicon "></i>  Explore</a>
        </li>


        {/* <li className="webappnavlink">
           <a href="/library"><i class="fas fa-compact-disc webapplinkicon"></i> Library</a>
       </li> */}


        <li className="webappnavlink">
          <a href="/newmusic"><i class="fas fa-play webapplinkicon"></i> New Music</a>
        </li>

        <li className="webappnavlink">
          <a href="/top100"><i class="fas fa-sort-amount-up webapplinkicon"></i> Top 100</a>
        </li>

        {/* <li className="webappnavlink forlisteneronly">
           <a href="/donate"><i class="fas fa-solid fa-hand-holding-medical webapplinkicon"></i> Donate</a>
       </li> */}
        <li className="webappnavlink forartistonly">
          <a href="/uploadsong"><i class="fas fa-solid fa-upload webapplinkicon"></i> Upload a song</a>
        </li>

        <li className="webappnavlink forartistonly">
          <a href="/dashboard"><i class="fas fa-columns webapplinkicon"></i> Dashboard</a>
        </li>

        <li className="webappnavlink forlisteneronly">
          <a href="/earnings"><i class="fas fa-wallet webapplinkicon"></i> Earnings</a>
        </li>
      </ul>

    </div>
  )
};


export default NewNavbar;