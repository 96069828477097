import React, { useEffect, useReducer, useState} from 'react';
import {Container, Row, Accordion,Button,} from 'react-bootstrap'

import "../stylesheets/website.css"
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
import Header from './Header';


const GeneralFaqNote = () => {
  useEffect(() =>{
    document.title = "Frequently Asked Questions | The New Trybe"
},[])
    return (
        <>
  <h5 className="faqsubheading">General</h5>
<Accordion defaultActiveKey="0" flush>
  <Accordion.Item eventKey="0">

    <Accordion.Header>What is The New Trybe about ?</Accordion.Header>
    <Accordion.Body>
    The New Trybe was created for Emerging artistes to get their music to their audience faster. You don’t need to wait to be discovered, you can now upload a song and reach thousands of people in minutes even if you just started your career today.

Our reward system created the listeners platform so that listeners discovering new artistes can enjoy cash rewards for every new song they stream.
   
    </Accordion.Body>
  </Accordion.Item>

  <Accordion.Item eventKey="1" className="faqaddspacing">
    <Accordion.Header>Contact</Accordion.Header>
    <Accordion.Body>
     Text/Call: +2348023007357
    </Accordion.Body>
  </Accordion.Item>
{/* 
  <Accordion.Item eventKey="2" className="faqaddspacing">
    <Accordion.Header>Our Vision</Accordion.Header>
    <Accordion.Body>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
      cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
      est laborum.
    </Accordion.Body>
  </Accordion.Item> */}
</Accordion>


            
            </>
    )
}

const ArtistFaqNote = () => {
    return (
        <>
 <h5 className="faqsubheading">Artistes</h5>
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>1. Is there a minimum and maximum amount i can fund?</Accordion.Header>
            <Accordion.Body>
            The minimum amount you can fund is ₦3,000, There is no maximum amount
            </Accordion.Body>
          </Accordion.Item>
        
          <Accordion.Item eventKey="1" className="faqaddspacing">
            <Accordion.Header>2. What are the acceptable payment methods?</Accordion.Header>
            <Accordion.Body>
            The only accepted payment method is with a debit card. Payments are made securely with Paystack.

            </Accordion.Body>
          </Accordion.Item>
        
          <Accordion.Item eventKey="2" className="faqaddspacing">
            <Accordion.Header>3. How do i see the number of streams my song is getting?</Accordion.Header>
            <Accordion.Body>
            You can view your song statics by signing into your artiste`s account,Click on Dashboard and view streams.

            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3"  className="faqaddspacing">
            <Accordion.Header>4. How does account funding work?</Accordion.Header>
            <Accordion.Body>
            You fund your account with a minimum of N1,000 from N20 is deducted each time a listener streams your song for the first time.

            </Accordion.Body>
          </Accordion.Item>
        
          {/* <Accordion.Item eventKey="4" className="faqaddspacing">
            <Accordion.Header>5. How do I see the number of streams my song is getting?</Accordion.Header>
            <Accordion.Body>
            Simply click on Artist Dashboard in the sidebar menu, then view streams.


            </Accordion.Body>
          </Accordion.Item> */}
        
          <Accordion.Item eventKey="4" className="faqaddspacing">
            <Accordion.Header>5 How many songs can I upload?</Accordion.Header>
            <Accordion.Body>
            You can upload as many songs as you like. The more, the better.


            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5" className="faqaddspacing">
            <Accordion.Header>6. Is there a maximum upload size?</Accordion.Header>
            <Accordion.Body>
            The maximum upload size is 10MB



            </Accordion.Body>
          </Accordion.Item>




        </Accordion>
        
        
                    
                    </>
    )
}

const ListenerFaqNote = () => {
    return (
        <>
 <h5 className="faqsubheading">Listeners</h5>
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>1. How much do I need to earn before I can withdraw?</Accordion.Header>
            <Accordion.Body>
            The minimum amount you can withdraw is ₦3,000.
            </Accordion.Body>
          </Accordion.Item>
        
          <Accordion.Item eventKey="1" className="faqaddspacing">
            <Accordion.Header>2. How do I withdraw my funds?</Accordion.Header>
            <Accordion.Body>
            You can request for your earning by signing into your listeners account, Click on Earnings then request for payout.

            </Accordion.Body>
          </Accordion.Item>
        
          <Accordion.Item eventKey="2" className="faqaddspacing">
            <Accordion.Header>3. I already requested for payout, How long will i take to reflect in my bank account?</Accordion.Header>
            <Accordion.Body>
            All payouts are processed within 30 minutes

            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3"  className="faqaddspacing">
            <Accordion.Header>4. What happens to my money if I don’t withdraw it?</Accordion.Header>
            <Accordion.Body>
            Nothing happens, it is yours when you’re ready to cash out.

            </Accordion.Body>
          </Accordion.Item>
        
          <Accordion.Item eventKey="4" className="faqaddspacing">
            <Accordion.Header>5. Do I still earn if I listen to one song more than once?</Accordion.Header>
            <Accordion.Body>
            No, you only earn for every new song you stream.



            </Accordion.Body>
          </Accordion.Item>
        
          <Accordion.Item eventKey="5" className="faqaddspacing">
            <Accordion.Header>6. Does downloads count for my earnings ?</Accordion.Header>
            <Accordion.Body>
            No, it does not.



            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6" className="faqaddspacing">
            <Accordion.Header>7. Is there a maximum amount I can earn ?</Accordion.Header>
            <Accordion.Body>
            No there is not, you can earn as much as you want.



            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7" className="faqaddspacing">
            <Accordion.Header>8. Can i create multiple account?</Accordion.Header>
            <Accordion.Body>
            No you cannot. Identity veriification will be done before payout request to prevent multiple accounts.



            </Accordion.Body>
          </Accordion.Item>




        </Accordion>
        
        
                    
                    </>
    )
}

const PaymentFaqNote = () => {
    return (
        <>
 <h5 className="faqsubheading">Payment</h5>
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>1. How much do I need to earn before I can withdraw?</Accordion.Header>
            <Accordion.Body>
            The minimum amount you can withdraw is ₦3,000.
            </Accordion.Body>
          </Accordion.Item>
          </Accordion>
          </>
    )
}

const FaqPage = () => {

    const GeneralMenuStyle = {
        backgroundColor:"#14497D",
        paddingTop:15,
        paddingBottom:15,
        color:"#FFF"
       
      }

      const unGeneralMenuStyle = {
        backgroundColor:"#121212",
        paddingTop:15,
        paddingBottom:15,
        color:"#707070"
       
      }




      
    const [faqnote, setFaqNote] = useState( <GeneralFaqNote/>)     
    const [generalmenu, setGeneralMenu] = useState()     
    const [artistmenu, setArtistMenu] = useState()     
    const [listenermenu, setListenerMenu] = useState()     
    const [paymentmenu, setPaymentMenu] = useState()     
  


    const generalFaq = () => {
        setFaqNote(<GeneralFaqNote/>)
        setGeneralMenu(GeneralMenuStyle) 
        setArtistMenu() 
        setListenerMenu() 
        setPaymentMenu() 
    }

    const artistFaq = () => {
        setFaqNote(<ArtistFaqNote  />)
        setArtistMenu(GeneralMenuStyle) 
        setGeneralMenu(unGeneralMenuStyle) 
        setListenerMenu() 
        setPaymentMenu() 
      

    }

    const listenerFaq = () => {
        setFaqNote(<ListenerFaqNote />) 
        setListenerMenu(GeneralMenuStyle) 
        setGeneralMenu(unGeneralMenuStyle) 
        setPaymentMenu() 
        setArtistMenu() 
    }

    const paymentsFaq = () => {
        setFaqNote(<PaymentFaqNote />)  
        setPaymentMenu(GeneralMenuStyle) 
        setGeneralMenu(unGeneralMenuStyle) 
        setListenerMenu() 
        setArtistMenu() 
       
      }




    return (
        


<div id="faqpage">
<Header/>
<Container className='page faqlastpage'>

<Row className="faqfirstBox">
    <div className="col-md-12">
        <h2 className='faqtitle'>Frequently Asked Questions</h2>
     
        </div>

      
</Row>


<Row className="">
    <div className="col-md-3">
        </div>
        <div className="col-md-6">
        <p className='faqdescription'>
           Here are the Frequently Asked Questions
              </p>   
        </div>
        <div className="col-md-3">
        </div>

      
</Row>

<Row className="">
    <div className="col-md-3">
        <h6 style={{ cursor: 'pointer' }}  onClick={generalFaq} style={generalmenu} className="faqmenu1">General</h6>
        <h6 style={{ cursor: 'pointer' }}  onClick={artistFaq} style={artistmenu} className="faqmenu">Artiste</h6>
        <h6 style={{ cursor: 'pointer' }}  onClick={listenerFaq} style={listenermenu} className="faqmenu">Listener</h6>
        <h6  style={{ cursor: 'pointer' }} onClick={paymentsFaq} style={paymentmenu} className="faqmenu">Payments</h6>
        </div>
        <div className="col-md-9">
        <p className='description'>{faqnote}</p>   
        </div>


      
</Row>








</Container>
<Footer/>
</div>

   
    )
}


export {FaqPage as default}