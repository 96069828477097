import React, { useEffect, useReducer, useState} from 'react';
import {Container, Row,Button, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import "../stylesheets/website.css"
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';

const HomePage = () => {

    const [Amount, setAmount] = useState(500)    
    const [result, setResult] = useState("")   
    useEffect(() =>{
        document.title = "Home | The New Trybe"
    },[])

    useEffect(() =>{
      

        const audience = (Amount/20).toLocaleString()
        setResult(audience)
     
      },[Amount]) 

    return (
      
      <div>


          <Header/>


<div className="homepagefirstbox">
<Container className='page'>
    <Row >
        <div className="col-md-6">
            <h2 className='homepagetitle'>Where<span className="homepagefavourite"> Music</span> rewards  <span className="homepagefavourite">Everyone</span>.</h2>
            <p className='homepagedescription'>Welcome to the New Trybe!<br/> The Only Platform built for New Artists to shine  <br/>We’re about Music – celebrating new artistes – rewarding fans. </p>   
            <p className="footermenup">
          <a href="#artists"><Button  className="homepagectabutton1">For Artistes</Button></a>
          <a href="#listeners"> <Button  className="homepagectabutton2">For Listeners</Button></a>

            </p>
     
            </div>

            <div className="col-md-6">
            <img className="img-fluid afbimg" src="/images/aboutfirstboximg.svg"/>
            {/* <div className="homepageyt"> */}
                
            {/* <iframe className="hpyt" src="https://www.youtube.com/embed/om1L-jfifJ4?showinfo=0&controls=0" title="YouTube video player" rel="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            {/* <img className="hpi1 img-fluid" src="/images/homepageimage.png"/> */}
            {/* </div> */}
            </div>
    </Row>
    </Container>
    </div>



<div className="homepagesecondbox">
    <Container>

  
    <Row >
    <div id="artists" className="col-md-12">
        {/* <img className="afbimg" src="/images/aboutfirstboximg2.png"/> */}

        <h2 className='forartistehp unlimitedmobile'>For<span className="homepagefavourite"> Artistes</span> </h2>
            <p className='aboutdescription'>

            You’ve made it to the New Trybe where it’s all about taking your music to a whole NEW level - upload a song and watch it go viral even if you just started your career today. The game is fair and every new music you upload, drops on thousands of listeners’ feed


</p>

 
<div className="homepagesecondboxhp">
<Container className='page'>
    <Row >
        <div className="col-md-6">
        <img className="img-fluid  growaudienceimage" src="/images/audience.jpg"/>

 
     
            </div>

            <div className="col-md-6">
            <h2 className='homepagetitle5 unlimitedmobile'>Grow<span className="homepagefavourite"> Audience</span> </h2>
            <p className='homepagedescription'>
            We send your music directly to your audience and we are growing everyday               
                   </p>   

                   <h2 className='homepagetitle5'>Show <span className="homepagefavourite"> Bookings </span></h2>

                   <p className='homepagedescription'>

                   Get booked for shows and events 10x faster as you grow your fan base.
                    </p>   

                    <h2 className='homepagetitle5'>Collaborate with other <span className="homepagefavourite"> Artistes </span> </h2>

<p className='homepagedescription'>

Find and collaborate with other artistes, celebrate your music diversity and give your fans something to celebrate
 </p>   
            </div>
    </Row>
    </Container>
    </div>




    <div className="homepagefirstboxlearnmore">
<Container className='page'>


<div class="row" style={{marginTop:"0px"}}>
    <div class="col-md-6">
    <h2 className='homepagetitle51'>Instagram<span className="homepagefavourite"> Audience</span> </h2>

    <p className='homepagedescription'>
    We will build you a strong & large community of music lovers on Instagram – for every stream you get, you earn a new follower.
                               </p>   
                               <h2 className='homepagetitle5'>Talk to industry experts<span className="homepagefavourite"> (Coming Soon)</span> </h2>

                               <p className='homepagedescription'>
                               Talk to Industry experts (managers, producers, distributers, promoters) in our monthly Q/A sessions and learn what it takes to grow even bigger.          
                   </p>   

                   <h2 className='homepagetitle5'>Share your <span className="homepagefavourite"> Music</span> </h2>

<p className='homepagedescription'>
But most importantly, we are giving you a platform to be your awesome self, share your music – whenever, however, connect and Shine on your own terms.       
</p>   

        </div>

        <div class="col-md-6">
        <img className="img-fluid learnmorestreamimage2 desktopdisplay" src="/images/igaudience.jpg"/>

     
        </div>
    </div>

    <div class="row" style={{ paddingTop:"0px"}} >
    <div class="col-md-6">
  
    <img className="img-fluid  dashboardlastimage" src="/images/feedback.png"/>

  

        </div>

        <div class="col-md-6 musicratingbox">
        <h2 className='homepagetitle52'>Comprehensive <span className="homepagefavourite"> Dashboard</span> </h2>

<p className='homepagedescription'>
Get access to a comprehensive dashboard that shows you real time data of your song’s performance, who your listeners are and feedback from your listeners</p>   



<h2 className='homepagetitle5'><span className="homepagefavourite">Reward</span> Fans & They <span className="homepagefavourite"> Reward </span> you </h2>

<p className='homepagedescription'>
We save you money because for every Naira you reward your listeners with, you can see it convert to an actual stream of your music (Say yes! to spend you can measure
</p>


{/* <h2 className='homepagetitle5'>Free <span className="homepagefavourite">Music Distribution</span> </h2>

<p className='homepagedescription'>
For every &#x20A6;30,000 you spend on The New Trybe, alongside the new streams you accumulate, the new followers and ratings on your song, you also get to distribute one of your songs to apple Music and spotify for FREE
 </p>   */}
        </div>

    </div>


    {/* <h2 className='homepagetitle5 calculatormaintitle'><span className="homepagefavourite">Reward  </span> Calculator </h2> */}
    <h3 className='homepagetitle2'>Reward<span className="homepagefavourite"> Calculator</span> </h3>


<div class="row">
    <div class="col-md-6">

 <p className='calculatorhead' style={{paddingBottom:"0px"}} >For Every</p>
 <input className="form-control loginemailinput calculatorinput" value={Amount} type="number"id="amount" onChange={(e) => setAmount(e.target.value)} width="100px" placeholder="Input an amount" size="lg" />
 <p style={{paddingTop:"10px"}} className='calculatorhead'>You Fund</p>

        </div>

    <div class="col-md-6">
  
    <p className='calculatorhead'>You get:</p>
    <p className='calculatorbody'>1. Streams from <span className="homepagefavourite">{result} New listeners</span>  on the platform.</p>
    <p className='calculatorbody'>2. <span className="homepagefavourite">{result} Real followers</span>  on Instagram.</p>
    <p className='calculatorbody'>3. <span className="homepagefavourite">{result} Ratings</span>  on your song.</p>
    {/* <p className='calculatorbody'>4. For every &#x20A6;30,000 you fund, We distribute 1 of your songs to <span className="homepagefavourite">Apple Music, Spotify for FREE.</span></p> */}


        </div>
    </div>

 
    </Container>

  
    </div>




{/* 
<p className='aboutdescription' style={{textAlign:"center",paddingLeft:""}}>
You have all it takes, you have the music, you have the audience, go take the FAME! Sign up <span className="homepagefavourite"><a href="/signup">HERE</a></span>
</p> */}
              {/* <Nav.Link  as={Link} to="/signup" >  <Button  className="aboutctabutton" >Learn More</Button></Nav.Link> */}
             
            </div>

            {/* <h3 className='homepagetitle2'>Signup Step By Step Guide for <span className="homepagefavourite">You.</span> </h3> */}
            <h2 style={{paddingBottom:"20px"}} className='forartistehp unlimitedmobile'>Signup Step By Step Guide for <span className="homepagefavourite"> You.</span> </h2>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">1</span>  <span className="hppb">Create an account <span className="homepagefavourite"><a href="/signup">HERE</a></span> </span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">2</span>  <span className="hppb">Complete your profile</span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">3</span>  <span className="hppb">Fund your account</span></p>
            </div>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">4</span>  <span className="hppb">Upload your song</span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">5</span>  <span className="hppb">Watch your numbers go up.</span></p>

            </div>
        
        
  

     
<hr class="hphr" />
            <div className="col-md-12 " id="listeners">
            <h2 className='forartistehp'>For<span className="homepagefavourite"> Listeners</span> </h2>
            <div class="row" >
    <div class="col-md-6">
  
    <img className="img-fluid  discoverimage" src="/images/reward.jpg"/>

  

        </div>

        <div class="col-md-6">
        <h2 className='homepagetitle5 hpdiscover'>Discover new <span className="homepagefavourite"> music</span> </h2>

<p className='homepagedescription'>
You discover new music and new artistes everyday already, why not get paid to do it? So we decided to pay you up to N50,000 every month to enjoy good music and discover new artistes<br/> It’s very simple to start.

</p>   





        </div>

    </div>


            <div class="row">
    <div class="col-md-2"></div>
    <div class="col-md-8">
    <p className='aboutdescription'>
            
              </p> 
    </div>
    <div class="col-md-2"></div>
</div>   
            
              {/* <h4 className='aboutwhatweoffer'>What we offer</h4>
              <p className='aboutdescription'>
              -	You sign-up here
      </p>
      <p className='aboutdescription'>
      -	You start streaming songs (we even have a Top 100 curated special waiting for you)
      </p>

      <p className='aboutdescription'>
      -	Earn N15 for every song you stream
      </p>

      <p className='aboutdescription'>
      -	Withdraw your money from your wallet anytime, after you earn N1000
      </p> */}
              {/* <Nav.Link  as={Link} to="/signup" >  <Button  className="aboutctabutton" >Create an account</Button></Nav.Link> */}
     
            </div>
          
            <h3 className='homepagetitle2'>Step By Step Guide for <span className="homepagefavourite">You.</span> </h3>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">1</span>   <span className="hppb">Sign up as a listener</span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">2</span>  <span className="hppb">Start streaming songs </span></p>


            </div>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">3</span> <span className="hppb"> Earn N15 for every song.</span></p>

            <p className="homepagehtsp"> <span className="homepagepagination">4</span>  <span className="hppb">Withdraw your money anytime.</span></p>

            </div>
    </Row>
    </Container>
    </div>



<div className="homepagethirdbox">
    <Container>
    <Row >
        <div className="col-md-6">
        <img className="appillimage" style={{height:"500px", width:"367px"}}src="/images/appillustration.png"/>
            </div>

            <div className="col-md-6 homepagedownloadbox">
            <h2 className='homepagedownloadtitle'>Come join the Trybe</h2>
            <p className='homepagedescription'>
            We’ve got a whole trybe here, Music lovers like you who are already cashing out and having the best time enjoying new music and discovering new artistes. 
            <br/>
            Because of you, a talented artiste out there gets to be discovered and celebrated. Let’s support more of them

             </p>   
        
            <Button  className="homepagedownloadbutton">Join Now</Button>
            {/* <Button  className="ctabutton playstorebutton" variant="outline-success">PlayStore</Button> */}
            </div>
    </Row>
    </Container>

    </div>


    {/* <div className="homepagefourthbox">
<Container>
    <Row className="">
        <div className="col-md-6">
            <h2 className='homepagelasttitle'>Listen and Get Paid!</h2>
            <h2 className='homepagelasttitle'>How Simple can it get!!!!</h2>
            </div>

            <div className="col-md-6">
            <Nav.Link  as={Link} to="/signup" >   <Button  className="homepagectabutton">Get Started</Button></Nav.Link>

            </div>
    </Row>
    </Container>
</div> */}


    <Footer/>
    </div>


    )
    
    
}


export {HomePage as default}