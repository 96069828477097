import React, { useEffect, useReducer} from 'react';
import {Container, Row, Nav, Button,} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import "../stylesheets/website.css"
const Footer = () => {
    



    return (
        
<div id="footerlastbox">
<Container >

    <Row className="aboutfooterfirstbox mobilefooter">
        {/* <div className="col-md-3">
        <Nav.Link  as={Link} to="/" > <p className="footerlogop"> <img className="footerlogo" src="/images/flogo.png" /></p></Nav.Link>
            </div> */}

            <div className="col-md-3">
                <h6 className="footermenuhead">QUICK LINKS</h6>
                <br/>
                <Nav.Link  as={Link} to="/about" >     <p  className="footermenubody">About</p></Nav.Link>
                <Nav.Link  as={Link} to="/faq" >     <p   className="footermenubody">FAQs</p></Nav.Link>
                {/* <Nav.Link  as={Link} to="/termsandconditions" >     <p   className="footermenubody">Terms & Conditions</p></Nav.Link> */}

     
     </div>

     <div className="col-md-3 footer2">
     <h6 className="footermenuhead">GET STARTED</h6>
     <br/>
     <Nav.Link  as={Link} to="/signup" > <p   className="footermenubody">Sign Up</p></Nav.Link>
     <Nav.Link  as={Link} to="/login" >    <p   className="footermenubody">Sign In</p></Nav.Link>
     
     </div>

     <div className="col-md-3 footer3">
     <h6 className="footermenuhead">FOLLOW US</h6>
<p className='footersocial2'>
<a style={{color: "#D90C5F"}} target="_blank" href="https://instagram.com/thenewtrybe"><i class="fab fa-instagram f1"></i></a>
<a style={{color: "#D90C5F"}} target="_blank" href="https://facebook.com/thenewtrybe"><i class="fab fa-facebook f2"></i></a>
<a style={{color: "#D90C5F"}} target="_blank" href="https://twitter.com/thenewtrybe"><i class="fab fa-twitter f3"></i></a>
</p>
     
     </div>

  
    </Row>




    </Container>
    </div>
    )
}


export {Footer as default}