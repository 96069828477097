import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const ViewStreamsPage = () => {
  const [show, setShow] = useState(false);
  const [notify, setNotify] = useState("")

  const handleClose = () => {
    setShow(false)

  }

  const showmodal = () => {
    console.log("ll")
    setShow(false)

  }

  function myFunction(x, myid = myid.reverse()) {
    console.log(myid[x.rowIndex])
  }



  useEffect(() => {
    document.title = "Manage Songs | Artist | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var r = []
    var total = []

    var settingstwo = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single",
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
    }


    $.ajax(settingstwo).done(function (response) {
      console.log(response)


      $.each(response, function (i, item) {
        console.log(response[i])
        //   console.log(typeof(response[i].user_id))

        var defaultDates = response[i].createdAt
        var d = new Date(defaultDates).toString();
        var actualdate = d.split(' ').splice(0, 4).join(' ')
        if (response[i].feature == "") {
          var feature = "-"
        }
        else {
          var feature = response[i].feature
        }
        r.push(response[i]._id)
        console.log(r)



        total.push(response[i].noofstreams)
        var sum = total.reduce(function (a, b) { return a + b; }, 0);
        console.log(sum)
        document.getElementById("tnost").innerHTML = sum
        // var totalno = total.length
        // console.log(totalno)
        // document.getElementById('noruid').innerHTML = total.length
        // document.getElementById("statementrecactamount").innerHTML = last.amount_transacted
        // document.getElementById("statementrecactid").innerHTML = last.id
        let status
        let approval
        if (response[i].admin_note === "Awaiting Review") {
          status = "Awaiting Review"
        }

        else if (response[i].admin_note === "Kindly upload a song art designed by a graphics designer.") {
          status = "Edit single art"
        }

        else if (response[i].admin_note === "Kindly upload a song art designed by a graphics designer, and replace your song with one recorded and mastered by a producer.") {
          status = "Edit single art & song"
        }

        else if (response[i].admin_note === "Kindly upload a song recorded and mastered by a producer.") {
          status = "Edit song"
        }

        if (response[i].admin_approved === false) {
          approval = "Pending Approval"
        }

        else if (response[i].admin_approved === true) {
          approval = "Approved"
        }

        $("<tr >").html(
          "<td> " + "" + "</td><td>"
          + response[i].name + "</td><td>"
          + actualdate + "</td><td>"
          + approval + "</td><td>"
          + status + "</td><td>"

          + "<button class='morebutton'> <a id='myInput' style='color:#fff' target='_blank' href='/stream?songid=" + response[i]._id + "'>" + "View song " + "</a></button></td><td>"

          + "<button class='morebutton'> <a style='color:#fff' target='_blank' href='/songstat?songid=" + response[i]._id + "'>" + "View statistics" + "</a></button></td><td>"

          // + <button onClick=" + ${showmodal} + "className='requestpayoutbutton' >Hide/Delete</button> 
          + "<button class='morebutton'> <a style='color:#fff' target='_blank' href='/edit?songid=" + response[i]._id + "'>" + "Edit song" + "</a></button></td><td>"

          + "<button class='morebutton'> <a style='color:#fff' target='_blank' href='/hideordelete?songid=" + response[i]._id + "'>" + "Delete song" + "</a></button></td>"




        ).appendTo('#records_table');

        var set = $('#records_table').children('tr').length;
        document.getElementById("tnos").innerHTML = set

        console.log(set)
      });




    })


  })






  return (

    <div className='d-flex'>


      <Modal id="myModal" class="modal fade modal-content"
        show={show} onHide={handleClose}
      >

        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {/* <p style={{textAlign:"center",color:"#FFF",paddingTop:"0px"}}>{notify2}</p> */}

          <div class="row">
            <div class="col-md-2"></div>

            <div class="col-md-8">
              <br />

              <p>Song: </p>
            </div>

            <div class="col-md-2"></div>


            <div class="row">
              <div class="col-md-2">

              </div>

              <div class="col-md-9">
                <p className="footermenup3">
                  <br />
                  <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify}</p>
                  <button type="button" class="btn btn-lg" className='onboardedbutton' >Hide</button>
                  <button type="button" class="btn btn-lg" className='onboardedbutton' >Delete</button>

                </p>
              </div>

              <div class="col-md-1">

              </div>
            </div>


          </div>


        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
       
        </Modal.Footer> */}
      </Modal>




      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">


            <Container>
              <h4 className="webappinnerheading">Dashboard - Manage Songs/Streams</h4>

              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />

              <Row>

                <div className='col-md-5 col-xs-9 dashboardbalance'>
                  <p className="dashboardpagetext">Total No of Songs</p>
                  <h1 className="dashboardpagefigures"><span id="tnos">0</span></h1>
                </div>
                <div className='col-md-1'>
                </div>
                <div className='col-md-5 col-xs-9 dashboardstreams'>
                  <p className="dashboardpagetext">Total No of Streams</p>
                  <h1 className="dashboardpagefigures"><span id="tnost">0</span></h1>
                </div>


              </Row>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />
              {/* <p className='dashboardhelp'><i class="fas fa-question-circle"></i> Help</p> */}
            </Container>

            <Container >
              <Row className="settingssecondcontainer">




                <div className='col-md-12'>
                  <p className="uploadsonghead">All Songs</p>
                  <table class="table  css-serial longtable">
                    <thead class="thead">
                      <tr class="ippisschedulehead">
                        <th class="ippiscol0" scope="col">S/N</th>
                        <th class="ippiscol0" scope="col">Title</th>
                        <th class="ippiscol0" scope="col">Upload Date</th>
                        <th class="ippiscol0" scope="col">Status</th>
                        <th class="ippiscol0" scope="col">Note from Admin</th>
                        {/* <th class="ippiscol0" scope="col">Note from Admin</th> */}


                        {/* <th class="ippiscol0" scope="col">Featuring</th> */}


                        <th class="ippiscol0" scope="col">View Song</th>

                        <th class="" scope="col">View Statistics</th>
                        <th class="" scope="col">Edit Song</th>
                        <th class="" scope="col">Delete Song</th>




                      </tr>
                    </thead>

                    <tbody id="records_table">
                    </tbody>
                  </table>




                </div>


              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { ViewStreamsPage as default }