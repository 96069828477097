import React, { useEffect, useState, useContext, FloatingLabel, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Form } from 'react-bootstrap'


// import postsReducer from '../reducers/posts';
// import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const PersonalInformationPage = () => {
  // const {users, dispatch} = useContext(PostsContext)
  const [useremail] = useState(localStorage.getItem("useremail"));


  // console.log(users[0])

  useEffect(() => {
    document.title = "Personal Information | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      if (response.user_role == "Listener") {
        $(".forartistonly").css({ 'display': 'none' });
        $(".pibutton").css({ 'display': 'none' });
      }
      else {
        $(".forartistonly").css({ 'display': 'block' });

      }



      document.getElementById('fullname').value = response.firstname + " " + response.lastname
      document.getElementById('phonenumber').value = response.phone
      document.getElementById('email').value = response.email
      document.getElementById('usertype').value = response.user_role
      // document.getElementById('facebook').value = response.facebook  
      document.getElementById('instagram').value = response.instagram
      // document.getElementById('twitter').value = response.twitter
      document.getElementById('stagename').value = response.stagename
      document.getElementById('about').value = response.about


      // if (document.getElementById("facebook").value !== ""){
      //     $("#facebook").prop("disabled", true);
      //     }

      if (document.getElementById("instagram").value !== "") {
        $("#instagram").prop("disabled", true);
      }

      // if (document.getElementById("twitter").value !== ""){
      //     $("#twitter").prop("disabled", true);
      //     }

      if (document.getElementById("stagename").value !== "") {
        $("#stagename").prop("disabled", true);
      }


    })

  })

  const updateUserInfo = () => {

    // var facebook =  document.getElementById("facebook").value
    // var instagram = document.getElementById("instagram").value
    // var twitter =  document.getElementById("twitter").value
    // var phone = document.getElementById("phonenumber").value
    var about = document.getElementById("about").value
    // var stagename = document.getElementById("stagename").value





    var settingsupdate = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "PATCH",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "data": {

        "picture": localStorage.getItem("picture"),
        "bankname": localStorage.getItem("bank"),
        "accountnumber": localStorage.getItem("accountnumber"),
        "phone": localStorage.getItem("loggedinuserphone"),
        "earnings": localStorage.getItem("earnings"),
        "instagram": localStorage.getItem("userinstagram"),
        // "twitter":  twitter,
        // "about":  localStorage.getItem("about"),
        "phone": localStorage.getItem("phone"),
        "instagramid": localStorage.getItem("instagramid"),
        // "youtube": youtube ,
        "about": about,
        "stagename": localStorage.getItem("stagename"),
        "onboarded": true,
      },

      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingsupdate).done(function (response) {
      console.log(response)
      window.location.replace("/personalinfo");

    })
  }



  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Personal Information</h4>
            <Container>

              <Row>
                <h5 className='pismh'>About</h5>

                <div className='col-md-6'>
                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" id="fullname" size="lg" disabled />
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" id="phonenumber" size="lg" disabled />
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="email" size="lg" disabled />
                  </Form.Group>


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="text" id="usertype" size="lg" disabled />
                  </Form.Group>

                  <Form.Group className="mb-3 loginemailinput forartistonly" controlId="formBasicEmail" >
                    <Form.Control type="text" id="stagename" placeholder="Stagename" size="lg" />
                  </Form.Group>

                  <Form.Group className="mb-3 loginemailinput forartistonly" controlId="formBasicEmail" >
                    <Form.Control as="textarea" id="about" placeholder="About Artist" size="lg" />
                  </Form.Group>



                  {/* <FloatingLabel controlId="floatingTextarea" label="Comments" className="mb-3">
    <Form.Control as="textarea" placeholder="Leave a comment here" />
  </FloatingLabel>
  <FloatingLabel controlId="floatingTextarea2" label="Comments">
    <Form.Control
      as="textarea"
      placeholder="Leave a comment here"
      style={{ height: '100px' }}
    />
  </FloatingLabel> */}

                </div>



              </Row>



              <Row>

                <div className='col-md-6'>
                  <h5 className='pismh2'>Instagram Handle</h5>
                  {/* <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="facebook" placeholder="Facebook Username" size="lg" />
</Form.Group> */}


                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="email" id="instagram" placeholder="Instagram Username" size="lg" />
                  </Form.Group>




                  {/* <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="twitter" placeholder="Twitter Username" size="lg" />
</Form.Group> */}
                  <button onClick={updateUserInfo} className='btn-block pibutton'>Save</button>

                  {/* <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="email" placeholder="User Type" size="lg" />
</Form.Group> */}
                </div>



              </Row>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />

            </Container>

          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { PersonalInformationPage as default }