import React, { useEffect, useReducer } from 'react';
import $ from 'jquery';
import Topbar from './topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"

import "../js/main.js"




const Top100Page = () => {



  useEffect(() => {
    document.title = "Top 100 | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)


    var settingstwo = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs",
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }
    var objarr = []
    var songArtists = []


    $.ajax(settingstwo).done(function (responsetwo) {
      console.log(responsetwo)

      var totalno = responsetwo.length

      $.each(responsetwo, function (i, item) {

        if (responsetwo[i].feature == "") {
          var feature = ""
        }
        else {
          var feature = "Ft " + responsetwo[i].feature
        }

        songArtists.push(responsetwo[i].user_id)
        //    var filteredArr = songArtists.filter(function(item, index) {
        //   if (songArtists.indexOf(item) == index)
        //     return item;
        // });
        // console.log(filteredArr)

        //    var uniq = songArtists.reduce(function(a,b){
        //     if (a.indexOf(b) < 0 ) a.push(b);
        //     return a;
        //   },[]);
        // var uniq2 = uniq


        // console.log(uniq2)
        // CHECK IF USER HAS A BALANCE
        let checkthis
        $.each(songArtists, function (i, item) {
          // console.log(songArtists[i])
          // setTimeout(function(){
          checkthis = songArtists[i]
        })
        console.log(checkthis)
        // console.log(songArtists[i])
        var settingsthree = {
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + checkthis,
          "method": "GET",
          "timeout": 0,
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        }
        $.ajax(settingsthree).done(function (responsethree) {
          console.log(responsethree)
          if (responsethree.balance !== 0) {
            $('#musicbox3').append($(
              "<a target='_blank' href='/newmusicsingle?songid=" + responsetwo[i]._id + "'>"
              + '<div class="musicitem">'
              + "<img class='musicimage' src='" + responsetwo[i].songart + "'"
              // + response[i].feature
              + '<br><p class="textfont moodtitle" style="margin-top:10px;">' + responsetwo[i].name + '</p>'
              + '<p class="textfont moodtitle" style="margin-top:-20px;">' + responsetwo[i].artist_name + " " + feature + '</p>'
              + " <a target='_blank' href='/newmusicsingle?songid=" + responsetwo[i]._id + "'>" + "</a>"

              + '</div>'
              + '</a>'
            ));

            $('#musicbox4').append($(
              "<a target='_blank' href='/newmusicsingle?songid=" + responsetwo[i]._id + "'>"
              + '<div class="musicitem">'
              + "<img class='musicimage' src='" + responsetwo[i].songart + "'"
              // + response[i].feature
              + '<br><p class="textfont moodtitle" style="margin-top:10px;">' + responsetwo[i].name + '</p>'

              + '<p class="textfont moodtitle" style="margin-top:-20px;">' + responsetwo[i].artist_name + " " + feature + '</p>'
              + " <a target='_blank' href='/newmusicsingle?songid=" + responsetwo[i]._id + "'>" + "</a>"

              + '</div>'
              + '</a>'
            ));

          }
        })

        // }, 3000);





        // CHECK IF USER HAS A BALANCE

        // var myDiv = response[i].song_art
        // myDiv.innerHTML = response[i].song_art
        // document.getElementById("newmusicbox").append(myDiv);
        // document.getElementById("songtitle").append(myDiv);

        // var totalno = response[i].id.length;
        // console.log(totalno)

        // $("body").html("<div>This is a new div.</div>");

        objarr.push(responsetwo[i])
        // console.log(objarr.length)



        // let imalges = ["url1","url2","url3","url4","url5"];
        // console.log(imalges)
        // let div='';
        // let j = 3;
        // for(var i = 0; i < imalges.length; i++ ){

        //   if(i % 3 === 0 || i == 0 ) div +=  "<div class='row'>";
        //     div +=  "<div class='col-md-4'>" + imalges[i] + "</div>";

        //   if( j == i || i === 2 || (imalges.length-1) === i )  {
        //     j += 3
        //     div +=  "</div>";

        //   }
        // }


        // document.getElementById('newmusicbox').innerHTML = div
        // console.log(div)







        // $(function () {
        //   var musicbox2 = document.getElementById('newmusicbox2').innerHTML
        //   let row = $('<div>').addClass('row').prependTo(newmusicbox2);
        //     for (let i = 0; i < 4; i++) {
        //         let col = $('<div>').addClass('col-md-3').appendTo(row);
        //         createCardsFor(col);
        //     }
        // })
        // function createCardsFor($container) {
        //     for (let i = 0; i < 1; i++) {
        //         let card = $('<div>').addClass('card').css({
        //             'margin': '10px',
        //             'box-shadow': '0 0 6px rgba(0,0,0, .2)'
        //         }).appendTo($container);
        //         let cardHeader = $('<div>').addClass('card-header').appendTo(card);
        //         let cardImage = $('<img>').attr({
        //             alt: "alt",
        //             src: "https://via.placeholder.com/50"
        //         }).appendTo(cardHeader);
        //         let deleteButton = $('<button>').addClass('btn btn-sm btn-outline-danger').text('Delete').on('click', deleteButtonClick).appendTo(cardHeader);
        //         let cardBody = $('<div>').addClass('card-body').appendTo(card);
        //         let bodyTitle = $('<h5>').text('Title').appendTo(cardBody);
        //         let bodyText = $('<p>').text('Lorem Ipsum Dolor...').appendTo(cardBody);
        //     }
        // }

        // function deleteButtonClick(e) {
        //     e.preventDefault();
        // }
        // $("<div class='row' id=''>").html(
        //       "<div style='margin-top:30px;' class='col-md-4'>"+
        //       "<img class='albumimage' src='" +response[i].song_art+ "'"
        //           //  +  "<p style=''>" + "daddyyo"+ "</p>"
        //           //  +  "<button>" + "View"+ "</button>"

        //      +  "<p style='text-align:center' class='centerthis'>" +response[i].name+ "</p>"
        //       // +"<p class='centerthis'>" + response[i].feature + "</p>"
        //       // +" <a target='_blank' href='/newmusicsingle?songid="+ response[i].id +"'>" +"<button class='btn btn-danger btn-sm'  id='appbtns' class='btn btn-sm downloadreceiptbutton'> VIEW SONG</button>"  +"</a>"

        //       + "</div>"

        //             ).appendTo('#newmusicbox');









      })
    })

































  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">



            <div class="container">
              <h4 className="webappinnerheading3">Top 100</h4>

              <div class="row">

                <div class="col-md-2 col-6 dt">
                  <a href="/afrobeatdaily">
                    <p class="clientpp"> <img class="albumimage" src="/images/webapp/top100/afrobeat.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Afrobeat </p>
                </div>

                <div class="col-md-2 col-6 dt">
                  <a href="/afropopdaily">
                    <p class="clientpp"> <img class="albumimage" src="/images/webapp/top100/afropop.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Afropop </p>
                </div>



                <div class="col-md-2 col-6 dt">
                  <a href="/bluesdaily">
                    <p class="clientpp"> <img class="albumimage" src="/images/webapp/top100/blues.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Blues </p>

                </div>



                <div class="col-md-2 col-6 dt">
                  <a href="/rapdaily">
                    <p class="clientpp">    <img class="albumimage" src="/images/webapp/top100/rap.png" /></p>
                  </a>
                  <p class="songtitle">Top 100: Rap </p>

                </div>

                <div class="col-md-2 col-6 dt">
                  <a href="/altedaily">
                    <p class="clientpp">    <img class="albumimage" src="/images/webapp/top100/alte.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Alte </p>

                </div>

                <div class="col-md-2 col-6 dt">
                  <a href="/gospeldaily">
                    <p class="clientpp">       <img class="albumimage" src="/images/webapp/top100/gospel.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Gospel </p>

                </div>
              </div>

              <div class="row" style={{ marginTop: "20px" }}>









                <div class="col-md-2 col-6 dt">
                  <a href="/highlifedaily">
                    <p class="clientpp">       <img class="albumimage" src="/images/webapp/top100/highlife.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Highlife </p>

                </div>

                <div class="col-md-2 col-6 dt">
                  <a href="/souldaily">
                    <p class="clientpp">      <img class="albumimage" src="/images/webapp/top100/soul.jpeg" /></p>
                  </a>
                  <p class="songtitle">Top 100: Soul </p>

                </div>
              </div>




            </div>





          </div>


        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { Top100Page as default }