import React, { useEffect, useReducer } from 'react';
import { Container, Row, Button, Nav } from 'react-bootstrap'
import "../stylesheets/website.css"
import { Link } from 'react-router-dom';

import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
import $ from 'jquery';


const ConfirmationPage = () => {
  useEffect(() => {
    document.title = "E-mail Confirmation | The New Trybe"
  }, [])

  var pathname = window.location.href;
  let params = (new URL(pathname)).searchParams;

  var tokens = params.get('token')
  console.log(tokens)
  var settings = {


    "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/confirm/" + tokens,
    "method": "GET",
    //   "headers": { "Authorization": "Bearer " + usertoken },
    "timeout": 0,

  }

  $.ajax(settings).done(function (response) {
    console.log(response)

  })



  return (

    <div id="emailverificationbody">
      <Container className='page'>

        <Row className="">

          <div className="col-md-12 evfirstbox">
            <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_W6nHGZ/Whats app.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} loop autoplay></lottie-player>




            <h2 className='evtitle'>Welcome to The New Trybe</h2>
            <p className='evsubtitle'>You can be sure not to miss out on any opportunity to earn as<br /> we will be updating you regularly on our WhatsApp community.</p>


          </div>


        </Row>
        <Row className="evsecondbox">
          <div className="col-md-2">
          </div>
          <div className="col-md-8">
            <p className='evdescription' style={{ textAlign: "center", color: "#C4C4C4" }}>
              {/* Click Here To Continue */}

              <Nav.Link as={Link} to="/login" >
                <Button className="confirmationbutton" type="submit">
                  Continue
                </Button>
              </Nav.Link>




            </p>
            <p className='evdescription' style={{ textAlign: "center", color: "#C4C4C4" }}>


            </p>
          </div>
          <div className="col-md-2">
          </div>


        </Row>







        {/* <Footer/> */}
      </Container>
    </div>
  )
}


export { ConfirmationPage as default }