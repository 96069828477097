import React, { useEffect, useReducer} from 'react';
import {Container, Row,Button, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import "../stylesheets/website.css"
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';

const ArtistPage = () => {
    useEffect(() =>{
        document.title = "Artistes | The New Trybe"
    },[])

    return (
      
      <div>


          <Header/>


<div className="homepagefirstbox">
<Container className='page'>
    <Row >
        <div className="col-md-6">
            <h2 className='homepagetitle'>For  <span className="homepagefavourite">Artistes.</span> </h2>
            <p className='homepagedescription'>
            You’ve made it to the New Trybe where it’s all about taking your music to a whole NEW level - upload a song and watch it go viral even if you just started your career today. The game is fair and every new music you upload, drops on thousands of listeners’ feed
                  
                   </p>   
            <p className="footermenup">
          <a href="#artists"><Button  className="homepagectabutton1">Read More</Button></a>
          {/* <a href="#listeners"> <Button  className="homepagectabutton2">For Listeners</Button></a> */}

            </p>
     
            </div>

            <div className="col-md-6">
            <img className="img-fluid afbimg" src="/images/aboutfirstboximg.png"/>
            {/* <div className="homepageyt"> */}
                
            {/* <iframe className="hpyt" src="https://www.youtube.com/embed/om1L-jfifJ4?showinfo=0&controls=0" title="YouTube video player" rel="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            {/* <img className="hpi1 img-fluid" src="/images/homepageimage.png"/> */}
            {/* </div> */}
            </div>
    </Row>
    </Container>
    </div>



<div id="artists" className="homepagesecondbox">
    <Container>
<Row>
<div id="" className="col-md-12">
        {/* <img className="afbimg" src="/images/aboutfirstboximg2.png"/> */}
{/* 
        <h2 className='forartistehp unlimitedmobile'>For<span className="homepagefavourite"> Artistes</span> </h2>
            <p className='aboutdescription'>

            You’ve made it to the New Trybe where it’s all about taking your music to a whole NEW level - upload a song and watch it go viral even if you just started your career today. The game is fair and every new music you upload, drops on thousands of listeners’ feed


</p> */}

 
<div className="homepagesecondboxhp">
<Container className='page'>
    <Row >
        <div className="col-md-6">
        <img className="img-fluid  growaudienceimage" src="/images/audience.jpg"/>

 
     
            </div>

            <div className="col-md-6">
            <h2 className='homepagetitle5 unlimitedmobile'>Grow<span className="homepagefavourite"> Audience</span> </h2>
            <p className='homepagedescription'>
            We send your music directly to your audience and we are growing everyday               
                   </p>   

                   <h2 className='homepagetitle5'>Show <span className="homepagefavourite"> Bookings </span></h2>

                   <p className='homepagedescription'>

                   Get booked for shows and events 10x faster as you grow your fan base.
                    </p>   

                    <h2 className='homepagetitle5'>Collaborate with other <span className="homepagefavourite"> Artistes </span> </h2>

<p className='homepagedescription'>

Find and collaborate with other artistes, celebrate your music diversity and give your fans something to celebrate
 </p>   
            </div>
    </Row>
    </Container>
    </div>




    <div className="homepagefirstboxlearnmore">
<Container className='page'>


<div class="row" style={{marginTop:"0px"}}>
    <div class="col-md-6">
    <h2 className='homepagetitle51'>Instagram<span className="homepagefavourite"> Audience</span> </h2>

    <p className='homepagedescription'>
    We will build you a strong & large community of music lovers on Instagram – for every stream you get, you earn a new follower.
                               </p>   
                               <h2 className='homepagetitle5'>Talk to industry experts<span className="homepagefavourite"> (Coming Soon)</span> </h2>

                               <p className='homepagedescription'>
                               Talk to Industry experts (managers, producers, distributers, promoters) in our monthly Q/A sessions and learn what it takes to grow even bigger.          
                   </p>   

                   <h2 className='homepagetitle5'>Share your <span className="homepagefavourite"> Music</span> </h2>

<p className='homepagedescription'>
But most importantly, we are giving you a platform to be your awesome self, share your music – whenever, however, connect and Shine on your own terms.       
</p>   

        </div>

        <div class="col-md-6">
        <img className="img-fluid learnmorestreamimage2 desktopdisplay" src="/images/igaudience.jpg"/>

     
        </div>
    </div>

    <div class="row" style={{ paddingTop:"40px"}} >
    <div class="col-md-6">
  
    <img className="img-fluid  dashboardlastimage" src="/images/feedback.jpg"/>

  

        </div>

        <div class="col-md-6 musicratingbox">
        <h2 className='homepagetitle5'>Comprehensive <span className="homepagefavourite"> Dashboard</span> </h2>

<p className='homepagedescription'>
Get access to a comprehensive dashboard that shows you real time data of your song’s performance, who your listeners are and feedback from your listeners</p>   



<h2 className='homepagetitle5'><span className="homepagefavourite">Reward</span> Fans & They <span className="homepagefavourite"> Reward </span> you </h2>

<p className='homepagedescription'>
We save you money because for every Naira you reward your listeners with, you can see it convert to an actual stream of your music (Say yes! to spend you can measure
</p>


<h2 className='homepagetitle5'>Collaborate with an <span className="homepagefavourite">A list</span> artiste. <br/>(Coming Soon) </h2>

<p className='homepagedescription'>
After you have hit 500,000 streams and maintained a Top 10 position for 12 consecutive weeks, you get a chance to collaborate with one of Africa’s biggest Artistes. </p>

        </div>

    </div>



 
    </Container>

  
    </div>




{/* 
<p className='aboutdescription' style={{textAlign:"center",paddingLeft:""}}>
You have all it takes, you have the music, you have the audience, go take the FAME! Sign up <span className="homepagefavourite"><a href="/signup">HERE</a></span>
</p> */}
              {/* <Nav.Link  as={Link} to="/signup" >  <Button  className="aboutctabutton" >Learn More</Button></Nav.Link> */}
             
            </div>

            {/* <h3 className='homepagetitle2'>Signup Step By Step Guide for <span className="homepagefavourite">You.</span> </h3> */}
            <h2 style={{paddingBottom:"20px"}} className='forartistehp unlimitedmobile'>Signup Step By Step Guide for <span className="homepagefavourite"> You.</span> </h2>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">1</span>  <span className="hppb">Create an account <span className="homepagefavourite"><a href="/signup">HERE</a></span></span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">2</span>  <span className="hppb">Complete your profile</span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">3</span>  <span className="hppb">Fund your account</span></p>
            </div>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">4</span>  <span className="hppb">Upload your song</span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">5</span>  <span className="hppb">Watch your numbers go up.</span></p>

            </div>
</Row>
  
 
    </Container>
    </div>



<div className="homepagethirdbox">
    <Container>
    <Row >
        <div className="col-md-6">
        <img className="appillimage" style={{height:"500px", width:"367px"}}src="/images/appillustration.png"/>
            </div>

            <div className="col-md-6 homepagedownloadbox">
            <h2 className='homepagedownloadtitle'>Come join the Trybe</h2>
            <p className='homepagedescription'>
            We’ve got a whole trybe here, Music lovers like you who are already cashing out and having the best time enjoying new music and discovering new artistes. 
            <br/>
            Because of you, a talented artiste out there gets to be discovered and celebrated. Let’s support more of them

             </p>   
        
            <Button  className="homepagedownloadbutton">Join Now</Button>
            {/* <Button  className="ctabutton playstorebutton" variant="outline-success">PlayStore</Button> */}
            </div>
    </Row>
    </Container>

    </div>


    {/* <div className="homepagefourthbox">
<Container>
    <Row className="">
        <div className="col-md-6">
            <h2 className='homepagelasttitle'>Listen and Get Paid!</h2>
            <h2 className='homepagelasttitle'>How Simple can it get!!!!</h2>
            </div>

            <div className="col-md-6">
            <Nav.Link  as={Link} to="/signup" >   <Button  className="homepagectabutton">Get Started</Button></Nav.Link>

            </div>
    </Row>
    </Container>
</div> */}


    <Footer/>
    </div>


    )
    
    
}


export {ArtistPage as default}