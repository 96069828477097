import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Link } from 'react-router-dom';
import { Container, Row, Button, Nav, Modal, Form } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const ExplorePage = () => {

  const [show, setShow] = useState(false);
  const [username, setUserName] = useState("User");
  const [bio, setbio] = useState()
  const [instagramname, setinstagramname] = useState("")
  const [following, setfollowing] = useState()
  const [followers, setfollowers] = useState()
  const [instagramid, setinstagramid] = useState()
  const [instagramimg, setinstagramimg] = useState()
  const [notify, setNotify] = useState()
  const [welcometext, setWelcomeText] = useState()
  const [welcometext2, setWelcomeText2] = useState()


  useEffect(() => {
    $('.loading').css("visibility", "visible");
  }, [])

  const searchUser = () => {
    var ighandle = document.getElementById("instagramhandle").value
    console.log(ighandle)

    if (ighandle === "") {
      setNotify("Input a valid Instagram handle without @")
    }
    else {
      setNotify("Processing...")
      const settingsuser = {
        "async": true,
        "crossDomain": true,
        beforeSend: function () {
          $('.loading').css("visibility", "visible");
        },
        "url": "https://instagram28.p.rapidapi.com/user_info?user_name=" + ighandle,
        "method": "GET",
        "headers": {
          "x-rapidapi-host": "instagram28.p.rapidapi.com",
          "x-rapidapi-key": "e159be1a99msh1d7ba1d55b1aba3p1a984fjsn9dab505cd247"
        },
        complete: function () {
          $('.loading').css("visibility", "hidden");
        }
      };

      $.ajax(settingsuser).done(function (response) {
        console.log(response)
        console.log(response.length)

        if (!response.status) {
          setNotify("User not found")
          setbio("")
          setfollowing("")
          setfollowers("")
          setinstagramimg("")
          setinstagramid("")
          setinstagramname("")
        }
        else {
          setbio(response.data.user.biography)
          setfollowing(response.data.user.edge_follow.count)
          setfollowers(response.data.user.edge_followed_by.count)
          setinstagramimg(response.data.user.profile_pic_url_hd)
          setinstagramid(response.data.user.id)
          setinstagramname(response.data.user.full_name)

          $('.twitterverifybox').css("display", "block");
          setNotify("")
        }

      });
    }



  }

  const saveUser = () => {

    if (instagramname === "") {
      setNotify("Input a valid Instagram handle without @")
    }

    else if (notify === "User not found") {
      setNotify("User not found ")
    }
    else {
      setNotify("Processing...")

      var instagram = document.getElementById("instagramhandle").value

      var settingsupdate = {
        beforeSend: function () {
          $('.loading').css("visibility", "visible");
        },

        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
        "method": "PATCH",
        "timeout": 0,

        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        "data": {


          "instagramid": instagramid,
          "onboarded": true,
          "instagram": instagram,
          "stagename": localStorage.getItem("stagename"),
          "phone": localStorage.getItem("loggedinuserphone")
        },
        error: function (xhr, status, error) {
          console.log(xhr)
        },

        complete: function () {
          $('.loading').css("visibility", "hidden");
        }
      }

      $.ajax(settingsupdate).done(function (response) {
        console.log(response)
        const name = response.firstname + " " + response.lastname
        localStorage.setItem("userinstagram", instagram);
        localStorage.setItem("userfullname", name);
        localStorage.setItem("userfirstname", response.firstname);
        localStorage.setItem("userlastname", response.lastname);
        localStorage.setItem("usertwitter", response.twitter);
        localStorage.setItem("bank", response.bankname);
        localStorage.setItem("earnings", response.earnings);
        localStorage.setItem("accountnumber", response.accountnumber);
        localStorage.setItem("picture", response.picture);
        localStorage.setItem("loggedinuserphone", response.phone);
        localStorage.setItem("useremail", response.email);
        localStorage.setItem("loggedinusertype", response.user_role);
        localStorage.setItem("userid", response._id);
        localStorage.setItem("stagename", response.stagename);
        localStorage.setItem("about", response.about);
        localStorage.setItem("picture", response.picture);
        localStorage.setItem("instagramid", response.instagramid);
        window.location.replace("/explore");

      })
    }



  }


  useEffect(() => {
    document.title = "Explore | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)
    // setShow(true)

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      if (response.onboarded !== "true" && response.user_role === "Artist") {
        setShow(true)

        setUserName(response.firstname + " " + response.lastname)
        setWelcomeText(" We put your music in the spotlight and allow the world discover your genius. Go make beautiful music, upload them here and watch as your music go mainstream and reach your audience in minutes.")
        setWelcomeText2("Please provide your valid instagram handle as it will be required for listeners to stream your songs.")

      }

      else if (response.onboarded !== "true" && response.user_role === "Listener") {
        setShow(true)

        setUserName(response.firstname + " " + response.lastname)
        setWelcomeText(" Listen to new music and get paid everytime you do – We are a free community.")
        setWelcomeText2("Please provide your valid instagram handle as it will be required to process your payout requests.")

      }

    })


    var settingstwo = {
      beforeSend: function () {
        $('.AnyElementYouWantToShowOnAjaxSend').fadeIn(50);
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs?limit=20",
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        $('.loading').css("visibility", "hidden");
        $(".AnyElementYouWantToShowOnAjaxSend").fadeOut(250);
      }
    }
    var objarr = []
    var songArtists = []
    let checkthis

    $.ajax(settingstwo).done(function (responsetwo) {

      var totalno = responsetwo.length

      $.each(responsetwo, function (i, item) {

        // if(responsetwo[i].admin_approved != false){
        console.log(responsetwo[i])
        songArtists.push(responsetwo[i].user_id)
        console.log(songArtists)
        // }

        if (responsetwo[i].feature == "") {
          var feature = ""
        }
        else {
          var feature = "Ft " + responsetwo[i].feature
        }

        // CHECK IF USER HAS A BALANCE

        $.each(songArtists, function (i, item) {
          console.log(songArtists[i])
          // setTimeout(function(){
          checkthis = songArtists[i]
        })
        console.log(checkthis)
        // console.log(songArtists[i])
        var settingsthree = {
          beforeSend: function () {
            $('.loading').css("visibility", "visible");

          },
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + checkthis,
          "method": "GET",
          "timeout": 0,
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          complete: function () {
            $('.loading').css("visibility", "hidden");
          }
        }
        $.ajax(settingsthree).done(function (responsethree) {
          console.log(responsethree)
          if (responsethree.balance > 0 && responsetwo[i].admin_approved != false) {
            $('#musicbox3').append($(
              "<a target='_blank' href='/stream?songid=" + responsetwo[i]._id + "'>"
              + '<div class="musicitemhome">'
              // + "<img class='musicimage' src='" +responsetwo[i].songart + "'"
              // + "<img class='musicimage' src='/images/hamburger.png'"
              + "<img class='musicimage'  src='/images/3.jpg'/> "
              // + response[i].feature
              + '<br><p class="textfont moodtitle streamartist" style="margin-top:10px;">' + "Listen to " + responsetwo[i].name + '</p>'
              + '<p class="textfont moodtitle streamartist" style="margin-top:-20px;">' + "By " + responsetwo[i].artist_name + " " + feature + '</p>'
              + " <a target='_blank' href='/stream?songid=" + responsetwo[i]._id + "'>" + "</a>"

              + '</div>'
              + '</a>'
            ));

            $('#musicbox4').append($(
              "<a target='_blank' href='/stream?songid=" + responsetwo[i]._id + "'>"
              + '<div class="musicitem">'
              + "<img class='musicimage' src='/images/musicplaceholder.jpg'"
              // + response[i].feature
              + '<br><p class="textfont moodtitle" style="margin-top:10px;">' + responsetwo[i].name + '</p>'

              + '<p class="textfont moodtitle" style="margin-top:-20px;">' + responsetwo[i].artist_name + " " + feature + '</p>'
              + " <a target='_blank' href='/stream?songid=" + responsetwo[i]._id + "'>" + "</a>"

              + '</div>'
              + '</a>'
            ));

          }
        })



        objarr.push(responsetwo[i])






      })
    })



  }, [])

  return (



    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Explore</h4>
            <div class="container-fluid">
              <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4">

                <Nav.Link as={Link} to="/newmusic" >
                  <div class="col-md-8">
                    <p class="featuretitle1">Discover</p>
                    <p class="featuretitle2">Discover new artists</p>
                    {/* <p class="featuretitle3">Search</p> */}


                    <div class="card featuremoodcard-block feature-1">

                      <div class="middle">
                        <div class="text"><i class="fas fa-play-circle playicon1"></i></div>
                      </div>

                    </div>


                  </div>
                </Nav.Link>



                <Nav.Link as={Link} to="/newmusic" >
                  <div class="col-md-8">
                    <p class="featuretitle1">Update your playlist</p>
                    <p class="featuretitle2">New Music</p>

                    <div class="card featuremoodcard-block feature-2">

                      <div class="middle">
                        <div class="text"><i class="fas fa-play-circle playicon1"></i></div>
                      </div>

                    </div>

                  </div>
                </Nav.Link>


                <Nav.Link as={Link} to="/top100" >
                  <div class="col-md-8">
                    <p class="featuretitle1">Most Streamed Songs</p>
                    <p class="featuretitle2">Top 100</p>

                    <div class="card featuremoodcard-block feature-3">

                      <div class="middle">
                        <div class="text"><i class="fas fa-play-circle playicon1"></i></div>
                      </div>

                    </div>

                  </div>
                </Nav.Link>



                {/* <Nav.Link  as={Link} to="/top100" >
                  <div class="col-md-4">
                    <p class="featuretitle1">UPDATE HOURLY</p>
                    <p class="featuretitle2">Top 100</p>
                    <p class="featuretitle3">The New Trybe</p>
                   
                    <div class="card featuremoodcard-block feature-3">
                  
                      <div class="middle">
                        <div class="text"><i class="fas fa-play-circle playicon1"></i></div>
                      </div>
                  
                    </div>
                  
                  </div>
                  </Nav.Link> */}








              </div>
            </div>


            <h5 className="webappinnerheading2 explorepagenewmusic">New Music <span class="viewall"><a href="/newmusic">View All</a></span></h5>

            <div class="container-fluid newmusic">

              <div class='scrolling-wrapper row nmrow flex-row flex-nowrap ' id='musicbox3'>

              </div>



            </div>


            <h5 className="webappinnerheading2">Top 100 <span class="viewall"><a href="/top100">View All</a></span></h5>

            <div class="container-fluid newmusic">
              <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-4">

                <div class="col-md-5">
                  <p class="clientpp"> <a href="/afrobeatdaily"> <img class="albumimage" src="/images/webapp/top100/afrobeat.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Afrobeat<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>


                <div class="col-md-5 addpaddingleft">
                  <p class="clientpp"> <a href="/bluesdaily"> <img class="albumimage" src="/images/webapp/top100/blues.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Blues<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">

                  <p class="clientpp"> <a href="/afropopdaily"><img class="albumimage" src="/images/webapp/top100/afropop.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Afropop<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">
                  <p class="clientpp">  <a href="/afrojujudaily">  <img class="albumimage" src="/images/webapp/top100/afrojuju.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Afrojuju<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">
                  <p class="clientpp">  <a href="/apaladaily">  <img class="albumimage" src="/images/webapp/top100/apala.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Apala<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">
                  <p class="clientpp"> <a href="/fujidaily">   <img class="albumimage" src="/images/webapp/top100/fuji.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Fuji<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">
                  <p class="clientpp">   <a href="/gospeldaily">    <img class="albumimage" src="/images/webapp/top100/gospel.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Gospel<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">
                  <p class="clientpp">   <a href="/highlifedaily">    <img class="albumimage" src="/images/webapp/top100/highlife.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Highlife<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>

                <div class="col-md-5">
                  <p class="clientpp">   <a href="/souldaily">   <img class="albumimage" src="/images/webapp/top100/soul.jpeg" /></a></p>
                  <p class="toptitle">Top 100 : Soul<br />
                    <span class="artistname">The New Trybe</span></p>
                </div>


              </div>




            </div>



            <br />
            <br />
            <br />
            {/* <div class="container-fluid hottracks">
                <h1 class="mt-4 menuheading"><span >-</span> Hot Tracks <span>-</span><span class="viewall"><a href="/hottracks">View All</a></span></h1>
                <div class='scrolling-wrapper row flex-row flex-nowrap ' id='musicbox4'>    
            
                </div>
          
        
            
              </div>
         */}





            {/* <h2>Collapsible Sidebar Using Bootstrap 4</h2>
       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>



       <h2>Lorem Ipsum Dolor</h2>
       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>



       <h2>Lorem Ipsum Dolor</h2>
       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>



       <h3>Lorem Ipsum Dolor</h3>
       <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
   
    */}
          </div>
        </div>

      </div>


      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Welcome to The New Trybe {username}</h5>




          <div className="hidediv">

            {/* <p className="requestnotify2">{Notify}</p> */}
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
            {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}

            {/* <p style={{textAlign:"center"}}> <button  onClick={requestPayout2 } type="button" class="btn btn-lg" className='fundaccountbutton2' >Request Payout</button></p> */}
          </div>
          <div className=""  >
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}






            <p className="welcometext">
              {welcometext}
            </p>

            <p style={{ color: "#FFF" }}>
              {welcometext2}
            </p>

            <p style={{ textAlign: "center", color: "#FFFFFF" }}>Input your Instagram handle without @ e.g thenewtrybe</p>
            <br />
            <div class="row">
              <div class="col-md-3"></div>

              <div class="col-md-6">
                <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                  <Form.Control type="text" placeholder="Instagram handle" id="instagramhandle" size="lg" />
                </Form.Group>
              </div>

              <div class="col-md-3"></div>


              <div class="container twitterverifybox">
                <div class="row">

                  {/* <div class="col-md-4">
    <img width="300px" src="" />
      <p><img width="100" height="100" style={{borderRadius:"8px"}} src={instagramimg}/></p>
  </div> */}

                  <div class="col-md-12">
                    <p style={{ color: "#FFF" }}>Name: {instagramname}</p>
                    <p style={{ color: "#FFF" }}>Followers: {followers}</p>
                    <p style={{ color: "#FFF" }}>Following: {following}</p>
                    <p style={{ color: "#FFF" }}>Biography: {bio}</p>
                  </div>


                </div>

              </div>
              <p style={{ textAlign: "center", color: "#FFF", paddingTop: "20px" }}>{notify}</p>

              {/* style={{textAlign:"center"}} */}

              <div class="row">
                <div class="col-md-2">

                </div>

                <div class="col-md-9">
                  <p className="footermenup3">
                    <button type="button" onClick={searchUser} class="btn btn-lg" className='onboardedbutton' >Search</button>
                    <button type="button" onClick={saveUser} class="btn btn-lg" className='onboardedbutton' >Save</button>
                  </p>
                </div>

                <div class="col-md-1">

                </div>
              </div>


            </div>



            {/* <div class="col-md-12">
<p className="ifthis">If this is your account, Continue Below</p>
  </div> */}
            {/* <p style={{textAlign:"center"}}><button   type="button" onClick={saveUser} class="btn btn-lg" className='onboardedbutton' >Continue</button></p> */}



            {/* <p style="color:#fff; text-align:center; padding-top:40px">

  Don`t forget to follow us.
</p> */}
            {/* <p style="text-align:center; padding-top:20px;">

              <a target="_blank" href="https://instagram.com/thenewtrybe"> <i style="font-size:28px; color:#fff" class="fab fa-instagram"></i></a>
              <a target="_blank" href="https://facebook.com/thenewtrybe"><i style="font-size:28px;margin-left:10px; color:#fff"class="fab fa-facebook"></i></a>
              <a target="_blank" href="https://twitter.com/thenewtrybe"><i style="font-size:28px; margin-left:10px; color:#fff" class="fab fa-twitter"></i></a>
      
            </p>
   */}

            {/* <p > <button onclick="submitFunction()" type="button" class="btn btn-lg"
                >CONTINUE</button></p> */}



            {/* <p style={{textAlign:"center"}}> <button onclick="submitFunction()" type="button" class="btn btn-lg"
              style="color:white; background-color:#FA2D48; font-size:10px">SUBMIT</button></p> */}



          </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>




      <div class="AnyElementYouWantToShowOnAjaxSend">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { ExplorePage as default }