import React, { useEffect, useState } from 'react';
import { Container, NavDropdown, Nav, Navbar, Form, FormControl, Button, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import $ from 'jquery';
import "../stylesheets/website.css"
import "../js/main.js"

const Topbar = () => {
  const [Songs, AllSongs] = useState("");
  // GET ALL SONGS TO FILTER THROUGH 
  useEffect(() => {
    var settingstwo = {
      //   beforeSend: function(){
      // $('.loading').css("visibility", "visible");
      // },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs",
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        // $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingstwo).done(function (responsetwo) {
      // console.log(responsetwo)
      // $.each(responsetwo, function (i, item) {
      //   console.log(responsetwo[i])
      //   if(responsetwo[i].admin_approved = true){
      // AllSongs(responsetwo)
      // }
      // })
      AllSongs(responsetwo)
    })
  }, [])
  // GET ALL SONGS TO FILTER THROUGH 


  // SEARCH SUGGESTIONS
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // window.location.replace(/stream?songid=6180d90c7f20f00016612152)
    window.location.assign(`/stream?songid=${item._id}`)

    // $('.loading').css("visibility", "visible");
    $('#searchresult').css("display", "block");
    // $('#searchresult').fadeIn();
    //  console.log(item)
    //   setthesongid(item._id)
    //   setsongtitle(item.name)
    //   setartist(item.artist_name)
    //   setgenre(item.genre)
    //   setyear(item.createdAt)
    //    setart(item.songart)
    //    setartistid(item.user_id)
    //    setsong(item.songlink)



    //  function pageloader() {
    //   $('.loading').css("visibility", "hidden");
    //  }
    //  setTimeout(pageloader, 3000); 


    // $('#searchresult').css("display", "block");
    // $('#searchresult').fadeIn(2000);


  }





  // const handleOnFocus = () => {
  //   console.log('Focused')
  // }

  // const formatResult = (item) => {
  //   return item
  //   return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  // }
  // SEARCH SUGGESTIONS

  useEffect(() => {
    var usertoken = localStorage.getItem("access_token")
    var settings = {
      //       beforeSend: function(){
      // $('.loading').css("visibility", "visible");
      // },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        // $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      // console.log(response)
      if (response.picture === "") {
        document.getElementById("profilepicture").src = "/images/usericon2.png"
      }
      else {
        document.getElementById("profilepicture").src = response.picture
      }

      if (response.user_role === "Artist") {
        $(".forartistonly").css({ 'display': 'block' });
        $(".forlisteneronly").css({ 'display': 'none' });

      }




      if (response.user_role === "Listener") {
        var totalno = []
        var totalno2 = []
        //  console.log(response.streamedsongs.length - 1 * 15)
        document.getElementById("profilename").innerHTML = response.firstname + " " + response.lastname
        document.getElementById("profilemoney").innerHTML = "Earnings: &#x20A6;" + (response.streamedsongs.length - 1) * 15
        $.each(response.streamedsongs, function (i, item) {

          if (response.streamedsongs[i] !== null) {

            totalno.push(response.streamedsongs[i])
            var totalnumber = (totalno).length
            if (totalnumber <= 500) {
              document.getElementById("profiletype").innerHTML = "" + response.user_role
            }
            else if (totalnumber > 500 && totalnumber <= 2500) {
              document.getElementById("profiletype").innerHTML = "Starter " + response.user_role
            }

            else if (totalnumber > 2500 && totalnumber <= 5000) {
              document.getElementById("profiletype").innerHTML = "Bronze " + response.user_role
            }

            else if (totalnumber > 5000 && totalnumber <= 10000) {
              document.getElementById("profiletype").innerHTML = "Intermediate " + response.user_role
            }

            else if (totalnumber > 10000 && totalnumber <= 20000) {
              document.getElementById("profiletype").innerHTML = "Gold " + response.user_role
            }

            else if (totalnumber > 20000 && totalnumber <= 50000) {
              document.getElementById("profiletype").innerHTML = "Advannced " + response.user_role
            }

            else if (totalnumber > 50000) {
              document.getElementById("profiletype").innerHTML = "Platinum " + response.user_role
            }

          }
        })
      }

      else if (response.user_role === "Artist") {
        document.getElementById("profilename").innerHTML = response.stagename
        document.getElementById("profilemoney").innerHTML = "Balance: &#x20A6;" + response.balance.toLocaleString()





        var totalnumber = response.totalnoofstreams
        if (totalnumber <= 500) {
          document.getElementById("profiletype").innerHTML = "" + response.user_role
        }
        else if (totalnumber > 500 && totalnumber <= 2500) {
          document.getElementById("profiletype").innerHTML = "Starter " + response.user_role
        }

        else if (totalnumber > 2500 && totalnumber <= 5000) {
          document.getElementById("profiletype").innerHTML = "Bronze " + response.user_role
        }

        else if (totalnumber > 5000 && totalnumber <= 10000) {
          document.getElementById("profiletype").innerHTML = "Intermediate " + response.user_role
        }

        else if (totalnumber > 10000 && totalnumber <= 20000) {
          document.getElementById("profiletype").innerHTML = "Gold " + response.user_role
        }

        else if (totalnumber > 20000 && totalnumber <= 50000) {
          document.getElementById("profiletype").innerHTML = "Advannced " + response.user_role
        }

        else if (totalnumber > 50000) {
          document.getElementById("profiletype").innerHTML = "Platinum " + response.user_role
        }




        // document.getElementById("profiletype").innerHTML =  response.user_role
      }


      // if(response.user_role === "Listener" && response.onboarded===""){
      //   $("#myModal").modal('show');
      //   document.getElementById("usernotify1").innerHTML = response.firstname + " !"
      // document.getElementById("usernotify2").innerHTML = response.firstname
      // }   

      // else if(response.user_role === "Artist" && response.onboarded===""){
      //   $("#myArtistModal").modal('show');
      //   document.getElementById("usernotify3").innerHTML = response.firstname + " !"
      // }   



      // if (document.getElementsByClassName("profilename").innerHTML !== ""){
      //   document.getElementsByClassName("profilename").innerHTML == "Edit Your Profile"
      // }
      // else{
      //   document.getElementsByClassName("profilename").innerHTML = response.firstname + " " + response.lastname

      // }

    })



  }, [])


  return (
    <div class="navbar navbar-expand-lg navbar-light webapptopbar">
      <div class="container-fluid">

        <img id="webappsidebarCollapse" className="webapphamburger" src="/images/hamburger.png" />
        <img className="webappleftarrow" src="/images/leftarrow.png" />
        <a href="javascript: history.go(+1)"><img className="webapprightarrow" src="/images/rightarrow.png" /> </a>

        <button class="btn btn-dark d-inline-block d-lg-none ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i class="fas fa-align-justify"></i>
        </button>




        <div class="">

          <div class="searchbox" >
            <ReactSearchAutocomplete
              styling={{
                backgroundColor: "black",
                color: "#FFF",
                hoverBackgroundColor: "#949494",

              }}

              size="lg"
              placeholder="Search"
              items={Songs}
              fuseOptions={{ keys: ["name", "description"] }}
              onSearch={handleOnSearch}
              onHover={handleOnHover}
              onSelect={handleOnSelect}
              // onFocus={handleOnFocus}
              autoFocus
            // formatResult={formatResult}


            />
          </div>
        </div>






        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">

            {/* <Nav.Link  as={Link} to="/notifications" >
                 <li style={{paddingRight:"20px"}} class="nav-item">
                     <a class="nav-link webapprighticon" href="">
                          <img style={{paddingRight:"10px"}} src="/images/bellicon.png"/> Notifications</a>
                 </li>
                 </Nav.Link> */}

            {/* <Nav.Link  as={Link} to="/account" > */}
            <li style={{ paddingRight: "20px" }} class="nav-item">
              <a class="nav-link webapprighticon" href="/account">
                <img style={{ borderRadius: "50%" }} width="30" height="30" id="profilepicture" src="/images/usericon2.png" />
                <span style={{ paddingLeft: "10px" }} id="profilename"></span>

              </a>

            </li>
            {/* </Nav.Link> */}
          </ul>
        </div>
      </div>
    </div>
  )
};


export default Topbar;