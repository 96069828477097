import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Modal, Form } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import "../stylesheets/webapp.css"

import "../js/main.js"

const DeletePage = () => {
  const [notify, setNotify] = useState("")
  const [notify2, setNotify2] = useState("")
  const [show, setShow] = useState(false);
  const [Songs, AllSongs] = useState("");
  const [songtitle, setsongtitle] = useState("Title");
  const [song, setsong] = useState("song");
  const [artist, setartist] = useState("Artist");
  const [genre, setgenre] = useState("Genre");
  const [year, setyear] = useState("Date");
  const [art, setart] = useState("/images/musicplaceholder.jpg");
  const [role, setrole] = useState("");
  const [streamedsongs, setstreamedsongs] = useState("");
  const [artistid, setartistid] = useState("");
  const [artistinstagram, setartistinstagram] = useState("");
  const [artistinstagramid, setartistinstagramid] = useState("");
  const [artistfacebook, setartistfacebook] = useState("");
  const [artisttwitter, setartisttwitter] = useState("");
  const [artisttwitterwithouturl, setartisttwitterwithouturl] = useState("");
  const [artistinstagramwithouturl, setartistinstagramwithouturl] = useState("");
  const [artistimage, setartistimage] = useState("");
  const [hasstreamed, sethasstreamed] = useState("false");
  const [instagramcheck, setinstagramcheck] = useState("false");
  const [twittercheck, settwittercheck] = useState("false");
  const [ratingscheck, setratingscheck] = useState("false");
  const [ratingsfigure, setratingsfigure] = useState("");

  const [thesongid, setthesongid] = useState("")

  const [showDelete, setDeleteShow] = useState(false);
  const [showHide, setHideShow] = useState(false);

  const showdeletemodal = () => {
    setDeleteShow(true)
    console.log(thesongid)
  }

  const showhidemodal = () => {
    setHideShow(true)
    console.log(thesongid)
  }

  const handleClose = () => {
    setDeleteShow(false)
    setHideShow(false)
  }


  const hidesong = () => {


  }

  const deletesong = () => {
    setNotify2("Processing...Please wait")
    var settingsthree = {

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + thesongid,
      "method": "DELETE",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },

    }

    $.ajax(settingsthree).done(function (responsethree) {
      setNotify2("Deleted Successfully..Redirecting")
      window.location.replace("/viewstreams");

    })
  }




  if (role === "Artist") {
    $(".forartistonly").css({ 'display': 'block' });
    $(".nmsbuttons").css({ 'display': 'block' });
    $(".artistnotify").css({ 'display': 'block' });
    $(".forlisteneronly").css({ 'display': 'none' });
    $(".nmsbuttons2").css({ 'display': 'none' });
  }
  else {
    $(".forartistonly").css({ 'display': 'none' });
    $(".forlisteneronly").css({ 'display': 'block' });
  }





  useEffect(() => {
    document.title = "Hide/Delete Song | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    console.log(usertoken)
    if (usertoken === null) { window.location.replace("/login"); }


    // GET LOGGED IN USER ROLE AND STREAMED SONGS





    // GET ALL SONGS TO FILTER THROUGH 
    var pathname = window.location.href;
    console.log(pathname)
    let params = (new URL(pathname)).searchParams;
    var songid = params.get('songid') // "n1"
    setthesongid(songid)
    var settingstwo = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + songid,
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingstwo).done(function (responsetwo) {
      console.log(responsetwo)

      setthesongid(responsetwo._id)
      setsongtitle(responsetwo.name)
      setartist(responsetwo.artist_name)
      setgenre(responsetwo.genre)
      setyear(responsetwo.createdAt)
      setart(responsetwo.songart)
      setartistid(responsetwo.user_id)
      setsong(responsetwo.songlink)


      var settingsthree = {
        beforeSend: function () {
          $('.loading').css("visibility", "visible");
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/user/" + responsetwo.user_id,
        "method": "GET",
        "timeout": 0,
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        complete: function () {
          $('.loading').css("visibility", "hidden");
        }
      }
      $.ajax(settingsthree).done(function (response) {
        console.log(response)
        var totalno = response.length

        $.each(response, function (i, item) {
          if (response[i].feature == "") {
            var feature = ""
          }
          else {
            var feature = "Ft " + response[i].feature
          }
          $('#musicbox3').append($(
            "<a target='_blank' href='/newmusicsingle?songid=" + response[i]._id + "'>"

            + '<div class="musicitemmain">'
            + "<img class='musicimagemain' src='" + response[i].songart + "'"
            // + response[i].feature
            + '<br><p class="textfont2" style="color:#fff; margin-top:10px;">' + response[i].artist_name + " " + feature + "  : " + response[i].name + '</p>'
            + " <a target='_blank' href='/stream?songid=" + response[i]._id + "'>" + "</a>"

            + '</div>'
            + '</a>'
          ));

        })
      })

      // AllSongs(responsetwo)
    })
    // GET ALL SONGS TO FILTER THROUGH 



  }, [])

  // },[thesongid, songtitle, artist, genre, year, streamedsongs])


  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />

        <Modal id="myModal" class="modal fade modal-content"
          show={showHide} onHide={handleClose}


        >

          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>


            <div class="row">
              <div class="col-md-2"></div>

              <div class="col-md-8">
                <br />


                <p style={{ color: "#FFF", textAlign: "center" }}>Are you sure you want to hide this song</p>
              </div>

              <div class="col-md-2"></div>


              <div class="container twitterverifybox">
                <div class="row">



                </div>

              </div>


              <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify2}</p>
              <p style={{ textAlign: "center", marginTop: "10px" }}><button type="button" onClick={hidesong} class="btn btn-lg" className='onboardedbutton' >Hide</button></p>
              <br />
              <br />
            </div>


          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
      
        </Modal.Footer> */}
        </Modal>

        <Modal id="myModal" class="modal fade modal-content"
          show={showDelete} onHide={handleClose}
        >

          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>


            {/* <p style={{textAlign:"center",color:"#FFF",paddingTop:"0px"}}>{notify2}</p> */}

            <div class="row">



              <br />


              <p style={{ color: "#FFF", textAlign: "center" }}>Are you sure you want to delete this song</p>





              <div class="row">
                <div class="col-md-2">

                </div>

                <div class="col-md-9">
                  <p className="footermenup3">
                    <br />
                    <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify2}</p>
                    <button type="button" onClick={deletesong} class="btn btn-lg" className='onboardedbutton' >Delete</button>
                  </p>
                </div>

                <div class="col-md-1">

                </div>
              </div>


            </div>


          </Modal.Body>
          {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
       
        </Modal.Footer> */}
        </Modal>


        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">


            <hr className="searchhr" />


            {/* <div class="row" > */}
            <Container id="">
              <Row >
                <div class="col-md-3">
                  <p class=""><img className="songart1366" style={{ borderRadius: "24px " }} id="songart"
                    src={art} /></p>
                </div>

                <input type="hidden" id="loggedinuserid" value="" />
                <input type="hidden" id="streamedstatus" value="" />
                <div class="col-md-9">
                  <div class="">
                    <h6 class="nmsartistalbum" id="songtitle">{songtitle}</h6>
                    <h4 class="nmsartistname" id="artist">{artist}</h4>
                    <p class="nmsartistyear nmsartistyear1024">
                      <span id="genre">{genre}</span> - <span id="year">{year}</span>
                    </p>
                    <p className="">
                      <br />
                      <p style={{ textAlign: "center", color: "#FFF", paddingTop: "10px" }}>{notify}</p>
                      {/* <button onClick={showhidemodal}   type="button"  class="btn btn-lg" className='onboardedbutton' >Hide</button> */}
                      <button onClick={showdeletemodal} type="button" class="btn btn-lg" className='onboardedbutton' >Delete</button>

                    </p>


                    <input type="hidden" value="" id="userid" />
                    {/* <hr/> */}



                    <div>
                      <div class="">
                        {/* <p class="qdet1">1 Song, 3 Minutes</p>
        <p class="qdet1" id="qdet2">Release Date</p> */}

                      </div>
                    </div>
                  </div>
                </div>
              </Row>

              <div class="newmusicsingleboxtwo">
                <div class="containerf">
                  <div class="containerf nmcont">
                    {/* <h1 class="mt-4 menuheading moreby">More by Artist </h1> */}




                  </div>
                </div>


              </div>
            </Container>
            {/* </div> */}
            {/* <h1 class="mt-4 menuheading moreby containers">More from Artist </h1>
<div class='containers newmusicmain' id='musicbox3'>

</div> */}

          </div>
        </div>

      </div>




      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { DeletePage as default }