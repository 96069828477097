import React, { useEffect, useReducer} from 'react';
import {Container, Row,Button,} from 'react-bootstrap'
import "../stylesheets/website.css"

import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
const EmailVerificationPage = () => {
    useEffect(() =>{
        document.title = "E-mail Verification | The New Trybe"
    },[])


    return (
        
<div id="emailverificationbody">
<Container className='page'>

    <Row className="">

        <div className="col-md-12 evfirstbox">
        <lottie-player src="https://assets2.lottiefiles.com/packages/lf20_ebqz3ltq.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player>



      
            <h2 className='evtitle'>Email Verification</h2>
            {/* <p>A verification has been sent to your email.</p>
            <p>Kindly check your email (Inbox or Promotion) for your verification link and activate your account.</p>
          */}
            </div>

          
    </Row>
    <Row className="evsecondbox">
        <div className="col-md-3">
            </div>
            <div className="col-md-6">
            <p className='evdescription' style={{textAlign: "center", color:"#C4C4C4"}}>

             A verification link has been sent to your email.
                 
                   </p>   
                   <p className='evdescription'  style={{textAlign: "center", color:"#C4C4C4"}}>

                   Kindly check your email <span style={{color:"#D80032"}}>(Inbox, Promotion or Spam)</span> for your verification link and activate your account.
    
      </p>   
            </div>
            <div className="col-md-3">
            </div>

          
    </Row>







    {/* <Footer/> */}
    </Container>
   </div>
    )
}


export {EmailVerificationPage as default}