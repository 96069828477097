import React, { useEffect, useReducer} from 'react';
import {Container, Row,Button, Nav} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import "../stylesheets/website.css"
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';

const ListenerPage = () => {
    useEffect(() =>{
        document.title = "Listener | The New Trybe"
    },[])

    return (
      
      <div>


          <Header/>


<div className="homepagefirstbox">
<Container className='page'>
    <Row >
        <div className="col-md-6">
            <h2 className='homepagetitle'>For  <span className="homepagefavourite">Listeners.</span> </h2>
            <p className='homepagedescription'>You discover new music and new artistes everyday already, why not get paid to do it? So we decided to pay you up to N50,000 every month to enjoy good music and discover new artistes –it’s very simple to start,

</p>   
            <p className="footermenup">
          <a href="#listeners"><Button  className="homepagectabutton1">Read More</Button></a>
          {/* <a href="#listeners"> <Button  className="homepagectabutton2">For Listeners</Button></a> */}

            </p>
     
            </div>

            <div className="col-md-6">
            <img className="img-fluid afbimg" src="/images/aboutfirstboximg.png"/>
            {/* <div className="homepageyt"> */}
                
            {/* <iframe className="hpyt" src="https://www.youtube.com/embed/om1L-jfifJ4?showinfo=0&controls=0" title="YouTube video player" rel="0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            {/* <img className="hpi1 img-fluid" src="/images/homepageimage.png"/> */}
            {/* </div> */}
            </div>
    </Row>
    </Container>
    </div>



<div className="homepagesecondbox">
    <Container>

  
    <Row >
 
        
        
  

     

            <div className="col-md-12 " id="listeners">
            {/* <h2 className='abouttitle6'>Come join the <span className="homepagefavourite">Trybe</span> </h2> */}
            <h3 className='homepagetitle3'>Come join the<span className=""> Trybe</span> </h3>

            <div class="row" >
    <div class="col-md-6">
  
    <img className="img-fluid  discoverimage" src="/images/discover.jpg"/>

  

        </div>

        <div class="col-md-6">
        <h2 className='homepagetitle5 hpdiscover'>Discover new <span className="homepagefavourite"> music</span> </h2>

<p className='homepagedescription'>
You discover new music and new artistes everyday already, why not get paid to do it? So we decided to pay you up to N50,000 every month to enjoy good music and discover new artistes<br/> It’s very simple to start.

</p>   





        </div>

    </div>
          
            {/* <p className='aboutdescription'>Our reward system
             created the listeners platform so that listeners discovering new artistes can enjoy
              cash rewards for every new song they stream.  </p>    */}
              {/* <h4 className='aboutwhatweoffer'>What we offer</h4>
              <p className='aboutdescription'>
              -	You sign-up here
      </p>
      <p className='aboutdescription'>
      -	You start streaming songs (we even have a Top 100 curated special waiting for you)
      </p>

      <p className='aboutdescription'>
      -	Earn N15 for every song you stream
      </p>

      <p className='aboutdescription'>
      -	Withdraw your money from your wallet anytime, after you earn N1000
      </p> */}
              {/* <Nav.Link  as={Link} to="/signup" >  <Button  className="aboutctabutton" >Create an account</Button></Nav.Link> */}
     
            </div>
          
            <h3 className='homepagetitle2'>Sign Up Step By Step Guide for <span className="homepagefavourite">You.</span> </h3>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">1</span>   <span className="hppb">You sign-up here</span></p>
            <p className="homepagehtsp"> <span className="homepagepagination">2</span>  <span className="hppb">Start streaming songs </span></p>


            </div>

            <div className="col-md-6">
            <p className="homepagehtsp"> <span className="homepagepagination">3</span> <span className="hppb"> Earn N15 for every song.</span></p>

            <p className="homepagehtsp"> <span className="homepagepagination">4</span>  <span className="hppb">Withdraw your money anytime.</span></p>

            </div>
    </Row>
    </Container>
    </div>



<div className="homepagethirdbox">
    <Container>
    <Row >
        <div className="col-md-6">
        <img className="appillimage" style={{height:"500px", width:"367px"}}src="/images/appillustration.png"/>
            </div>

            <div className="col-md-6 homepagedownloadbox">
            <h2 className='homepagedownloadtitle'>Come join the Trybe</h2>
            <p className='homepagedescription'>
            We’ve got a whole trybe here, Music lovers like you who are already cashing out and having the best time enjoying new music and discovering new artistes. 
            <br/>
            Because of you, a talented artiste out there gets to be discovered and celebrated. Let’s support more of them

             </p>   
        
            <Button  className="homepagedownloadbutton">Join Now</Button>
            {/* <Button  className="ctabutton playstorebutton" variant="outline-success">PlayStore</Button> */}
            </div>
    </Row>
    </Container>

    </div>


    {/* <div className="homepagefourthbox">
<Container>
    <Row className="">
        <div className="col-md-6">
            <h2 className='homepagelasttitle'>Listen and Get Paid!</h2>
            <h2 className='homepagelasttitle'>How Simple can it get!!!!</h2>
            </div>

            <div className="col-md-6">
            <Nav.Link  as={Link} to="/signup" >   <Button  className="homepagectabutton">Get Started</Button></Nav.Link>

            </div>
    </Row>
    </Container>
</div> */}


    <Footer/>
    </div>


    )
    
    
}


export {ListenerPage as default}