import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
// import 'bootstrap';



import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { PaystackButton } from 'react-paystack';
// import { PaystackPop } from 'react-paystack';


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"
// import "../js/bootstrap.js"
// import "../js/popper.js"




const FundAccountPage = () => {
  const [Amount, setAmount] = useState("")
  const [notify, setNotify] = useState("")
  const [notify1, setNotify1] = useState("")

  const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);


  console.log(localStorage.getItem("userid"))
  console.log(localStorage.getItem("useremail"))
  console.log(localStorage.getItem("loggedinuserphone"))
  console.log(localStorage.getItem("userfirstname"))
  console.log(localStorage.getItem("userlastname"))
  console.log(localStorage.getItem("stagename"))



  useEffect(() => {
    if (Amount < 1000) {
      setNotify('Minimum Amount is 10,000')
      $('.fundaccountbuttonp').css("display", "none");

    }

    else {
      setNotify('')
      $('.fundaccountbuttonp').css("display", "block");
    }



    const audience = (Amount / 20).toLocaleString()

    setNotify1(`Your song will reach ${audience} extra audience and you will get ${audience} followers on Instagram with the amount you wish to fund. Note: For every 30,000 you fund, 1 of your songs get distributed to Apple Music for FREE`)
    console.log(notify1)
  }, [Amount])
  // document.getElementById("amount").value,
  const config = {
    reference: (new Date()).getTime().toString(),
    email: localStorage.getItem("useremail"),
    amount: Amount * 100,
    firstname: localStorage.getItem("userfullname"),
    publicKey: 'pk_live_e4b8bdc926733299abdc72b5d70d50fb06c19980',
  };


  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    console.log(reference);

    var settingspayment = {

      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/payment",
      "method": "POST",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
      "data":
      {
        "user_id": localStorage.getItem("userid"),
        "email": localStorage.getItem("useremail"),
        "phone": localStorage.getItem("loggedinuserphone"),
        "firstname": localStorage.getItem("userfirstname"),
        "lastname": localStorage.getItem("userlastname"),
        "stagename": localStorage.getItem("stagename")
      },
      error: function (xhr, status, error) {
        console.log(xhr)

      },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }


    $.ajax(settingspayment).done(function (response) {
      console.log(response)

    })


    var settingspaystack = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }

    $.ajax(settingspaystack).done(function (response) {
      console.log(response.balance)
      var balanceint = parseInt(Amount) + parseInt(response.balance)
      console.log(balanceint)
      $.ajax({
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user/" + localStorage.getItem("userid"),
        "method": "PATCH",
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        "timeout": 0,
        "data":
          { "balance": balanceint, }
      })

      setShow(true)
    })
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
  }

  const componentProps = {

    ...config,
    text: 'Continue',


    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };









  useEffect(() => {
    document.title = "Fund Account | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }

    var settings = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }
    $.ajax(settings).done(function (response) {
      // setShow(true)
      console.log(response)
      document.getElementById("dfigure").innerHTML = response.balance.toLocaleString()
      document.getElementById("dstream").innerHTML = response.totalnoofstreams.toLocaleString()

    })
  }, [])


  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">





            <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
              keyboard={false}>

              <Modal.Header >
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <h5 style={{ textAlign: "center", color: "#fff" }}>Payment <span id="artist2"></span> Successful</h5>
                <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} loop autoplay></lottie-player>
                {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}

                <p style={{ textAlign: "center" }}>    <a href="/dashboard"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >CLICK TO CONTINUE</button></a></p>


              </Modal.Body>
              <Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
              </Modal.Footer>
            </Modal>



            <h4 className="webappinnerheading">Dashboard</h4>

            <Container>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />

              <Row>

                <div className='col-md-5 col-xs-9 dashboardbalance'>
                  <p className="dashboardpagetext">Balance</p>
                  <h1 className="dashboardpagefigures">&#8358;<span id="dfigure">0</span></h1>
                </div>
                <div className='col-md-1'>
                </div>
                <div className='col-md-5 col-xs-9 dashboardstreams'>
                  <p className="dashboardpagetext">Streams</p>
                  <h1 className="dashboardpagefigures"><span id="dstream">0</span></h1>
                </div>


              </Row>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />
              <p className='dashboardhelp'><i class="fas fa-question-circle"></i> Help</p>
            </Container>

            <Container >
              <Row className="settingssecondcontainer">

                <div className='col-md-2'>

                </div>

                <div className='col-md-8'>
                  <p className="fundaccounttexthead enteranamount">Please enter an amount to deposit</p>
                  <p className="fundaccounttextbody">
                    {notify1}

                  </p>



                </div>

                <div className='col-md-2'>
                </div>


                <div className='col-md-3'>

                </div>

                <div className='col-md-6'>

                  <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
                    <Form.Control type="number" id="amount" onChange={(e) => setAmount(e.target.value)} placeholder="Input an amount" size="lg" />
                  </Form.Group>
                </div>

                <div className='col-md-3'>
                </div>
                <p className='loginerrornotify'>{notify}</p>
                {/* <p   className="fundaccountbuttonp"><button className='fundaccountbutton'>Continue</button></p> */}
                <p className="fundaccountbuttonp"><PaystackButton {...componentProps} id="paystackbutton" className='fundaccountbutton' /></p>
              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>

      {/* <div id="myModal" class="modal fade" show={show} role="dialog" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
  
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <h5 style={{textAlign:"center", color:"#fff"}}>Payment <span id="artist2"></span> Successful</h5>
              <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p>

          <p style={{textAlign:"center"}}>    <a href="/artistdashboard"><button type="button" class="btn btn-lg"style={{color:"white", backgroundColor:"#FA2D48", fontSize:"10px"}}>CLICK TO CONTINUE</button></a></p>
  
  
                          </div>
       
          </div>
  
        </div>
      </div> */}



      {/* <div class="loading">
      <div class="loading-text">
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">H</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">N</span>
        <span class="loading-text-words">E</span>
        <span class="loading-text-words">W</span>
        <span class="loading-text-words"></span>
    
        <span class="loading-text-words">T</span>
        <span class="loading-text-words">R</span>
        <span class="loading-text-words">Y</span>
        <span class="loading-text-words">B</span>
        <span class="loading-text-words">E</span>
    
      </div>
    </div> */}
    </div>


  )


}


export { FundAccountPage as default }