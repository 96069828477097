import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Modal } from 'react-bootstrap'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import "../stylesheets/webapp.css"

import "../js/main.js"




const SearchPage = () => {
  const [Notify, setNotify] = useState("Follow the artist below to earn from this stream")
  const [show, setShow] = useState(false);
  const [Songs, AllSongs] = useState("");
  const [thesongid, setthesongid] = useState("");
  const [songtitle, setsongtitle] = useState("Title");
  const [song, setsong] = useState("song");
  const [artist, setartist] = useState("Artist");
  const [genre, setgenre] = useState("Genre");
  const [year, setyear] = useState("Date");
  const [art, setart] = useState("/images/footerlogo.png");
  const [role, setrole] = useState("");
  const [streamedsongs, setstreamedsongs] = useState("");
  const [artistid, setartistid] = useState("");
  const [artistinstagram, setartistinstagram] = useState("");
  const [artistinstagramid, setartistinstagramid] = useState("");
  const [artistinstagramwithouturl, setartistinstagramwithouturl] = useState("");
  const [artistfacebook, setartistfacebook] = useState("");
  const [artisttwitter, setartisttwitter] = useState("");
  const [artisttwitterwithouturl, setartisttwitterwithouturl] = useState("");
  const [artistimage, setartistimage] = useState("");
  const [hasstreamed, sethasstreamed] = useState("false");
  // const [ instagramcheck, setinstagramcheck ] = useState("false");
  const [twittercheck, settwittercheck] = useState("false");
  const [ratingscheck, setratingscheck] = useState("false");
  const [instagramcheck, setinstagramcheck] = useState("false");
  const [ratingsfigure, setratingsfigure] = useState("");


  if (role === "Artist") {
    $(".forartistonly").css({ 'display': 'block' });
    $(".nmsbuttons").css({ 'display': 'block' });
    $(".artistnotify").css({ 'display': 'block' });
    $(".forlisteneronly").css({ 'display': 'none' });
    $(".nmsbuttons2").css({ 'display': 'none' });
  }
  else {
    $(".forartistonly").css({ 'display': 'none' });
    $(".forlisteneronly").css({ 'display': 'block' });
  }



  console.log(streamedsongs)
  console.log(thesongid)

  useEffect(() => {
    for (let i = 0; i < streamedsongs.length; i++) {
      console.log(streamedsongs[i])
      if (thesongid === streamedsongs[i]) {
        console.log("its a match")
        // document.getElementById('streamedstatus').value = true
        // console.log(document.getElementById('streamedstatus').value )
        $(".alreadyearned").css({ 'display': 'block' });
        $(".qdet1").css({ 'display': 'none' });
        sethasstreamed("true")
        break;

      }
      else {
        $(".alreadyearned").css({ 'display': 'none' });
        $(".qdet1").css({ 'display': 'block' });
      }
    }
  }, [thesongid])

  // GET SELECTED ARTIST SOCIAL MEDIA HANDLE SO LISTENER CAN FOLLOW AFTER STREAM
  useEffect(() => {
    console.log(artistid)
    var settingsthree = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + artistid,
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingsthree).done(function (responsethree) {
      console.log(responsethree)
      setartistinstagram(responsethree.instagram)
      setartistfacebook(responsethree.facebook)
      setartisttwitter(`https://twitter.com/${responsethree.twitter}`)
      setartisttwitterwithouturl(responsethree.twitter)
      setartistimage(responsethree.picture)

    })
  }, [artistid])
  // GET SELECTED ARTIST SOCIAL MEDIA HANDLE SO LISTENER CAN FOLLOW AFTER STREAM


  // PLAY, PAUSE SONG AND DISPLAY FOLLOW MODAL
  var x = document.getElementById("myAudio")


  const playAudio = () => {
    console.log(hasstreamed)
    x.play();
    var intervalId = window.setInterval(function () {
      var duration = document.getElementById("myAudio").duration;
      var currenttime = x.currentTime
      var totalduration = duration / 60
      var curtime = currenttime / 60
      document.getElementById("totalduration").innerHTML = totalduration.toFixed(2)
      document.getElementById("currentduration").innerHTML = curtime.toFixed(2)

      // HANDLE SONG ENDED
      var songEnded = document.getElementById("myAudio").ended
      var usertype = document.getElementById("defaultprofiletype").value
      // var sstatus = document.getElementById('streamedstatus').value 
      console.log(songEnded)
      console.log(hasstreamed)
      console.log(role)
      if (songEnded === true && hasstreamed === "false" && role === "Listener") {
        clearInterval(intervalId)

        // $(".nmsbuttons").css({ 'display': 'none' });
        $(".alreadyearned").css({ 'display': 'block' });
        setShow(true)
        // $("#myModal").modal('show');  
      }
      else {
        // setShow(true)
      }
    })
  }

  const pauseAudio = () => {
    x.pause();
  }
  // PLAY, PAUSE SONG AND DISPLAY FOLLOW MODAL

  // TRACK SOCIAL MEDIA CLICKS
  //     const  igclick = () => {

  //       setinstagramcheck("true")
  //       $(".fa-instagram").css({ 'color': '#7BFFB3' });
  //  }



  const igclick = () => {

    setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to follow ${artistinstagramwithouturl}, Do not refresh the page`)
    const checkInterval = setInterval(function () {
      const settings = {
        "async": true,
        "crossDomain": true,
        "url": `https://instagram28.p.rapidapi.com/search_followers?user_id=${artistinstagramid}&query=${localStorage.getItem("userinstagram")}`,
        "method": "GET",
        "headers": {
          "x-rapidapi-host": "instagram28.p.rapidapi.com",
          "x-rapidapi-key": "03911959b7mshb28ccc8c49cfe4fp1eae9ejsnc5e071cf7e2a"
        },
        error: function (xhr, status, error) {
          console.log(xhr)
        },
      };

      $.ajax(settings).done(function (response) {
        console.log(response.users);
        console.log(response.users.length);
        if (response.users.length === 0) {
          setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to follow ${artistinstagramwithouturl}, <br/> Do not refresh the page`)
          console.log("User has not followed")
        }
        else {
          setNotify(` ${localStorage.getItem("userinstagram")} has followed ${artistinstagramwithouturl}. Rate the song below to continue`)
          $('.followtwitter').css("display", "none");
          setinstagramcheck("true")
          clearInterval(checkInterval);

        }
      });
    }, 5000);
  }


  const ratings5click = () => {
    console.log("5 rating")
    console.log(document.getElementById("star5").value)
    setratingscheck("true")
    setratingsfigure(5)
  }

  const ratings4click = () => {
    console.log("4 rating")
    console.log(document.getElementById("star4").value)
    setratingscheck("true")
    setratingsfigure(4)
  }

  const ratings3click = () => {
    console.log("3 rating")
    console.log(document.getElementById("star3").value)
    setratingscheck("true")
    setratingsfigure(3)
  }

  const ratings2click = () => {
    console.log("2 rating")
    console.log(document.getElementById("star2").value)
    setratingscheck("true")
    setratingsfigure(2)
  }

  const ratings1click = () => {
    console.log("1 rating")
    console.log(document.getElementById("star1").value)
    setratingscheck("true")
    setratingsfigure(1)
  }


  // TRACK SOCIAL MEDIA CLICKS


  // SUBMIT 
  const submitFunction = () => {

    // var ig = instagramcheck
    // var tw = twittercheck
    var ig = "true"
    var ratings = ratingscheck
    // var ratingsfigure = ratingsfigure

    console.log(ratings)
    // console.log(tw)
    // console.log(ig)



    if (ig !== "true") {
      setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to follow ${artistinstagramwithouturl}, Do not refresh the page`)

    }

    else if (ratings !== "true") {
      setNotify(`Waiting for ${localStorage.getItem("userinstagram")} to rate the song`)

    }

    else {
      console.log("hi")
      setNotify("Processing...")





      // ADD N8 TO LISTENER USER ID, ADD STREAMED SONG ID TO USER ID TO INDICATE STREAMED SONGS

      var settings = {
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
        "method": "GET",
        "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
        "timeout": 0,
      }

      $.ajax(settings).done(function (response) {
        var earning = 10
        var earningint = earning + response.earnings
        var settingsearnings = {
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
          "method": "PATCH",
          "timeout": 0,
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          "data": {
            "earnings": earningint,
            "streamedsongs": thesongid,




            "picture": localStorage.getItem("picture"),
            "bankname": localStorage.getItem("bank"),
            "phone": localStorage.getItem("loggedinuserphone"),
            "onboarded": true,



            "instagram": localStorage.getItem("userinstagram"),
            "twitter": localStorage.getItem("usertwitter"),
            "about": localStorage.getItem("about"),
            "instagramid": localStorage.getItem("instagramid"),
            "about": localStorage.getItem("about"),
            "stagename": localStorage.getItem("stagename"),

          }
        }
        $.ajax(settingsearnings).done(function (response) {
          console.log(response)
        })
        // ADD N8 TO USER ID, ADD STREAMED SONG ID TO USER ID TO INDICATE STREAMED SONGS


        // ADD 1 EXTRA STREAM TO NO OF STREAMS ON SONG ID
        var updatenoofstreams = {
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + thesongid,
          "method": "GET",
          "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
          "timeout": 0,
        }
        $.ajax(updatenoofstreams).done(function (response) {
          console.log(response)
          var noofstreams = 1
          var noofstreamsint = noofstreams + response.noofstreams
          console.log(noofstreamsint)
          var audience = localStorage.getItem("userid")
          var updatenoofstreams2 = {
            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + thesongid,
            "method": "PATCH",
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "timeout": 0,
            "data": { "noofstreams": noofstreamsint, "audience": audience }
          }

          $.ajax(updatenoofstreams2).done(function (response) {
            console.log(response)
          })

          // ADD 1 EXTRA STREAM TO NO OF STREAMS ON SONG ID

          // DEDUCT N8 FROM STREAMED ARTISTS BALANCE
          var settingsdeduct = {
            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + response.user_id,
            "method": "GET",
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "timeout": 0,
          }
          $.ajax(settingsdeduct).done(function (response) {
            var deduct = 20
            var balance = response.balance
            var newbalance = balance - deduct

            var add = 1
            var tnos = response.totalnoofstreams
            var tnos2 = tnos + add
            var settingsdeduct2 = {
              "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user/" + response._id,
              "method": "PATCH",
              "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
              "timeout": 0,
              "data":
              {
                "balance": newbalance,
                "totalnoofstreams": tnos2
              }
            }
            $.ajax(settingsdeduct2).done(function (response) {
              console.log(response)
              document.getElementById("follownotify").innerHTML = "Success!!"
              window.location.replace("/newmusic");
            })
          })

        })
        // DEDUCT N8 FROM STREAMED ARTISTS BALANCE



      })
    }




  }




  // SUBMIT 

  // SEARCH SUGGESTIONS
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    console.log(string, results)
  }

  const handleOnHover = (result) => {
    // the item hovered
    console.log(result)
  }

  const handleOnSelect = (item) => {
    // window.location.replace(/stream?songid=6180d90c7f20f00016612152)
    window.location.assign(`/stream?songid=${item._id}`)

    $('.loading').css("visibility", "visible");
    $('#searchresult').css("display", "block");
    // $('#searchresult').fadeIn();
    console.log(item)
    setthesongid(item._id)
    setsongtitle(item.name)
    setartist(item.artist_name)
    setgenre(item.genre)
    setyear(item.createdAt)
    setart(item.songart)
    setartistid(item.user_id)
    setsong(item.songlink)



    function pageloader() {
      $('.loading').css("visibility", "hidden");
    }
    setTimeout(pageloader, 3000);


    // $('#searchresult').css("display", "block");
    // $('#searchresult').fadeIn(2000);


  }

  const handleOnFocus = () => {
    console.log('Focused')
  }

  const formatResult = (item) => {
    return item
    // return (<p dangerouslySetInnerHTML={{__html: '<strong>'+item+'</strong>'}}></p>); //To format result as html
  }
  // SEARCH SUGGESTIONS


  useEffect(() => {
    document.title = "Search | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    console.log(usertoken)
    if (usertoken === null) { window.location.replace("/login"); }

    // GET LOGGED IN USER ROLE AND STREAMED SONGS

    var settings = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }
    $.ajax(settings).done(function (response) {
      // setShow(true)
      var allstreamedsongs = response.streamedsongs
      setrole(response.user_role)
      setstreamedsongs(response.streamedsongs)

      // for (let i = 0; i < allstreamedsongs.length; i++) {
      //   console.log(allstreamedsongs[i])
      //   console.log(thesongid)
      //   if (thesongid === allstreamedsongs[i]) {
      //     console.log("its a match")
      //     document.getElementById('streamedstatus').value = true
      //     console.log(document.getElementById('streamedstatus').value )
      //     $(".alreadyearned").css({ 'display': 'block' });

      //     break;

      //   }}

    })
    // GET LOGGED IN USER ROLE AND STREAMED SONGS

    // GET ALL SONGS TO FILTER THROUGH 
    var settingstwo = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/allsongs",
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingstwo).done(function (responsetwo) {
      console.log(responsetwo)
      AllSongs(responsetwo)
    })
    // GET ALL SONGS TO FILTER THROUGH 





  }, [])

  // },[thesongid, songtitle, artist, genre, year, streamedsongs])


  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <div class="input-group mb-3">

              <div class="searchbox" >
                <ReactSearchAutocomplete
                  styling={{
                    backgroundColor: "black",
                    color: "#FFF",
                    hoverBackgroundColor: "#949494",
                  }}

                  size="lg"
                  placeholder="Search"
                  items={Songs}
                  fuseOptions={{ keys: ["name", "description"] }}
                  onSearch={handleOnSearch}
                  onHover={handleOnHover}
                  onSelect={handleOnSelect}
                  onFocus={handleOnFocus}
                  autoFocus
                  formatResult={formatResult}


                />
              </div>
            </div>
            <hr className="searchhr" />


            {/* <div class="row" > */}
            <Container id="searchresult">
              <Row >
                <div class="col-md-3">
                  <p class="newmusicsingleboximgp"><img className="searchsongart" id="songart"
                    src={art} /></p>
                </div>

                <input type="hidden" id="loggedinuserid" value="" />
                <input type="hidden" id="streamedstatus" value="" />

                <div class="col-md-9">
                  <div class="">
                    <h4 class="nmsartistalbum" id="songtitle">{songtitle}</h4>
                    <h4 class="nmsartistname" id="artist">{artist}</h4>
                    <p class="nmsartistyear">
                      <span id="genre">{genre}</span> - <span id="year">{year}</span>
                    </p>
                    <p style={{ marginBottom: "0px" }} class="qdet1">Note: Earnings are recorded at the end of the song.</p>
                    <p class="alreadyearned">Note: You have already streamed this song, You will not earn from this stream </p>
                    <p style={{ color: "#FFF" }} class="forartistonly">Note: You are logged in as an artist, You will not earn on this stream.</p>



                    <input type="hidden" value="" id="userid" />
                    {/* <hr/> */}

                    <div>
                      <audio style={{ width: "500px", borderRadius: "0px!important" }} src={song} id="myAudio"></audio>
                      <p class="">

                        <button className="controlbuttons1" onClick={playAudio} type="button"><i
                          class="fas fa-play "></i>
                        </button>

                        <button className="controlbuttons" onClick={pauseAudio} type="button"><i
                          class="fas fa-pause "></i></button>

                        <button className="controlbuttons" onclick="" type="button"><a id="myAudioLink" href=""
                          target="_blank"><i class="fas fa-download "></i></a></button>

                        <button className="controlbuttons2"


                          onclick="copyFunction()" type="button">Copy Song Link</button>
                        <p class="nmsbuttons mobilecentercount"><span id="currentduration">0:00</span> : <span id="totalduration">0:00</span></p>
                      </p>




                    </div>


                  </div>
                </div>
              </Row>
            </Container>
            {/* </div> */}

            {/* <h4 className="webappinnerheading">Browse</h4>

  <Row>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
    <div className="col-md-3 searchpageboxes">
      <img src="/images/webapp/searchcategory.png"/>
    </div>
  </Row> */}


          </div>
        </div>

      </div>

      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Almost There</h5>




          <div className="hidediv">

            {/* <p className="requestnotify2">{Notify}</p> */}
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
            {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}

            {/* <p style={{textAlign:"center"}}> <button  onClick={requestPayout2 } type="button" class="btn btn-lg" className='fundaccountbutton2' >Request Payout</button></p> */}
          </div>
          <div className=""  >
            {/* <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json"  background="transparent"  speed="1"  style={{width: "300px", height: "300px"}}  loop autoplay></lottie-player> */}
            <h5 style={{ textAlign: "center", color: "#696969" }}> Follow {artist} on Social Media </h5>
            <p style={{ textAlign: "center", marginTop: "30px" }}><img class="clientimg" style={{ borderRadius: "50%" }} width="150" height="150" id="followimg"
              src={artistimage} /></p>


            <p style={{ textAlign: "center", marginTop: "30px" }} id="follownotify">{Notify}</p>

            <p style={{ textAlign: "center", paddingTop: "20px" }}>
              {/* 
            <a onClick={igclick} id="followig" target="_blank" href={artistinstagram}> <i style={{fontSize:"28px", color:"#fff "}} 
                class="fab fa-instagram"></i></a> */}



              <a onClick={igclick} id="followtwitter" target="_blank" href={artisttwitter}>
                <i style={{ fontSize: "28px", marginLeft: "0px", color: "#fff " }} class="fab fa-instagram"></i></a>

              <input type="hidden" id="instagramcheck" value={instagramcheck} />
              {/* <input type="hidden" id="twittercheck" value={twittercheck} /> */}
              <input type="hidden" id="ratingscheck" value={ratingscheck} />

            </p>
            <div class="row">
              <div class="col-md-2">

              </div>

              <div class="col-md-6">
                <fieldset class="rating" >
                  <input type="radio" id="star5" name="rating" value="5" onClick={ratings5click} /><label id="ratingfigure" class="full" for="star5" title=" 5 stars"></label>
                  {/* <input type="radio" id="star4half" name="rating" value="4 and a half" /><label class="half" for="star4half" title="Pretty good - 4.5 stars"></label> */}
                  <input type="radio" id="star4" name="rating" value="4" /><label id="ratingfigure" onClick={ratings4click} class="full" for="star4" title=" 4 stars"></label>
                  {/* <input type="radio" id="star3half" name="rating" value="3 and a half" /><label class="half" for="star3half" title="Meh - 3.5 stars"></label> */}
                  <input type="radio" id="star3" name="rating" value="3" /><label id="ratingfigure" onClick={ratings3click} class="full" for="star3" title="3 stars"></label>
                  {/* <input type="radio" id="star2half" name="rating" value="2 and a half" /><label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label> */}
                  <input type="radio" id="star2" name="rating" value="2" /><label id="ratingfigure" onClick={ratings2click} class="full" for="star2" title=" 2 stars"></label>
                  {/* <input type="radio" id="star1half" name="rating" value="1 and a half" /><label class="half" for="star1half" title="Meh - 1.5 stars"></label> */}
                  <input type="radio" id="star1" name="rating" value="1" /><label id="ratingfigure" onClick={ratings1click} class="full" for="star1" title=" 1 star"></label>
                  {/* <input type="radio" id="starhalf" name="rating" value="half" /><label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label> */}

                </fieldset>
              </div>

              <div class="col-md-3">

              </div>
            </div>


            {/* <p style={{textAlign:"center"}}> <button onclick="submitFunction()" type="button" class="btn btn-lg"
              style="color:white; background-color:#FA2D48; font-size:10px">SUBMIT</button></p> */}


            <p style={{ textAlign: "center" }}><button onClick={submitFunction} type="button" class="btn btn-lg" className='fundaccountbutton2' >Continue</button></p>

          </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>




      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { SearchPage as default }