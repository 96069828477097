import React, { useEffect, useState, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const AccountPage = () => {

  const [name] = useState(localStorage.getItem("userfullname"));
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();
  const [url, setUrl] = useState("");
  const [notify, setNotify] = useState("")


  // const changeAvatar = () =>{

  useEffect(() => {
    const data = new FormData()
    data.append("file", image2)
    data.append("upload_preset", "e3e7uyua")
    data.append("cloud_name", "dlrsrdekh")
    fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload", {
      method: "post",
      body: data
    })
      .then(resp => resp.json())
      .then(data => {
        setUrl(data.url)
        console.log(data)
        if (data.error) {
          document.getElementById("imageselectorid").src = "/images/usericon2.png"
          // setNotify("Uploading")
        }
        else {
          console.log(data.url)
          document.getElementById("imageselectorid").src = data.url
          // localStorage.setItem("songart", data.secure_url);
          var settingsupdate = {
            beforeSend: function () {
              $('.loading').css("visibility", "visible");
            },

            "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user/avatar",
            "method": "PATCH",
            "timeout": 0,
            "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
            "data": {

              "picture": data.url,

            },

            complete: function () {
              $('.loading').css("visibility", "hidden");
            }
          }

          $.ajax(settingsupdate).done(function (response) {
            console.log(response)
            // window.location.replace("/personalinfo");
          })
        }
      })
      .catch(err => console.log(err))

  }, [image2])




  // }

  useEffect(() => {
    document.title = "Account | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },

      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + usertoken },
      "timeout": 0,
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response.user_role)
      if (response.user_role === "Artist") {
        $(".forlisteneronly").css({ 'display': 'none' });
      }
      else {
        $(".forlisteneronly").css({ 'display': 'block' });

      }

      if (response.picture === "") {
        setImage("/images/usericon2.png")
      }
      else {
        setImage(response.picture)

      }




    })
  }, [])

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Account</h4>
            <div style={{ marginTop: "30px" }} class="formobileonly">

            </div>


            <Container>
              <Row>
                <div className='col-md-2 col-xs-2 col-4'>
                  <img id="imageselectorid" className="settingsdp" src={image} />
                </div>

                <div className='col-md-10 col-xs-9 col-7'>
                  <p className="settingsusertype"></p>
                  <p id="profilenamesettings">{name}</p>
                  <label for="file" class="btn avatarbutton" style={{ color: "#FFF" }}>Select Image</label>
                  <input style={{ visibility: "hidden", width: "100px" }} type="file" className="" id="file" onChange={(e) => setImage2(e.target.files[0])}></input>
                  <p style={{ marginTop: "0px", color: "#FFF" }} className=''>{notify}</p>




                </div>
                <hr className="accountpagehr" />
              </Row>
            </Container>




            <Container >
              <Row className="settingssecondcontainer">
                <div className='col-md-12'>

                  <a href='/personalinfo'>
                    <p>
                      <img src="/images/webapp/susericon.png" />
                      <span className="settingspersonalinfo" >Personal Information</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </a>

                  <a href='/bank' className="forlisteneronly" >
                    <p className="settingsacc ">
                      <img src="/images/webapp/sbankicon.png" />
                      <span className="settingspersonalinfo" >Account Details</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </a>

                  <a href='/verify' className="forlisteneronly" >
                    <p className="settingsacc ">
                      <img src="/images/webapp/sbankicon.png" />
                      <span className="settingspersonalinfo" >Verification</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </a>

                  {/* <Nav.Link as={Link}   to='/password'>
       <p className="settingsacc">
           <img src="/images/webapp/spasswordicon.png"/>
       <span className="settingspersonalinfo" >Change Password</span>
           <span style={{float: "right", marginTop:"15px"}}>  <img src="/images/rightarrow.png"/></span>
       </p>
</Nav.Link> */}
                  <a href='/login'>
                    <p className="settingsacc">
                      <img src="/images/webapp/slogouticon.png" />
                      <span className="settingspersonalinfo" >Logout</span>
                      <span style={{ float: "right", marginTop: "15px" }}>  <img src="/images/rightarrow.png" /></span>
                    </p>
                  </a>

                </div>

              </Row>
            </Container>


          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { AccountPage as default }