

import React, { useEffect, useReducer, useState } from 'react';
import { Container, Row, Form, Button, Nav } from 'react-bootstrap'
import $ from 'jquery';
import { Link } from 'react-router-dom';

import Header from './Header';
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';
import "../js/main.js"


import "../stylesheets/website.css"
const RegisterPage = () => {

  // const navigate = useHistory();
  const [firstname, setFirstName] = useState("")
  const [lastname, setLastName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [stagename, setStageName] = useState("")
  const [password, setPassword] = useState("")
  const [usertype, setUserType] = useState("")
  const [notify, setNotify] = useState("")
  const [year] = useState(new Date().getFullYear())
  useEffect(() => {
    document.title = "Sign Up | The New Trybe"
  }, [])

  console.log(usertype)
  if (usertype === "") {
    $('.stagenamehpinput').css("display", "none");
  }

  else if (usertype === "Listener") {
    $('.stagenamehpinput').css("display", "none");
  }

  else {
    $('.stagenamehpinput').css("display", "block");

  }

  const userRegister = (e) => {
    e.preventDefault()
    console.log(email)
    if (firstname === "") {
      setNotify('First name cannot be empty.')
    }

    else if (lastname === "") {
      setNotify('Last name cannot be empty.')
    }

    // else if (phone === ""){
    //   setNotify('Phone number cannot be empty.')
    // }

    else if (email === "") {
      setNotify('E-mail cannot be empty.')
    }

    else if (password === "") {
      setNotify('Password cannot be empty.')
    }

    else if (usertype === "") {
      setNotify('Select a User Type.')
    }

    else if (usertype === "Artist" && stagename === "") {
      setNotify('Stagename cannot be empty.')
    }

    else if (usertype === "Select User Type") {
      setNotify('Select a User Type.')
    }




    else {

      if (usertype === "Artist") {
        const obj = {
          "members": [{
            "email_address": email,
            "status": "subscribed",
            "merge_fields": {
              "FNAME": firstname,
              "LNAME": lastname,
              "PHONE": phone
            }
          }]
        }

        var settingsmailchimp = {
          "url": "https://vast-bastion-98389.herokuapp.com/https://us6.api.mailchimp.com/3.0/lists/17bce5da12/",
          "method": "POST",
          "timeout": 0,
          "headers": { "Authorization": "Bearer 66f3997fd084d2f027200fce5e93113a-us6" },
          "data": JSON.stringify(obj),
          error: function (xhr, status, error) {
            console.log(xhr)
          },

        }

        $.ajax(settingsmailchimp).done(function (responsemailchimp) {
          console.log(settingsmailchimp)
        })

      }


      else if (usertype === "Listener") {
        const obj = {
          "members": [{
            "email_address": email,
            "status": "subscribed",
            "merge_fields": {
              "FNAME": firstname,
              "LNAME": lastname,
              "PHONE": phone
            }
          }]
        }

        var settingsmailchimp = {
          "url": "https://vast-bastion-98389.herokuapp.com/https://us6.api.mailchimp.com/3.0/lists/3296d07bf3/",
          "method": "POST",
          "timeout": 0,
          "headers": { "Authorization": "Bearer 66f3997fd084d2f027200fce5e93113a-us6" },
          "data": JSON.stringify(obj),
          error: function (xhr, status, error) {
            console.log(xhr)
          },

        }

        $.ajax(settingsmailchimp).done(function (responsemailchimp) {
          console.log(settingsmailchimp)
        })

      }

      var settings = {
        beforeSend: function () {
          setNotify('Signing Up...')
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/signup",
        "method": "POST",
        "timeout": 0,
        error: function (xhr, status, error) {
          console.log(xhr)
          if (xhr.responseJSON.message === "User validation failed: email: Email is invalid") {
            console.log()
            setNotify("Email is invalid")
          }
          //   else if(xhr.responseJSON.message ==="User validation failed: password: Path `password` (`sasa`) is shorter than the minimum allowed length (8).") {
          //     console.log()
          //     setNotify("Password should have more than 8 characters")
          // }


          else if (xhr.status == 403) {
            console.log(xhr.responseJSON.error)
            setNotify(xhr.responseJSON.error)
          }

        },
        "data": {
          "firstname": firstname,
          "lastname": lastname,
          "email": email,
          "password": password,
          "user_role": usertype,
          "stagename": stagename,
          "phone": phone,
        },

        complete: function () {
          // setNotify('Redirecting...')
        }
      }

      $.ajax(settings).done(function (response) {
        console.log(response)

        window.location.href = '/emailverification';
      })
    }


    // dispatch.push('/')

  }


  return (
    <div id="registerbody">
      <Header />
      <Container className='page'>
        {/* <Header/> */}
        <Row className="">
          <div className="col-md-12">
            <h1 className='signuptitle'>Sign Up</h1>

          </div>


        </Row>
        <Row className="">
          <div className="col-md-3">
          </div>
          <div className="col-md-6">
            <p className='signupnotify'>Create an account now. </p>


          </div>
          <div className="col-md-3">
          </div>


          <div className="col-md-4">
          </div>
          <div className="col-md-4">
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Control type="text" onChange={(e) => setFirstName(e.target.value)} placeholder="Input your firstname" size="lg" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Control type="text" onChange={(e) => setLastName(e.target.value)} placeholder="Input your lastname" size="lg" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Control type="number" onChange={(e) => setPhone(e.target.value)} placeholder="Phone" size="lg" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicEmail" >
                <Form.Control type="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" size="lg" />
              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="formBasicPassword">
  
  <Form.Control type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Password" size="lg" />
</Form.Group> */}


              <div class="input-group" id="show_hide_password">
                <Form.Control id="password" type="password" className="form-control passwordformcontrol" placeholder=" Password" onChange={(e) => setPassword(e.target.value)} size="lg" required />

                <div class="input-group-addon">
                  <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                </div>
              </div>


              <Form.Select size="lg" onChange={(e) => setUserType(e.target.value)} className="form-control signupusertype usertypeformcontrol">
                <option>Select User Type</option>
                <option>Artist</option>
                <option>Listener</option>
              </Form.Select>

              <Form.Group className="signupusertype usertypeformcontrol stagenamehpinput" controlId="formBasicEmail" >
                <Form.Control type="email" onChange={(e) => setStageName(e.target.value)} placeholder="Stage Name" size="lg" />
              </Form.Group>
              <p style={{ color: "#FFF", fontSize: "12px", paddingTop: "10px" }}>NB for Listeners: Kindly ensure the firstname & lastname provided matches that on your NIN to make payout seamless.</p>




              <p className='loginerrornotify'>{notify}</p>
              <div className="d-grid gap-2">
                <Button onClick={userRegister} className="loginbutton" type="submit">
                  Continue
                </Button>
              </div>



            </Form>

            <p className='loginnoaccountnotify'><Nav.Link as={Link} to="/login" ><span style={{ color: "#FFF" }}>Have an account?</span> <span className="loginmakebold">SIGN IN</span> </Nav.Link></p>
            {/* <p className='copyrighttext'>Copyright  &copy; <span>{year}</span> The New Trybe.</p>   */}
          </div>
          <div className="col-md-4">
          </div>




          <br />

        </Row>
        {/* <Footer/> */}
      </Container>
    </div>
  )
}


export { RegisterPage as default }