import React, { useEffect, useReducer } from 'react';
import $ from 'jquery';
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';

// import Navbar from './Navbar';
// import Header from './Header';

// import "../stylesheets/style.css"
// import "../stylesheets/registration.css"

import "../stylesheets/webapp.css"
// import "../stylesheets/nav.css"
// import "../stylesheets/main.css"
// import "../stylesheets/home.css"
// import "../stylesheets/simple-sidebar.css"
import "../js/main.js"




const SongStatPage = () => {



  useEffect(() => {
    document.title = "Statistics | Artist | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)

    var pathname1 = window.location.href;
    console.log(pathname1)
    let params1 = (new URL(pathname1)).searchParams;

    var songid1 = params1.get('songid') // "n1"



    var settingstwo = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + songid1,
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + usertoken },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }


    $.ajax(settingstwo).done(function (response) {
      console.log(response)
      document.getElementById("tnost").innerHTML = response.audience.length
      document.getElementById("tnos").innerHTML = response.name

      var audiencearray = response.audience
      let text = "";
      for (let i = 0; i < audiencearray.length; i++) {
        //   text += audiencearray[i] + "<br>"



        var settingsdeduct = {
          beforeSend: function () {
            $('.loading').css("visibility", "visible");
          },
          "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/allusers/" + audiencearray[i],
          "method": "GET",
          "headers": { "Authorization": "Bearer " + usertoken },
          "timeout": 0,
          complete: function () {
            $('.loading').css("visibility", "hidden");
          }
        }

        $.ajax(settingsdeduct).done(function (response) {
          console.log(response)

          $('<tr onclick="myFunction2(this, r)">').html(
            "<td> " + "" + "</td><td>"
            + response.firstname + "</td><td>"
            + response.lastname + "</td><td>"
            // + response.facebook + "</td>"
            // + response.instagram + "</td>"
            + response.twitter + "</td>"
            //  + actualdate + "</td><td>"
            //       + feature + "</td><td>"
            //         + response[i].genre + "</td><td>"
            //             + response[i].producer+ "</td><td>"
            //                 +" <a target='_blank' href='/artist/streams?songid="+ response[i]._id +"'>" + "View "+"</a>"
            // + response[i].balance_currency + "</td><td>"

            //    + "<button class='btn btn-danger btn-sm' data-toggle='modal' data-target='#receiptModal'  id='appbtns' class='btn btn-sm downloadreceiptbutton'> View </button>" + "  "  

            +

            "</td>"

          ).appendTo('#records_table');
        })


      }



    })


  })






  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper2">

          <Topbar />

          <div className="webappinnercontent">

            <h4 className="webappinnerheading">Dashboard - View Streams</h4>

            <Container>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />

              <Row>

                <div className='col-md-5 col-xs-9 dashboardbalance'>
                  <p className="dashboardpagetext">Song</p>
                  <h1 className="dashboardpagefigures"><span id="tnos">0</span></h1>
                </div>
                <div className='col-md-1'>
                </div>
                <div className='col-md-5 col-xs-9 dashboardstreams'>
                  <p className="dashboardpagetext">Total No of Streams</p>
                  <h1 className="dashboardpagefigures"><span id="tnost">0</span></h1>
                </div>

                <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />
              </Row>
              {/* <p className='dashboardhelp'><i class="fas fa-question-circle"></i> Help</p> */}
            </Container>

            <Container >
              <Row className="settingssecondcontainer">




                <div className='col-md-12'>
                  <p className="uploadsonghead">All streams your song(s) generated</p>
                  <table class="table  css-serial">
                    <thead class="thead">
                      <tr class="ippisschedulehead">
                        <th class="ippiscol0" scope="col">S/N</th>
                        <th class="ippiscol0" scope="col">Firstname</th>
                        <th class="ippiscol0" scope="col">Lastname</th>

                        <th class="ippiscol0" scope="col">Twitter</th>


                      </tr>
                    </thead>

                    <tbody id="records_table">
                    </tbody>
                  </table>




                </div>


              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>





      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { SongStatPage as default }