import React, { useEffect, useReducer, useState } from 'react';
import $ from 'jquery';
import "ajax"
import Topbar from '../webappcomponents/topBar'
import NewNavbar from './newNavbar';
import { Container, Row, Button, Nav, Form, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import "../stylesheets/webapp.css"

import "../js/main.js"



const EditPage = () => {

  const [image, setImage] = useState("/images/musicart.png");
  // const [newimage, setImage ] = useState("/images/musicart.png");

  const [url, setUrl] = useState("");
  const [thesongid, setthesongid] = useState("")
  const [songtitle, setsongtitle] = useState("");
  const [note, setnote] = useState("");
  // const [ artistid, setartistid ] = useState("");
  const [song, setSong] = useState("song");
  // const [ ImageSelector, setImageSelector ] = useState("/images/musicart.png");

  // const [song, setSong ] = useState("");
  const [urlsong, setUrlSong] = useState("");
  const [notify, setNotify] = useState("")
  const [show, setShow] = useState(false);



  useEffect(() => {
    $('.loading').css("visibility", "visible");
    const data = new FormData()
    data.append("file", image)
    data.append("upload_preset", "e3e7uyua")
    data.append("cloud_name", "dlrsrdekh")
    fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload", {
      // beforeSend: function(){
      //   $('.loading').css("visibility", "visible");
      //   },
      method: "post",
      body: data
    })
      .then(resp => resp.json())
      .then(data => {
        setUrl(data.url)
        console.log(data)
        if (data.error) {
          document.getElementById("imageselectorid").src = "/images/musicart.png"
          $('.loading').css("visibility", "hidden");
        }
        else {
          document.getElementById("imageselectorid").src = data.url
          localStorage.setItem("songart", data.secure_url);
          $('.loading').css("visibility", "hidden");
        }


      })
      .catch(err => console.log(err))



  }, [image])

  useEffect(() => {
    //   setNotify('Uploading')
    const data = new FormData()
    data.append("file", song)
    data.append("upload_preset", "e3e7uyua")
    data.append("cloud_name", "dlrsrdekh")
    fetch("  https://api.cloudinary.com/v1_1/dlrsrdekh/upload", {
      method: "post",
      body: data
    })
      .then(resp => resp.json())
      .then(data => {
        //   setUrlSong(data.url)
        console.log(data)
        if (data.error) {
          // setNotify('Something went wrong, Please try again')
          // document.getElementById("imageselectorid").src = "/images/musicart.png"
        }
        else {
          setNotify('Uploaded successfully, click continue below.')
          localStorage.setItem("songlink", data.secure_url);

        }
      })
  }, [song])


  useEffect(() => {
    var pathname = window.location.href;
    console.log(pathname)
    let params = (new URL(pathname)).searchParams;
    var songid = params.get('songid') // "n1"
    // setthesongid(songid)
    var settingstwo = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/" + songid,
      "method": "GET",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settingstwo).done(function (responsetwo) {
      if (responsetwo.admin_note === "Awaiting Review") {
        $('.editpicture').css("display", "block");
        $('.editsong').css("display", "block");
        $('.artandsongsubmitbutton').css("display", "block");
      }

      if (responsetwo.admin_note === "Approved") {
        $('.editpicture').css("display", "none");
        $('.editsong').css("display", "none");
      }


      else if (responsetwo.admin_note === "Kindly upload a song art designed by a graphics designer.") {
        $('.editpicture').css("display", "block");
        $('.artandsongsubmitbutton').css("display", "none");
        $('.songsubmitbutton').css("display", "none");
        $('.artsubmitbutton').css("display", "block");

      }

      else if (responsetwo.admin_note === "Kindly upload a song art designed by a graphics designer, and replace your song with one recorded and mastered by a producer.") {
        $('.editpicture').css("display", "block");
        $('.editsong').css("display", "block");

        $('.artandsongsubmitbutton').css("display", "none");
        $('.songsubmitbutton').css("display", "none");
        $('.artsubmitbutton').css("display", "none");
      }

      else if (responsetwo.admin_note === "Kindly upload a song recorded and mastered by a producer.") {
        $('.editsong').css("display", "block");
        $('.artandsongsubmitbutton').css("display", "none");
        $('.songsubmitbutton').css("display", "block");
        $('.artsubmitbutton').css("display", "none");
      }


      // console.log(responsetwo)

      setthesongid(responsetwo._id)
      setsongtitle(responsetwo.name)
      //  setImage(responsetwo.songart)
      setnote(responsetwo.admin_note)
      //  setartistid(responsetwo.user_id)
      //  setSong(responsetwo.songlink)
    })
  }, [])







  const artsubmit = () => {
    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/edit/" + thesongid,
      "method": "PATCH",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "data": {
        "songart": localStorage.getItem("songart"),
        // "songlink":localStorage.getItem("song"),            
      },

      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      setNotify('Updated Successfully')
      setShow(true)
      // window.location.replace("/artistdashboard");

    })
  }

  const songsubmit = () => {
    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/edit/" + thesongid,
      "method": "PATCH",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "data": {
        //   "songart":localStorage.getItem("songart"),
        "songlink": localStorage.getItem("songlink"),
      },

      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      setNotify('Updated Successfully')
      setShow(true)
      // window.location.replace("/artistdashboard");

    })
  }

  const artandsongsubmit = () => {
    var settings = {
      beforeSend: function () {
        $('.loading').css("visibility", "visible");
      },
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/single/edit/" + thesongid,
      "method": "PATCH",
      "timeout": 0,
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "data": {
        "songart": localStorage.getItem("songart"),
        "songlink": localStorage.getItem("songlink"),
      },

      complete: function () {
        $('.loading').css("visibility", "hidden");
      }
    }

    $.ajax(settings).done(function (response) {
      console.log(response)
      setNotify('Updated Successfully')
      setShow(true)
      // window.location.replace("/artistdashboard");

    })
  }








  // else{
  //   setNotify('Next Step')
  // }





  useEffect(() => {
    document.title = "Edit Song | Artist | The New Trybe"
    var usertoken = localStorage.getItem("access_token")
    if (usertoken === null) { window.location.replace("/login"); }
    console.log(usertoken)



    var settings = {
      "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/user",
      "method": "GET",
      "headers": { "Authorization": "Bearer " + localStorage.getItem("access_token") },
      "timeout": 0,
    }
    $.ajax(settings).done(function (response) {
      // setShow(true)
      // $("#myModal").modal('show');
      //   console.log(response)
      //   document.getElementById("dfigure").innerHTML = response.balance.toLocaleString()
      //   document.getElementById("dstream").innerHTML = response.totalnoofstreams.toLocaleString()
      //   document.getElementById("stagename").value = response.stagename
    })
  })

  return (

    <div className='d-flex'>


      <div class="webappnavbarwrapper">
        <NewNavbar />



        <div id="webappcontent" id="page-content-wrapper">

          <Topbar />

          <div className="webappinnercontent">


            <Container>
              <hr style={{ borderTop: "1px solid #FFF", marginTop: "30px" }} />
              <h4 className="webappinnerheading">Edit: {songtitle}</h4>


              {/* <hr style={{borderTop: "1px solid #FFF", marginTop: "30px"}}/> */}

            </Container>

            <Container >
              <Row className="settingssecondcontainer">

                {/* <div className='col-md-4'>

    </div>

    <div className='col-md-4'>


     
    </div>

    <div className='col-md-4'>
    </div> */}


                <div className='col-md-12 editpicture'>
                  <p style={{ color: "#FA2D48" }} className="uploadsonghead2">Admin Note: {note}</p>

                  <p className="uploadsonghead2">Click on the image below to replace it.</p>

                  <div class="image-upload">
                    <label for="file">
                      <img class="img-fluid" style={{ marginBottom: "40px", cursor: "pointer " }} width="489" height="371" id="imageselectorid" src={image} />
                    </label>

                    {/* <input id="file" type="file" /> */}
                    <input type="file" id="file" onChange={(e) => setImage(e.target.files[0])}></input>
                  </div>
                  <p style={{ marginTop: "0px " }} className="artsubmitbutton"><button onClick={artsubmit} className='fundaccountbutton'>Continue</button></p>

                </div>

                <div className='col-md-12 editsong'>
                  <p className="uploadsonghead">Replace song below.</p>

                  {/* <p className="uploadsonghead">Input details about song here</p> */}

                  {/* <div class="image-upload">
    <label for="file-input">
    <img src="https://icons.iconarchive.com/icons/dtafalonso/android-lollipop/128/Downloads-icon.png"/>
  </label>
    <input type="file" onChange= {(e)=> setImage(e.target.files[0])}></input>
    </div> */}


                  {/* <img src="/images/musicart.png"/> */}

                  {/* <input id="file" type="file" onChange= {(e)=> setImage(e.target.files[0])} />
<button onClick={uploadImage}>Upload</button> */}


                  {/* <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="" placeholder="Input Artist Name"  id="stagename"   size="lg" disabled/>
</Form.Group>


<Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="" placeholder="Input Song Title" id="songname" size="lg" />
</Form.Group>

<Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="feature" placeholder="Featuring (Leave empty if theres no feature)" size="lg" />
</Form.Group>

<Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control type="email"id="producer" placeholder="Producer (s)" size="lg" />
</Form.Group>

<Form.Group className="mb-3 loginemailinput" >
<Form.Select size="lg" size="lg" id="genre" className="form-control signupusertype usertypeformcontrol">
<option value="">- Select Genre -</option>
                <option value="Afropop">Afropop</option>
                <option value="Afrobeat">Afrobeat</option>
                <option value="Alte">Alte</option>
                <option value="Blues">Blues</option>
                <option value="Gospel">Gospel</option>
                <option value="Highlife">Highlife</option>
                <option value="Rap">Rap</option>
                <option value="Soul">Soul</option>
  </Form.Select>
</Form.Group> */}



                  {/* <p className="uploadsonghead">Write something about your song</p>
<Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >
<Form.Control as="textarea"style={{ height: '200px' }} id="description" placeholder="Describe your song"  size="lg" />
</Form.Group> */}


                  <p className="uploadsonghead">Upload Song (Max size: 10MB)</p>


                  {/* <div class="image-upload"> */}
                  {/* <label for="file">
    <img style={{marginBottom:"40px", cursor:"pointer "}} width="489" height="371" id="imageselectorid" src={ image} />
  </label> */}

                  {/* <input id="file" type="file" /> */}
                  <input type="file" id="file2" onChange={(e) => setSong(e.target.files[0])}></input>
                  {/* </div> */}

                  <p style={{ marginTop: "20px " }} className='loginerrornotify2'>{notify}</p>

                  <p style={{ marginTop: "0px " }} className="songsubmitbutton"><button onClick={songsubmit} className='fundaccountbutton'>Continue</button></p>
                  <p style={{ marginTop: "0px " }} className="artandsongsubmitbutton"><button onClick={artandsongsubmit} className='fundaccountbutton'>Continue</button></p>


                </div>

                {/* <div className='col-md-3'>
    </div> */}



              </Row>
            </Container>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>

      </div>
      <Modal id="myModal" class="modal fade modal-content" show={show} backdrop="static"
        keyboard={false}>

        <Modal.Header >
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5 style={{ textAlign: "center", color: "#fff" }}>Edited <span id="artist2"></span> Successfully</h5>
          <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_qbqgwx9f.json" background="transparent" speed="1" style={{ width: "300px", height: "300px" }} loop autoplay></lottie-player>
          {/* <p style={{textAlign:"center", marginTop:"40px", fontSize:"100px ", color:"#FF0039"}}> <i class="far fa-check-circle"></i> </p> */}
          <p style={{ textAlign: "center", color: "#fff" }}>Your edit has been submitted for review, you will get an e-mail once it is approved.</p>

          <p style={{ textAlign: "center" }}>
            <a href="/fundaccount"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Fund Account</button></a>
            <a href="/dashboard"><button type="button" class="btn btn-lg" className='fundaccountbutton2' >Go to Dashboard</button></a>
          </p>

          {/* <p style={{textAlign:"center"}}>  </p> */}


        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>




      <div class="loading">
        <div class="loading-text">
          <span class="loading-text-words">T</span>
          <span class="loading-text-words">H</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">N</span>
          <span class="loading-text-words">E</span>
          <span class="loading-text-words">W</span>
          <span class="loading-text-words"></span>

          <span class="loading-text-words">T</span>
          <span class="loading-text-words">R</span>
          <span class="loading-text-words">Y</span>
          <span class="loading-text-words">B</span>
          <span class="loading-text-words">E</span>

        </div>
      </div>
    </div>


  )


}


export { EditPage as default }