import React from 'react';
import {Container, NavDropdown, Nav, Navbar, Form, FormControl,Button, Offcanvas } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import "../stylesheets/website.css"

const Header = () => (
  
  
  <header>

{/* <Container > */}


<Navbar  collapseOnSelect expand="false" bg="" expand="lg">

  <Container>
  <Navbar.Brand ><Nav.Link  as={Link} to="/" eventKey="5" activeClassName="is-active" className="" >   <img className='headerlogo' src="/images/tntlogowhite.png"/> </Nav.Link></Navbar.Brand>

  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
    </Nav>
    <Nav>
    <Nav.Link   as={Link} to="/about"  eventKey="1" activeClassName="is-active" className="linkmenu" > About</Nav.Link>
        <Nav.Link  as={Link} to="/faq"   eventKey="2"  activeClassName="is-active" className="linkmenu" >FAQs</Nav.Link>

        <Nav.Link  as={Link} to="/login" eventKey="3"   ><Button   className="navmenu loginmenu">Login</Button></Nav.Link>
        <Nav.Link  as={Link} to="/signup" eventKey="3"   ><Button   className="createmenu" >Create an account</Button></Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>


  
{/* </Container> */}


  </header>
);


export default Header;