import React, { useEffect, useReducer, useState } from 'react';
import { Container, Row, Form, Button, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
// import {useHistory} from 'react-router-dom';
import $ from 'jquery';
import Header from './Header';
import "../stylesheets/website.css"


import postsReducer from '../reducers/posts';
import PostsContext from '../context/posts-context';
import HomePageHeader from './HomePageHeader';
import Footer from './Footer';


const LoginPage = () => {
  // const navigate = useHistory();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [notify, setNotify] = useState("")
  const [year] = useState(new Date().getFullYear())
  useEffect(() => {
    document.title = "Sign In | The New Trybe"
  }, [])

  const userLogin = (e) => {
    e.preventDefault()
    if (email === "") {
      setNotify('E-mail cannot be empty.')
    }
    else if (password === "") {
      setNotify('Password cannot be empty.')
    }
    else {
      var settings = {
        beforeSend: function () {
          setNotify('Signing In...')
        },
        "url": "https://tnt-backend-7b6098ccaafc.herokuapp.com/api/users/signin",
        "method": "POST",
        "timeout": 0,
        error: function (xhr, status, error) {
          var data1 = xhr
          var data = xhr.statusText
          console.log(data1)
          setNotify(data)
          // document.getElementById("errorsignin").innerHTML = data

        },
        "data": { "email": email, "password": password },

        complete: function () {
          // setNotify('Redirecting...')
        }
      }

      $.ajax(settings).done(function (response) {
        console.log(response)
        console.log(response)
        if (response.errors === "email or password is invalid") {
          setNotify("Incorrect E-mail or Password ")
        }
        // else if (response.user.isVerified === false) {
        //   setNotify("Please Confirm Your E-mail ")
        // }

        else {
          var token = response
          localStorage.setItem("access_token", response.token);
          // console.log(token)
          window.location.href = '/explore';

        }

      })
    }


    // dispatch.push('/')

  }

  return (

    <div id="signinbody">
      <Header />
      <Container className='page'>
        {/* <Header/> */}
        <Row className="">
          <div className="col-md-12">
            <h1 className='logintitle'>Sign In</h1>

          </div>


        </Row>
        <Row className="">
          <div className="col-md-3">
          </div>
          <div className="col-md-6">
            <p className='loginnotify'>Sign in with your registered email address. </p>


          </div>
          <div className="col-md-3">
          </div>


          <div className="col-md-4">
          </div>
          <div className="col-md-4">
            <Form >
              <Form.Group className="mb-3 loginemailinput" controlId="formBasicEmail" >

                <Form.Control type="email" id="email" onChange={(e) => setEmail(e.target.value)} placeholder="E-mail" size="lg" />

              </Form.Group>

              {/* <Form.Group className="mb-3" controlId="formBasicPassword"   >
  
    <Form.Control type="password" id="password" onChange={(e) => setPassword(e.target.value)}  placeholder="Password" size="lg" />
  </Form.Group> */}



              <div class="input-group" id="show_hide_password">
                <Form.Control id="password" type="password" className="form-control passwordformcontrol" placeholder=" Password" onChange={(e) => setPassword(e.target.value)} size="lg" required />

                <div class="input-group-addon">
                  <a><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                </div>
              </div>





              <div className="d-grid gap-2">
                <p className='loginerrornotify'>{notify}</p>
                <Button onClick={userLogin} className="loginbutton" type="submit">
                  Continue
                </Button>

              </div>



            </Form>

            <p className='loginnoaccountnotify'> <Nav.Link as={Link} to="/signup" ><span style={{ color: "#FFF" }}>Don`t have an account?</span> <span className="loginmakebold">SIGN UP</span> </Nav.Link></p>

            <Nav.Link as={Link} to="/resetpassword" ><p className='forgotpassword'>Forgot Password?  <span className="loginmakebold">Reset HERE</span></p>   </Nav.Link>
            {/* <p className='copyrighttext'>Copyright  &copy; <span>{year}</span> The New Trybe.</p>   */}

          </div>
          <div className="col-md-4">
          </div>






        </Row>
        {/* <Footer/> */}
      </Container>
    </div>
  )
}


export { LoginPage as default }