import React from "react";

const initialState = {
    posts: [
      {
        id: 1,  
        title: "DigitalOcean",
        body: "Shark"
      }
    ]
  };

const PostsContext = React.createContext(initialState)

export {PostsContext as default}